import _ from 'lodash'

export default {
  computed: {
    validationErrorText: function () {
      return !!this.fieldState.validationErrors?.length
        ? _.join(this.fieldState.validationErrors, '. ') + '.'
        : '';
    },
    shouldShowValidation: function() {
      return (this.fieldState.hadInteraction || this.fieldState.showValidation) && !!this.fieldState.validationErrors?.length;
    },
  },
}
