<template>
  <div class="attributes-container">
    <div class="annotation-control">
      <label for="zoom-slider">Zoom:</label>
      <input type="range" :min="zoomSettings.min" :max="zoomSettings.max" :step="zoomSettings.step" :value="zoom" @input="onZoomChanged" id="zoom-slider"/> 
      <label for="zoom-slider" class="slider-guidance">{{ zoom }}</label>
    </div>
    <div class="annotation-control" v-if="!readOnly">
      <button class="btn btn-success btn-small" v-on:click.prevent="onCropToView(true)">Crop to current view</button>
      <button class="btn btn-warning btn-small" v-on:click.prevent="onCropToView(false)">Remove crop</button>
    </div>
    <hr v-show="showAdd"/>
    <div class="annotation-control" v-show="showAdd">
      <label for="new-shape">Add annotation:</label>
      <select v-model="newAnnotation" :disabled="readOnly" id="new-shape">
        <option :value="option.key" v-for="option in availableShapes" :key="option.key">{{ option.value }}</option>
      </select>
      <button v-if="locatorConfig.allowed" :class="['btn', 'btn-success', 'btn-small', 'locator-button', {'locator-required': locatorRequired}]" v-on:click.prevent="onLocator">
        <i class="material-icons">push_pin</i> {{ locatorTitle }}
      </button>
    </div>
    <hr v-show="showTextScale"/>
    <div class="annotation-control" v-show="showBalloonText">
      <label for="balloon-text-value">Balloon text:</label>
      <input type="text" v-model="balloonText" :disabled="!canEditBalloonText" id="balloon-text-value"/> 
    </div>
    <div class="annotation-control" v-show="showTextScale">
      <label for="text-scale-slider">Balloon text size:</label>
      <input type="range" min="6" max="100" step="1" :disabled="readOnly" :value="balloonTextScale" @input="onTextScaleChanged" id="text-scale-slider"/> 
      <label for="text-scale-slider" class="slider-guidance">{{ balloonTextScale }}</label>
    </div>
    <div class="annotation-control" v-show="showFillColor">
      <label for="fill-colors">Balloon colour:</label>
      <select v-model="fillColor" :disabled="readOnly" id="fill-colors">
        <option :value="option.key" v-for="option in colors" :key="option.key">{{ option.value }}</option>
      </select>
    </div>
    <div class="annotation-control" v-show="showTextColor">
      <label for="text-colors">Text colour:</label>
      <select v-model="textColor" :disabled="readOnly" id="text-colors">
        <option :value="option.key" v-for="option in colors" :key="option.key">{{ option.value }}</option>
      </select>
    </div>
    <div class="annotation-control" v-show="showStrokeColor">
      <label for="line-colors">Line colour:</label>
      <select v-model="strokeColor" :disabled="readOnly" id="line-colors">
        <option :value="option.key" v-for="option in colors" :key="option.key">{{ option.value }}</option>
      </select>
    </div>
    <div class="annotation-control" v-show="showStrokeOpacity">
      <label for="stroke-opacity-slider">Line opacity:</label>
      <input type="range" min="10" max="100" step="1" :disabled="readOnly" v-model="strokeOpacity" id="stroke-opacity-slider"/> 
      <label for="stroke-opacity-slider" class="slider-guidance">{{ strokeOpacity }}</label>
    </div>
    <div class="annotation-control" v-show="showStrokeThickness">
      <label for="stroke-thickness-slider">Line thickness:</label>
      <input type="range" min="1" max="100" step="1" :disabled="readOnly" v-model="strokeScale" id="stroke-thickness-slider"/> 
      <label for="stroke-thickness-slider" class="slider-guidance">{{ strokeScale }}</label>
    </div>
    <hr v-show="showUseFill"/>
    <div class="checkbox-control" v-show="showUseFill">
      <input type="checkbox" :disabled="readOnly" v-model="useFill" id="fill-checkbox" class="checkbox"/> 
      <label for="fill-checkbox" class="checkbox-label">Use fill</label>
    </div>
    <div class="control-group"  v-show="showFill">
      <div class="annotation-control" v-show="showFillOpacity">
        <label for="fill-opacity-slider">Fill Opacity:</label>
        <input type="range" min="1" max="100" step="1" :disabled="readOnly" v-model="fillOpacity" id="fill-opacity-slider"/> 
        <label for="fill-opacity-slider" class="slider-guidance">{{ fillOpacity }}</label>
      </div>
    </div>
     <hr v-show="showDestroy"/>
    <div class="annotation-control" v-show="showDestroy">
      <button :disabled="destroyUnavailable" @click.prevent="destroyCurrent" class="btn btn-danger btn-small">Remove selected annotation</button>
    </div>

  </div>
</template>

<script>
  import _ from 'lodash'
  import { AnnotationDefaults } from "../../lib/annotations/annotation"
  import { AnnotationTypes } from "../../lib/annotations/annotation"

  var annotationType = AnnotationTypes();
  var annotationDefault = AnnotationDefaults();

  const AVAILABLE_COLORS = [
    {
        key: '#FFFFFF',
        value: 'White'
    },{
        key: '#000000',
        value: 'Black'
    },{
        key: '#0000FF',
        value: 'Blue'
    },{
        key: '#4CAF50',
        value: 'Green'
    },{
        key: '#F44336',
        value: 'Red'
    },{
        key: '#FF8000',
        value: 'Orange'
    },{
        key: '#FFFF00',
        value: 'Yellow'
    },{
        key: '#7030A0',
        value: 'Purple'
    },{
        key: '#00B0F0',
        value: 'Sky Blue'
    }]

  const AVAILABLE_SHAPES = [
    {
        key: annotationType.balloon,
        value: 'Text Balloon'
    },{
        key: annotationType.arrow,
        value: 'Arrow'
    },{
        key: annotationType.line,
        value: 'Line'
    },{
        key: annotationType.square,
        value: 'Square'
    },{
        key: annotationType.rectangle,
        value: 'Rectangle'
    },{
        key: annotationType.circle,
        value: 'Circle'
    // },{
    //     key: annotationType.freeStyle,
    //     value: 'Freestyle'
    }]

export const ZoomSettings = {
  min: 1,
  max: 10,
  step: 1,
};

export default {
  props: {
    annotation: Object,
    balloonTextScale: Number,
    zoom: Number,
    readOnly: Boolean,
    notifyBalloonChange: Object,
    locatorConfig: Object,
  },
  data: function() {
    return {
      colors: AVAILABLE_COLORS,
      newAnnotation: null,
      balloonText: null,
      textColor: null,
      strokeColor: null,
      strokeOpacity: null,
      strokeScale: null,
      useFill: false,
      fillColor: null,
      fillOpacity: null,
      zoomSettings: ZoomSettings,
    }
  },
  watch: {
    annotation: function(annotation) {
      if (annotation != null) {
        let data = annotation.annotation_data;
        if (annotation.annotation_type == annotationType.balloon) {
          this.fillColor = data.fill_color ? data.fill_color.toUpperCase() : annotationDefault.balloonFillColor;
          this.textColor = data.text_color ? data.text_color.toUpperCase() : annotationDefault.balloonTextColor;
          this.fetchBalloonText();
        } else {
          this.strokeColor = data.stroke_color ? data.stroke_color.toUpperCase() : annotationDefault.shapeStrokeColor;
          this.strokeOpacity = data.stroke_opacity ? Math.round(data.stroke_opacity / 255 * 100) : annotationDefault.shapeStrokeOpacity;
          this.strokeScale = data.stroke_scale ? Math.round(data.stroke_scale) : annotationDefault.shapeStrokeScale;
          this.useFill = data.fill_opacity && data.fill_opacity > 0;
          this.fillColor = data.fill_color ? data.fill_color.toUpperCase() : this.strokeColor;
          this.fillOpacity = data.fill_opacity ? Math.round(data.fill_opacity / 255 * 100) : annotationDefault.shapeFillOpacity;
        }
      }
    },
    notifyBalloonChange: function(val) {
      this.onNotifyBalloonChanged(val);
    },
    newAnnotation: function(type) {
      if (type != null) {
        this.$emit('newAnnotation', type);
        this.newAnnotation = null;
      }
    },
    balloonText: _.debounce(function (newValue) {
        this.onBalloonTextChanged(newValue)
      }, 150),
    textColor: function(val) {
      if (this.showTextColor && !this.readOnly) {
        this.annotation.annotation_data.text_color = val;
      }
    },
    strokeColor: function(val) {
      if (this.showStrokeColor && !this.readOnly) {
        this.annotation.annotation_data.stroke_color = val;
        this.fillColor = val; // TODO: remove this line if a colour selector is added
      }
    },
    strokeOpacity: function(val) {
      if (this.showStrokeOpacity && !this.readOnly) {
        this.annotation.annotation_data.stroke_opacity = Math.round(val / 100 * 255);
      }
    },
    strokeScale: function(val) {
      if (this.showStrokeThickness && !this.readOnly) {
        this.annotation.annotation_data.stroke_scale = Math.round(val);
      }
    },
    useFill: function(val) {
      if (this.showUseFill && !this.readOnly) {
        if (val) {
          if (this.fillOpacity == 0) {
            this.fillOpacity = 1;
          }
          this.annotation.annotation_data.fill_opacity = Math.round(this.fillOpacity / 100 * 255);
          this.annotation.annotation_data.fill_color = this.fillColor;
        } else {
          this.annotation.annotation_data.fill_opacity = 0;
          this.annotation.annotation_data.fill_color = this.annotation.annotation_data.stroke_color;
        }
      }
    },
    fillColor: function(val) {
      // TODO: remove the useFill clause if a shape fill colour selector is added
      if ((this.showFillColor || this.useFill) && !this.readOnly) {
        this.annotation.annotation_data.fill_color = val;
      }
    },
    fillOpacity: function(val) {
      if (this.showFillOpacity && !this.readOnly) {
        this.annotation.annotation_data.fill_opacity = Math.round(val / 100 * 255);
      }
    },
  },
  computed: {
    showAdd: function() {
      return !this.readOnly;
    },
    showTextScale: function() {
      return this.balloonTextScale;
    },
    showBalloonText: function() {
      return this.annotation && this.annotation.annotation_type == annotationType.balloon;
    },
    showTextColor: function() {
      return this.annotation && this.annotation.annotation_type == annotationType.balloon;
    },
    showStrokeColor: function() {
      return this.annotation && this.annotation.annotation_type != annotationType.balloon;
    },
    showStrokeOpacity: function() {
      return this.annotation && this.annotation.annotation_type != annotationType.balloon;
    },
    showStrokeThickness: function() {
      return this.annotation && this.annotation.annotation_type != annotationType.balloon;
    },
    showUseFill: function() {
      return this.annotation && this.annotation.annotation_type != annotationType.balloon;
    },
    showFill: function() {
      return this.annotation && this.annotation.annotation_type != annotationType.balloon && this.useFill;
    },
    showFillColor: function() {
      return this.annotation && this.annotation.annotation_type == annotationType.balloon;
    },
    showFillOpacity: function() {
      return this.showFill;
    },
    showDestroy: function() {
      return !this.readOnly && this.annotation;
    },
    availableShapes: function() {
      let shapes = [{key: null, value: '-- Select a shape type --'}];
      return shapes.concat(AVAILABLE_SHAPES);
    },
    destroyUnavailable: function() {
      return !this.annotation;
    },
    canEditBalloonText: function() {
      return !this.readOnly && this.showBalloonText && !this.annotation.linked_feature && !this.annotation.annotation_data?.template_text
    },
    locatorTitle: function() {
      return this.locatorConfig.exists ? "Find smart pin" : "Drop smart pin";
    },
    locatorRequired: function() {
      return this.locatorConfig.required && !this.locatorConfig.exists;
    },
  },
  methods: {
    onCropToView: function(crop) {
      this.$emit('cropToView', crop);
    },
    fetchBalloonText: function () {
      if (!!this.annotation.linked_feature) {
        let sections = this.annotation.linked_feature.split('/');
        let last = sections[sections.length - 1];
        let balloonEvent = {
          destination: last,
          type: 'getBalloonValue',
        };
        this.$emit('getBalloonValue', balloonEvent);
      } else {
        let text = this.annotation.annotation_data.text;
        this.balloonText = text;
      }
    },
    onBalloonTextChanged: function (val) {
      if (this.canEditBalloonText) {
        this.annotation.annotation_data.text = val;
        this.annotation.annotation_data.full_text = val;
      }
    },
    onNotifyBalloonChanged: function(val) {
      if (!!this.annotation.linked_feature) {
        let sections = this.annotation.linked_feature.split('/');
        let last = sections[sections.length - 1];
        if (this.showBalloonText 
            && val.type == 'updatedBalloonText' 
            && val.source == last) {
          this.annotation.annotation_data.full_text = val.payload.newValue;
          this.annotation.annotation_data.text = val.payload.newValue;
          this.balloonText = val.payload.newValue;
        }
      }
    },
    onZoomChanged: function (evt) {
      this.$emit('update:zoom', evt.target.valueAsNumber);
    },
    onTextScaleChanged: function (evt) {
      if (!this.readOnly) {
        this.$emit('update:balloonTextScale', evt.target.valueAsNumber);
      }
    },
    onLocator: function() {
      this.$emit('locatorSelected');
    },
    destroyCurrent: function() {
      if (!this.destroyUnavailable) {
        let destroyText = this.annotation.annotation_type == annotationType.balloon && this.annotation.linked_feature 
            ? "Remove this balloon?\n\nThis balloon is linked to a feature on this form.  You will remove this link if you continue."
          : this.annotation.annotation_type == annotationType.freeStyle
            ? "Remove this freestyle annotation?\n\nFreestyle can only be redrawn in the app."
          : "Remove this shape?";
        if (!this.readOnly && window.confirm(destroyText)) {
          this.$emit('destroyCurrent');
        }
      }
    },
  }
}
</script>
<style scoped>
  .annotation-control {
    margin-top: 10px;
  }
  .slider-guidance {
    display: inline;
  }
  .checkbox-control {
    margin-top: 10px;
    margin-bottom: 6px;
  }
  .checkbox-label {
    display: inline;
    padding-left: 6px;
  }
  .checkbox {
    margin-top: 0;
  }
  .control-group {
    background-color: #EEE;
    margin-left: -6px;
    padding-left: 6px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .locator-button {
    display: flex;
    align-items: center;
    padding-left: 6px;
  }
  .locator-button i {
    margin-right: 4px;
  }
  .locator-required {
    border: 2px solid red;
  }

</style>
