import _ from 'lodash'

export default {
  computed: {
    label: function () {
      return this.specification.label;
    },
    helpText: function () {
      return this.specification.help_text;
    },
    hasHelpText: function () {
      return !_.isEmpty(this.helpText);
    },
  }
}