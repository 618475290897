<template>
  <div>
    <div>
      <label for="edit-version-name" class="control-label">Version Name</label>
      <div class="controls">
        <input id="edit-version-name" type="text" v-model="versionName" v-on:keydown.enter.prevent="">
      </div>
    </div>
    <div class="indent btn-group image-controls" v-show="isNew">
      <button data-toggle="dropdown" class="btn btn-success dropdown-toggle">
        <i v-show="!hasPhoto" class="icon-plus icon-white"></i>
        {{ versionButtonText }}
      </button>
      <ul class="dropdown-menu pull-left">
        <li><a @click.stop="$refs.fileInput.click()">Load from image</a></li>
        <li><a @click.stop="$refs.pdfInput.click()">Load from pdf</a></li>
      </ul>
      <div class="hide">
        <input type="file" accept="image/jpeg" @change="onFileChosen" ref="fileInput" >
        <input type="file" accept="application/pdf" @change="onFileChosen" ref="pdfInput" >
      </div>
    </div>
    <dropzone class="indent file-drop-zone" v-show="isNew" label="Drop your pdf or image file here" @files_selected="val => onFilesDragged(val)"/>
    <div class="indent version-container" v-show="isNew">
      <div class="alert alert-error small-alert" v-if="showError">{{ errorText }}</div>
      <div class="alert alert-success small-alert" v-if="showInformation">{{ informationText }}</div>
    </div>
    <div class="indent version-container" v-show="hasPhoto">
      <div>
        <div class="btn-group">
          <button class="btn btn-primary" v-on:click.prevent="showCoordinateEditor = true" :disabled="!editCoordsAllowed">Edit Coordinates</button>
        </div>
        <div v-if="isNew" class="float-right">
          <button class="btn btn-primary rotate_button" v-on:click.prevent="onImageRotate(false)" title="Rotate left.">
            <i class="material-icons">rotate_left</i>
          </button>
          <button class="btn btn-primary rotate_button" v-on:click.prevent="onImageRotate(true)" title="Rotate right.">
            <i class="material-icons">rotate_right</i>
          </button>
        </div>
      </div>
      <img class="hide photo" @load="onImageRendered" crossorigin="anonymous" :src="photoUrl" ref="versionImage">
      <drawing-version-display
        v-show="!showCoordinateEditor"
        :currentDrawing="version"
        :subjectImage="coordinateImage"
        :selectedCoordinate="'all'"
        :forceRedraw="coordinateRedraw"
        :style="thumbSize"
      />
      <div class="alert alert-info rotation-warning" v-show="showOrientationWarning"><p><strong>This drawing is in portrait!</strong></p><p class="last-line">Please check the orientation is correct, as it <strong>cannot</strong> be altered after saving.</p></div>
      <coordinate-editor
        :show="showCoordinateEditor"
        :currentDrawing="version"
        :subjectImage="coordinateImage"
        @close="showCoordinateEditor = false"
      />
    </div>
    <div class="hide">
      <input type="file" accept="image/jpeg" @change="onFileChosen" ref="fileInput" >
      <input type="file" accept="application/pdf" @change="onFileChosen" ref="pdfInput" >
    </div>

    <pdfDrawingSelector
      :selectedPdfFile="pdfSrc"
      @saved_image_blob="val => onNewImageLoaded(val)"
      @selected_drawing="val => onDrawingLoaded(val)"
      @cancel="onAddVersionCancelled"
      @error="val => onLoadError(val)"
      v-show="pdfSrc"
    />
    <imageLoader
      :drawingSource="imageFile"
      :loadView="false"
      @unscaled_image="val => onNewImageLoaded(val)"
      @scaled_image="val => { informationText = 'Drawing scaled from original.'; onNewImageLoaded(val); }"
      @error="val => onLoadError(val)"
      v-show="imageFile"
    />
    <spinner v-show="showSpinner"/>
  </div>
</template>

<script>
  import _ from 'lodash'
  import pdf_document from './drawing_pdf_page_selector.vue'
  import image_loader from './drawing_image_loader.vue'
  import coordinate_editor from './drawing_coordinate_editor.vue'
  import drawing_version_display from './drawing_version_display.vue'
  import { AssetUploader } from "../../lib/asset_uploader"
  import spinner from '../utils/spinner_overlay.vue'
  import dropzone from '../utils/dropzone.vue'


  export default {
    components: {
      dropzone,
      pdfDrawingSelector: pdf_document,
      imageLoader: image_loader,
      drawingVersionDisplay: drawing_version_display,
      coordinateEditor: coordinate_editor,
      spinner: spinner,
    },
    props: {
      version: Object,
      isNew: Boolean,
      uploadAssets: Boolean
    },
    data: function () {
      return {
        versionName: this.version.version,
        newVersionSource: null,

        selectedFileOrPage: null,
        isUploading: false,

        pdfSrc: null,
        imageFile: null,

        coordinateImage: null,
        coordinateRedraw: false,

        informationText: "",
        errorText: "",

        showCoordinateEditor: false,
        editCoordsAllowed: false,

        showSpinner: false,
      }
    },
    watch: {
      version: function(version) {
        this.versionName = version.version;
      },
      versionName: function(name) {
        this.version.version = name;
        this.$emit('update:version', this.version)
      },
      uploadAssets: function(upload) {
        if (upload && !this.isUploading) {
          this.onSave();
        }
      },
      showCoordinateEditor: function(show) {
        if (!show) {
          this.coordinateRedraw = true;
          this.$nextTick(() => this.coordinateRedraw = false)
        }
      }
    },
    computed: {
      showInformation: function () {
        return this.informationText != '';
      },
      showError: function () {
        return this.errorText != '';
      },
      showOrientationWarning: function () {
        return this.isNew && this.version.aspect_ratio < 1;
      },
      photoUrl: function () {
        if (this.isNew) {
          return this.newVersionSource;
        } else {
          let uuid = this.version.original_asset_id;
          return this.assetUrl(uuid);
        }
      },
      hasPhoto: function () {
        return this.isNew ? !!this.selectedFileOrPage
          : !!this.version.original_asset_id;
      },
      versionButtonText: function () {
        return !this.selectedFileOrPage ? "Load File ..."
          : "Replace File ..."
      },
      coordinateLabelText: function () {
        return this.isNew ? "Set Coordinates" : "Edit Coordinates";
      },
      thumbSize: function () {
        return {
          width: '600px',
          height: 600 / this.version.aspect_ratio + 'px'
        }
      },
    },
    methods: {
      onSave: function(event) {
        if (_.isNil(this.selectedFileOrPage)) {
          this.uploadComplete(true);
        } else {
            this.upload();
        }
      },
      
      onFilesDragged: function (files) {
        this.onFileSelected(files[0]);
      },
      onFileChosen: function (event) {
        this.onFileSelected(event.target.files[0]);
        this.$refs.pdfInput.value = ''; // reset the input field in case use chooses same file again
        this.$refs.fileInput.value = ''; // reset the input field in case use chooses same file again
      },
      onFileSelected: function (file) {
        if (null == file || file.type != 'application/pdf' && file.type != 'image/jpeg') {
          return;
        }
        this.resetAddingVersion();
        if (file.type == 'image/jpeg') {
          this.imageFile = file;
          this.pdfSrc = null;
        } else if (file.type == 'application/pdf') {
          this.pdfSrc = file;
        }
      },

      onDrawingLoaded: function(drawing) {
        this.$emit("drawingDetailsChanged", drawing);
      },
      onNewImageLoaded: function(fileOrBlob) {
        this.onImageLoaded(fileOrBlob);
        this.imageFile = null;
        this.$nextTick(() => this.pdfSrc = null);
        this.$emit("haveNewAsset", true)
        if (this.isNew) {
          this.clearCoordinates();
        }
      },
      onImageLoaded: function (fileOrBlob) {
        this.selectedFileOrPage = fileOrBlob;
        let source = this.newVersionSource;
        this.newVersionSource = URL.createObjectURL(fileOrBlob);
        if (source != null) {
          URL.revokeObjectURL(source);
        }
      },
      onAddVersionCancelled: function () {
        this.resetAddingVersion();
        this.imageFile = null;
        this.$nextTick(() => this.pdfSrc = null);
      },
      onLoadError: function (errorText) {
        this.errorText = errorText;
        this.imageFile = null;
        this.$nextTick(() => this.pdfSrc = null);
      },
      onImageRendered: function(event) {
        if (this.isNew) {
          this.version.aspect_ratio = (event.target.naturalWidth / event.target.naturalHeight);
        }
        // toggle the image so the coordinate editor redraws
        this.coordinateImage = null;
        this.$nextTick(() => this.coordinateImage = event.target);
        this.editCoordsAllowed = true;
      },
      clearCoordinates: function() {
        this.version.top_left.splice(0, 2);
        this.version.top_left.push(0, 0);
        this.version.bottom_right.splice(0, 2);
        this.version.bottom_right.push(1, 1);
      },

      resetAddingVersion: function () {
        this.newVersionSource = null;
        this.selectedFileOrPage = null;
        this.$emit("haveNewAsset", false)
        this.errorText = '';
        this.informationText = '';
        this.editCoordsAllowed = false;
      },
      onImageRotate: function(clockwise) {
        this.showSpinner = true;
        this.errorText = '';
        try {
          let width = this.coordinateImage.height;
          let height = this.coordinateImage.width;

          let canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          let context = canvas.getContext('2d');

          let x = clockwise ? width : 0;
          let y = clockwise ? 0 : height;
          context.translate(x, y);
          context.rotate(Math.PI / 2 * (clockwise ? 1 : -1)); // 90 degrees in radians

          context.drawImage(this.coordinateImage, 0, 0, height, width);

          let vm = this;
          canvas.toBlob((blob) => {
            try {
              vm.onImageLoaded(blob);
            } catch {
              vm.errorText = "Unexpected error rotating image.";
            } finally {
              vm.showSpinner = false;
            }
          }, 'image/jpeg', 0.95);
        } catch {
          this.errorText = "Unexpected error rotating image.";
          this.showSpinner = false;
        }
      },
      assetUrl: function (assetId) {
        return '/o/' + this.organisationId() + '/assets/' + (assetId)
      },
      organisationId: function () {
        const organisationIdRegex = /^\/o\/(\d+)\//g;
        let matches = organisationIdRegex.exec(window.location.pathname)

        if (matches) {
          return matches[1]
        }
      },

      upload: function () {
        let vm = this;

        vm.errorText = '';

        let uploader = AssetUploader(
          success, 
          fail, 
          message => vm.informationText = message
        );

        uploader.uploadJpeg(vm.selectedFileOrPage)

        function success(original_id) {
          vm.version.asset_file_size = vm.selectedFileOrPage.size;
          vm.version.original_asset_id = original_id;
          vm.$emit('update:version', vm.version)
          vm.$nextTick(() => vm.uploadComplete(true));
        };
        function fail(message) {
          vm.informationText = '';
          vm.errorText = message;
          vm.uploadComplete(false)
        };
      },
      uploadComplete: function(success) {
        this.isUploading = false;
        if (_.isEmpty(this.version['original_asset_id']) || _.isEmpty(this.version['aspect_ratio'] + "")) {
          this.errorText = 'Error uploading file - please try again.';
        } else {
          this.$emit("uploadResult", {'success': success})
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .version-container {
    margin-top: 20px;
    margin-left: 20px;
    width: 600px;
  }
  .version-container .btn-group {
    margin-bottom: 10px;
  }
  #edit-version-name {
    width: 590px;
  }
  .btn-group a {
    cursor: pointer;
  }
  .image-controls {
    margin: 3px;
  }
  .form-controls {
    margin: 3px;
    float: right;
  }
  .alert {
    margin-bottom: 0
  }
  #coordinates label {
    display: inline;
    padding-right: 20px;
  }
  .hide {
    display: none;
  }
  .float-right {
    float: right;
  }
  .indent {
    margin-left: 201px;
  }
  .rotate_button {
    height: 30px;
    i {
      font-size: 20px;
    }
  }
  .rotation-warning {
    margin-top: 20px;
  }
  .last-line {
    margin-bottom: 0;
  }
  .file-drop-zone {
    width: 20vw;
    height: 80px;
  }
</style>