<template>
  <tr class="inspection-section-row">
    <td v-show="hasHints">
      <select :class="['hint-list', {'hints-unavailable': hintsDisabled}]" ref="hintContainer" size="1" v-model="selectedHint" :title="hintTitle" :disabled="hintsDisabled">
        <option class="hint-text" v-for="hint in rowHints" :key="hint.Ref" :value="hint.Ref">
          <span>
            {{ hintSolutionReference(hint) }}{{ hintSolutionDescription(hint) }}{{ hintSolutionRating(hint) }}
          </span>
        </option>
      </select>
    </td>
    <td v-for="column in columns" :key="column" class="inspection-table-cell">
      <component
        :is="componentFor(chosenField(column))"
        :specification="chosenField(column)"
        :data="data[chosenField(column).reference]"
        :fieldModel="fieldModelFor(chosenField(column))"
        :fieldState="fieldStateFor(chosenField(column))"
        :notifyFieldData="notifyFieldData[chosenField(column).reference]"
        @registerLinks="val => registerLinks(val)"
        @update:data="val => fieldUpdate(chosenField(column), val)"
        @clear:data="() => fieldRemoval(chosenField(column))"
        :read-only="readOnly"
        @notifyFieldEvent="val => onNotifyFieldEvent(val)"
        :key="chosenField(column).reference">
      </component>
    </td>
  </tr>
</template>

<script>
  import DateField from './fields/date.vue'
  import DecimalField from './fields/decimal.vue'
  import FeatureField from './fields/feature.vue'
  import GuidanceField from './fields/guidance.vue'
  import IntegerField from './fields/integer.vue'
  import LocationField from './fields/location.vue'
  import SelectionField from './fields/selection.vue'
  import TextField from './fields/text.vue'
  import TimeField from './fields/time.vue'
  import PhotoField from './fields/photo.vue'

  export default {
    components: {
      DateField,
      DecimalField,
      FeatureField,
      GuidanceField,
      IntegerField,
      LocationField,
      SelectionField,
      TextField,
      TimeField,
      PhotoField
    },
    props: {
      fields: Array,
      data: Object,
      fieldListModel: Object,
      fieldListState: Object,
      readOnly: Boolean,
      columns: Array,
      notifyFieldEvent: Object,
    },
    inject: ['hints','filterOn','targetFields','includeOverRating'],
    data: function () {
      let initialNotify = _.reduce(this.fields, (o, f) => {
        o[f.reference] = {};
        return o;
        }, {});

      return {
        selectedHint: null,
        registeredLinks: [],
        notifyFieldData: initialNotify,
      }
    },
    watch: {
      selectedHint: function(ref) {
        this.addToForm(ref);
      },
      notifyFieldEvent: function(val) {
        this.onNotifyFieldEvent(val);
      },
    },
    computed: {
      hasHints: function () {
        return !_.isEmpty(this.hints());
      },
      rowHasHints: function () {
        return !_.isEmpty(this.rowHints);
      },
      hintsDisabled: function () {
        return !this.rowHasHints || this.isReadOnly;
      },
      hintTitle: function () {
        return this.rowHasHints ? 'Matching options: ' + this.rowHints.length : 'No matching hints';
      },
      rowHints: function () {
        let vm = this;
        return _.filter(vm.hints(), function (hintValue) {
          return vm.hintPredicate(hintValue)
        });
      },
    },
    methods: {
      registerLinks: function(reg) {
        let newReg = _.map(reg.links, (l) => { return {ownerReference: reg.owner, targetReference: l}; })
        this.registeredLinks = this.registeredLinks.concat(newReg);
      },
      onNotifyFieldEvent: function(val) {
        if (!val.destination) {
          _.forEach(this.registeredLinks, (l) => {
            if (l.targetReference == val.source) {
              val.destination = l.ownerReference;
              this.notifyFieldData[l.ownerReference] = val;
            }
          });
        }
        if (!!val.destination) {
          // we work with relative paths only
          // we're making the assumption that there are no deep paths
          // TODO: adapt to support nested features
          // TODO: adapt to support parent paths
          // TODO: adapt to support absolute paths (eg if path starts with a '/')
          let parts = val.destination.split(':');
          if (parts.length == 2) {
            val.destination = parts[1];
            this.notifyFieldData[parts[0]] = val;
          } else {
            this.notifyFieldData[val.destination] = val;
          }
        }
      },
      hintSolutionReference: function (hint) {
        return (_.isNil(hint['solutionReference'])) ? "" : hint['solutionReference'];
      },
      hintSolutionDescription: function (hint) {
        return (_.isNil(hint['solutionDescription'])) ? "" : ", " + hint['solutionDescription'];
      },
      hintSolutionRating: function (hint) {
        return (_.isNil(hint['fireRating'])) ? "" : ", " + hint['fireRating'];
      },
      addToForm: function (hintRef) {
        // add current values into current data then hide hint Container
        let vm = this;
        
        let thisHint = _.cloneDeep(_.find(this.rowHints, function (hint) { return hint.Ref == hintRef }));
        let exclusions = _.keys(vm.filterOn);
        exclusions.push('Ref');
        
        let hintFields = _.pickBy(thisHint, function (value, key) { return !_.includes(exclusions, key) });
        
        let localData = _.cloneDeep(vm.data);
        let sendData = false;

        _.forEach(hintFields, function (value, key) {
          if (!_.isNil(vm.chosenField(key))) {
            let dataToSend = {};
            let fieldData = vm.data[key];
            dataToSend = _.isNil(fieldData) ? {} : _.cloneDeep(fieldData);

            // For now only update text fields
            switch (vm.chosenField(key).type) {
              case 'text':
                dataToSend['value'] = thisHint[key];
                dataToSend['type'] = 'text';            
                localData[key] = dataToSend;
                sendData = true;
                break;
              case 'selection':
                let localValue = vm.matchOptionValue(vm.chosenField(key),thisHint[key]);
                if (!_.isEmpty(localValue)) {
                  dataToSend['selections'] = localValue;
                  dataToSend['type'] = 'selection';            
                  localData[key] = dataToSend;
                  sendData = true;                                  
                }
            }
          }
        });
        if (sendData) { vm.$emit('update:data', localData) };
      },
      matchOptionValue: function (field, value) {
        let fieldModel = this.fieldListModel.getField(field.reference);
        let originalOptions = fieldModel?.getOptions ? fieldModel.getOptions() : [];
        return _.filter(originalOptions, { 'value': value });        
      },
      hintPredicate: function (hintValue) {
        // find the field
        let vm = this;
        let result = false;
        result =  _.every(_.keys(vm.filterOn), function (filterReference) {
          if (_.isEmpty(vm.data[filterReference])) {
            return true;
          } else {
            switch (vm.data[filterReference].type) {
              case 'text':
                return hintValue[filterReference] == vm.data[filterReference]['value'];
              break;
              case 'selection':
                if (_.isEmpty(vm.data[filterReference]["selections"])) {
                  return true;
                } else {
                  if (vm.filterOn[filterReference] == 'Fire Rating') {
                    return !_.isNil(_.find(vm.data[filterReference]['selections'], function (selection) {
                      return vm.compareFireRating(hintValue[filterReference],selection.value,vm.includeOverRating());
                      }));
                  } else {
                    return !_.isNil(_.find(vm.data[filterReference]['selections'], function (selection) {
                      return (hintValue[filterReference] == selection.value);
                      }));
                  }
                }
            }
          }
        });
        return result;
      },
      compareFireRating: function (solutionRating, substrateRating, includeOverRated) {
        // Do they match exactly? (smoke, acoustic or direct match)
        if (solutionRating == substrateRating ) { 
          return true ;
        } else {
          // Are they numeric?
          let arr1 = solutionRating.split('/');
          let arr2 = substrateRating.split('/');

          if ((arr1.length != arr2.length) || (arr1.length != 3) || (arr2.length != 3)) { 
            // Completely different
            return false ; 
          } else {
            // check specific integrity and insulation
            let int1 = (arr1[1] == '-') ? 0 : parseInt(arr1[1]);
            let ins1 = (arr1[2] == '-') ? 0 : parseInt(arr1[2]);
            let int2 = (arr2[1] == '-') ? 0 : parseInt(arr2[1]);
            let ins2 = (arr2[2] == '-') ? 0 : parseInt(arr2[2]);

            // Meets criteria
            if ((int1 == int2) && (ins1 == ins2)) { 
              return true ;
            } else {
              if (includeOverRated) {
                // look for overrated
                if (((int1 > int2) && (ins1 >= ins2)) || ((int1 >= int2) && (ins1 > ins2)) || ((int1 > int2) && (ins1 > ins2))) {
                  return true ;
                } else {
                  return false ;
                }                
              } else {
                return false ;
              }
            }
          }
        }
      },
      componentFor: function (field) {
        return field.type + "-field";
      },
      fieldModelFor: function (field) {
        return this.fieldListModel.getField(field.reference);
      },
      fieldStateFor: function (field) {
        return this.fieldListState.fieldStates[field.reference];
      },
      chosenField: function (column) {
        return _.find(this.fields,{'reference': column});
      },
      fieldUpdate: function (field, value) {
        let data = this.fieldListState.setFieldData(field.reference, value, this.fieldListModel, this.data);
        this.$emit('update:data', data)
      },
      fieldRemoval: function (field) {
        let data = this.fieldListState.setFieldData(field.reference, null, this.fieldListModel, this.data);
        this.$emit('update:data', data)
      },
    }
  }
</script>

<style scoped lang="scss">
  .inspection-table-cell {
    padding: 0px;
  }
  .hint-list {
    width:20px;
    background-color: #0000FF;
    color: white;
    margin-bottom: 0;
    margin-right:4px;
    margin-left:2px;
    border-radius:10px;
    cursor: pointer;
  }
  .hints-unavailable {
    background-color: #b9b8b8;
    cursor: default;
  }
  .hint-text {
    cursor: pointer;
    background-color: white;
    color: black;
  }
  .hint-text:nth-of-type(odd) {
    background: #e0e0e0;
  }
</style>