import _ from 'lodash'
import { _BaseField as Base } from './_base.js'

const type = 'decimal';
const DecimalFieldImpl = (specification) => {

  const spec = _.cloneDeep(specification);

  const minValue = finiteValueFromString(spec.minimum_value);
  const maxValue = finiteValueFromString(spec.maximum_value);

  const thisField = Base.create(type, spec);

  thisField.parse = parse;
  thisField.validate = validate;

  return thisField;


  /* end of construction */


  function validate(jsonValue) {
    let errors = [];
    let isValid = true;

    if (!thisField.isReadOnly) {
      let requiredCheck = checkRequired(jsonValue);
      errors = errors.concat(requiredCheck.messages);
      isValid = isValid && requiredCheck.isValid;

      if (isPresent(jsonValue)) {
        let criteriaCheck = checkCriteria(jsonValue);
        errors = errors.concat(criteriaCheck.messages);
        isValid = isValid && criteriaCheck.isValid;
      }
    }

    return {
      isValid: isValid,
      errors: errors
    }
  }

  function checkRequired (jsonValue) {
    let isValid = !thisField.isRequired || isPresent(jsonValue);
    return {
      isValid: isValid,
      messages: isValid ? [] : ['This field is mandatory']
    };
  }

  function isPresent (jsonValue) {
    return !!valueFromData(jsonValue);
  }

  function valueFromData(jsonValue) {
    return Number.parseFloat(jsonValue?.value);
  }
  function finiteValueFromString(string) {
    let val = Number.parseFloat(string);
    return Number.isFinite(val) ? val : undefined;
  }

  function checkCriteria (jsonValue) {
    let messages = [];
    let isValid = true;

    let val = valueFromData(jsonValue);
    if (val) {
      let hasMin = minValue != null;
      let hasMax = maxValue != null;
      
      let minMet = !hasMin || (val !== Number.NEGATIVE_INFINITY && val >= minValue);
      let maxMet = !hasMax || (val !== Number.POSITIVE_INFINITY && val <= maxValue);
      isValid = minMet && maxMet;

      if (!isValid) {
        let message = "Value must be "
          + (hasMin ? "greater than or equal to " + minValue + (hasMax ? " and " : "") : "")
          + (hasMax ? "less than or equal to " + maxValue : "");
        messages.push(message);
      }
    }

    return {
      isValid: isValid,
      messages: messages,
    };
  }

  function parse(value) {
    let output = null;
    let messages = [];
    let isEmpty = false;

    let newFloat = NaN;
    if (typeof value === 'string' || value instanceof String) {
      let matches = value?.match(/^\s*([+|-]?\d+(\.\d+)?|\d+\.\d+[e|E][+|-]\d+)\s*$/);
      if (_.isArray(matches)) {
        newFloat = parseFloat(matches[1]);
      } else {
        let val = value.trim();
        isEmpty = _.isEmpty(val) || val == '+' || val == '-';
      }
    } else {
      newFloat = parseFloat(value);
      isEmpty = _.isNil(value);
    }
    if (!_.isNaN(newFloat)) {
      let newValue = newFloat.toString();
      if (newValue.includes('e')) {
        messages.push("Decimal number is too big for the field");
      } else {
        output = {
          type: type,
          value: newValue,
        };
      }
    } else if (!isEmpty) {
      messages.push("Value must be a decimal");
    }

    return {
      data: output,
      messages: messages,
    };
  };
}

export const DecimalField = {
  type,
  create: (specification) => DecimalFieldImpl(specification),
}
