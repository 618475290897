import _ from 'lodash'
import { _BaseField as Base } from './_base.js'

const type = 'date';
const DateFieldImpl = (specification) => {

  const spec = _.cloneDeep(specification);

  const thisField = Base.create(type, spec);

  thisField.hasDefault = !!(spec.set_on_create ?? true);
  thisField.getDefault = getDefault;
  thisField.parse = parse;
  thisField.parseFromPicker = parseFromPicker;
  thisField.validate = validate;

  return thisField;


  /* end of construction */


  function getDefault() {
    let now = new Date();
    return {
      type: type,
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate()
    };
  }

  function validate(jsonValue) {
    let errors = [];
    let isValid = true;

    if (!thisField.isReadOnly) {
      let requiredCheck = checkRequired(jsonValue);
      errors = errors.concat(requiredCheck.messages);
      isValid = isValid && requiredCheck.isValid;
    }

    return {
      isValid: isValid,
      errors: errors
    }
  }

  function checkRequired (jsonValue) {
    let isValid = !thisField.isRequired || isPresent(jsonValue);
    return {
      isValid: isValid,
      messages: isValid ? [] : ['This field is mandatory']
    };
  }

  function isPresent (jsonValue) {
    return jsonValue != null && (jsonValue.day != null || jsonValue.month != null || jsonValue.year != null);
  }

  
  function parse(stringValue) {
    let output = null;
    let messages = [];

    // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format
    // for how this will parse the string input
    let parsedDate = Date.parse(stringValue);

    if (_.isNaN(parsedDate)) {
      messages.push("Value must be a valid date");
    } else {
      let date = new Date(parsedDate);
      output = {
        type: type,
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      }
    }
  
    return {
      data: output,
      messages: messages,
    };
  };

  function parseFromPicker(stringValue) {

    // this is the very specific format that is produced by the UI date picker
    var DATE_INPUT_REGEX = /^(\d{4})-(\d{2})-(\d{2})$/;
    var match = stringValue?.match(DATE_INPUT_REGEX);

    if (Array.isArray(match)) {
      return {
        type: type,
        year: Number.parseInt(match[1]),
        month: Number.parseInt(match[2]),
        day: Number.parseInt(match[3])
      }
    }
    return null;
  };
}

export const DateField = {
  type,
  create: (specification) => DateFieldImpl(specification),
}
