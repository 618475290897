import _ from 'lodash'
import { 
  DateField,
  DecimalField,
  FeatureField,
  GuidanceField,
  IntegerField,
  LocationField,
  PhotoField,
  SelectionField,
  TextField,
  TimeField,
} from './_fields.js'

export const FieldList = {
  create: (fields, sourceLists, classifiers, categories) => FieldListImpl(fields, sourceLists, classifiers, categories),
}


const FieldListImpl = (fields, sourceLists, classifiers, categories) => {

  const fieldList = {};
  const listClassifiers = [];
  const listCategories = {};


  (Array.isArray(fields) ? fields : []).forEach(fieldSpec => {
    let field = makeField(fieldSpec, sourceLists);
    if (field) {
      fieldList[field.reference] = field;
    }
    function makeField(fieldSpec, sourceLists) {
      switch (fieldSpec.type) {
        case DateField.type: return DateField.create(fieldSpec);
        case DecimalField.type: return DecimalField.create(fieldSpec);
        case FeatureField.type: return FeatureField.create(fieldSpec, sourceLists);
        case GuidanceField.type: return GuidanceField.create(fieldSpec);
        case IntegerField.type: return IntegerField.create(fieldSpec);
        case LocationField.type: return LocationField.create(fieldSpec);
        case PhotoField.type: return PhotoField.create(fieldSpec);
        case SelectionField.type: return SelectionField.create(fieldSpec, sourceLists);
        case TextField.type: return TextField.create(fieldSpec, sourceLists);
        case TimeField.type: return TimeField.create(fieldSpec);
        default: return null;
      }
    }
  });

  (Array.isArray(classifiers) ? classifiers : []).forEach(classifierSpec => {
    let classifier = classifierSpec ? Classifier(classifierSpec) : undefined;
    if (classifier) {
      listClassifiers.push(classifier);
    }
  });

  Object.entries(categories ?? {}).forEach(entry => {
    let category = entry[1] ? Category(entry[1]) : undefined;
    if (category) {
      listCategories[entry[0]] = category;
    }
  })

  return {
    getFieldRefs: () => Object.keys(fieldList),
    getField: (reference) => fieldList[reference],
    isControlField: fieldRef => !!fieldList[fieldRef]?.fieldControlSpec,
    defaultData: defaultData,
    classify: classify,
  }

  /* end of construction */


  function defaultData() {
    let data = {};
    Object.entries(fieldList).forEach(e => {
      let field = e[1];
      if (field.hasDefault) {
        let defaultValue = field.getDefault();
        if (defaultValue != null) {
          data[field.reference] = defaultValue;
        }
      }
    })
    return data;
  }

  function classify(jsonData) {
    let classifications = {};
    listClassifiers.map(c => {
      let classification = c.classify(jsonData);
      if (classification) {
        let categoryValue = listCategories[c.category]?.getValue(classification);
        classifications[c.reference] = categoryValue; // might be nullish
      }
    });
    return classifications;
  }
}


const Classifier = (classifier) => {

  const mapper = Mapper(classifier.mapper);

  return  {
    reference: classifier.reference,
    category: classifier.category,
    label: classifier.label,
    filterable: !!(classifier.filterable ?? true),
    colorable: !!(classifier.colorable ?? true),
    exhaustive: !!classifier.exhaustive,
    hideOnSitePlan: !!classifier.hide_on_site_plan,
    contextSensitive: !!classifier.context_sensitive,
    classify: jsonData => mapper.classify(jsonData),
  }
}


const Mapper = (mapper) => {
    
  const mapperSpec = mapper ? {...mapper} : {};

  const mappers = Array.isArray(mapper?.mappers)
    ? mapper.mappers.map(m => Mapper(m))
    : [];

  // TODO: availability mapper

  const operation = mapperSpec.type === 'or' ? orClassify
    : mapperSpec.type === 'selection' ? selectionClassify
    : mapperSpec.type === 'text' ? textClassify
    : () => null;

  return {
    classify: jsonData => operation(jsonData),
  }

  function orClassify(jsonData) {
    return mappers.find(m => m.classify(jsonData));
  }

  function selectionClassify(jsonData) {
    let field = jsonData?.[mapperSpec.field];
    if (field?.type === SelectionField.type) {
      if (Array.isArray(field.selections) && field.selections.length > 0) {
        // TODO: to date we only consider the first value in the field
        let option = field.selections[0];
        return mapperSpec.attribute === 'key' ? option.key 
          : mapperSpec.attribute === 'value' ? option.value
          : null;
      }
    }
    return null;
  }

  function textClassify(jsonData) {
    let field = jsonData?.[mapperSpec.field];
    if (field?.type === TextField.type) {
      return field.value?.trim() ? field.value : null;
    }
    return null;
  }
}

const Category = (category) => {

  const values = category.values ? {...category.values} : {};
  const fallback = category.fallback ? {...category.fallback} : {};

  return {
    getValue: key => ({...(values[key] ?? fallback)}),
  }
}
