import _ from 'lodash'

export const Shape = (annotation) => {
  function draw (context, layoutParams, drawingOptions, highlight) {
  }
  function selectHere(x, y) {
  }
  function selectAll() {
  }
  function unselect() {
  }
  function isSelected() {
    return false;
  }
  function isHere(x, y) {
    return false;
  }
  function move(x, y, moveOptions) {
    return {x_ok: false, y_ok: false};
  }

  return {
    uuid: annotation.uuid,
    type: annotation.annotation_type,
    draw: draw,
    selectHere: selectHere,
    selectAll: selectAll,
    unselect: unselect,
    isSelected: isSelected,
    isHere: isHere,
    move: move,
  }
}

export const DrawingContext = (context) => {

  // we're assuming here that if it is not a canvas context it is a jsPdf doc
  const isCanvasContext = context instanceof CanvasRenderingContext2D;
  const pdf_font = 'helvetica'
  const font_family = pdf_font + ', sans-serif'

  return isCanvasContext ? canvasContext() : pdfContext();


  function canvasContext() {
    let ctx = {
      isCanvasContext: isCanvasContext
    };

    ctx.setGlobalAlpha = (alpha) => {
      context.globalAlpha = alpha;
    }

    // text
    ctx.measureText = (text) => {
      return context.measureText(text).width;
    }
    ctx.configureFont = (text_height) => {
      context.font = text_height + 'px ' + font_family;
    }
    ctx.setTextColor = (color_hex) => {
      context.fillStyle = color_hex;
    }
    ctx.writeText = (text, x, y, {align, baseline}) => {
      context.textAlign = align;
      context.textBaseline = baseline;
      context.fillText(text, x, y);
    }

    // stroke
    ctx.setStrokeColor = (color_hex) => {
      context.strokeStyle = color_hex;
    }
    ctx.setStrokeWidth = (width) => {
        context.lineWidth = width;
    }
    ctx.setLineJoin = (join) => {
      context.lineJoin = join;
    }
    ctx.setLineCap = (cap) => {
      context.lineCap = cap
    }
    ctx.strokeCircle = (x, y, r) => {
      ctx.beginPath();
      context.arc(x, y, r, 0, 2 * Math.PI);
      context.stroke(); 
    }
    ctx.stroke = () => {
      context.stroke(); 
    }

    // fill
    ctx.setFillColor = (color_hex) => {
      context.fillStyle = color_hex;
    }
    ctx.fillRect = (tlx, tly, brx, bry) => {
      context.fillRect(tlx, tly, brx, bry)
    }
    ctx.fillCircle = (x, y, r) => {
      ctx.beginPath();
      context.arc(x, y, r, 0, 2 * Math.PI);
      context.fill(); 
    }
    ctx.fill = () => {
      context.fill(); 
    }

    // path
    ctx.beginPath = () => {
      context.beginPath(); 
    }
    ctx.moveTo = (x, y) => {
      context.moveTo(x, y); 
    }
    ctx.lineTo = (x, y) => {
      context.lineTo(x, y); 
    }
    ctx.closePath = () => {
      context.closePath(); 
    }

    return ctx;
  }

  function pdfContext() {
    let ctx = {
      isCanvasContext: isCanvasContext
    };

    ctx.setGlobalAlpha = (alpha) => {
      context.setGState(new context.GState({'stroke-opacity': alpha, 'opacity': alpha}));
    }

    // text
    ctx.measureText = (text) => {
      return context.getTextWidth(text);
    }
    ctx.configureFont = (text_height) => {
      context.setFont(pdf_font);
      context.setFontSize(text_height * 96 / 72); // convert pixels to points for font size
    }
    ctx.setTextColor = (color_hex) => {
      let color = hexToRgb(color_hex);
      context.setTextColor(color.r, color.g, color.b);
    }
    ctx.writeText = (text, x, y, {align, baseline}) => {
      context.text(text, x, y, {align: align, baseline: baseline});
    }

    // stroke
    ctx.setStrokeColor = (color_hex) => {
      let color = hexToRgb(color_hex);
      context.setDrawColor(color.r, color.g, color.b);
    }
    ctx.setStrokeWidth = (width) => {
      context.setLineWidth(width);
    }
    ctx.setLineJoin = (join) => {
      context.setLineJoin(join)
    }
    ctx.setLineCap = (cap) => {
      context.setLineCap(cap)
    }
    ctx.strokeCircle = (x, y, r) => {
      context.circle(x, y, r, 'S');
    }
    ctx.stroke = () => {
      context.stroke(); 
    }

    // fill
    ctx.setFillColor = (color_hex) => {
      let color = hexToRgb(color_hex);
      context.setFillColor(color.r, color.g, color.b);
    }
    ctx.fillRect = (tlx, tly, brx, bry) => {
      context.rect(tlx, tly, brx, bry, 'F')
    }
    ctx.fillCircle = (x, y, r) => {
      context.circle(x, y, r, 'F');
    }
    ctx.fill = () => {
      context.fill(); 
    }

    // path
    ctx.beginPath = () => {
    }
    ctx.moveTo = (x, y) => {
      context.moveTo(x, y); 
    }
    ctx.lineTo = (x, y) => {
      context.lineTo(x, y); 
    }
    ctx.closePath = () => {
      context.close(); 
    }

    function hexToRgb(hex) {
      var bigint = parseInt(hex.substring(1), 16);
      var r = (bigint >> 16) & 255;
      var g = (bigint >> 8) & 255;
      var b = bigint & 255;
  
      return {r: r, g: g, b: b};
    }

    return ctx;
  }
}


export const ShapeUtils = () => {

  const HIGHLIGHTCOLOUR = '#00FF00'
  const HIGHLIGHTOPACITY = 0.5

  return {
    strokeWidth: strokeWidth,
    strokeTransparency: strokeTransparency,
    hotSpotRadius: hotSpotRadius,
    isOnHotSpot: isOnHotSpot,
    isOnPoint: isOnPoint,
    isOnLine: isOnLine,
    isBetweenPoints: isBetweenPoints,
    highlightPoints: highlightPoints,
    highlightVectorPoint: highlightVectorPoint,
    highlightPoint: highlightPoint,
    drawAnchors: drawAnchors,
    drawVectorAnchor: drawVectorAnchor,
    drawAnchor: drawAnchor,
    vectorIndiciesToPoints: vectorIndiciesToPoints,
    vectorAnchorsFromSelectedIndicies: vectorAnchorsFromSelectedIndicies,
    move: move,
    canMoveX: canMoveX,
    canMoveY: canMoveY,
  }

  function strokeWidth(stroke_scale, canvasHeight) {
    return (0.05 * canvasHeight - 1) / 100 * stroke_scale + 1;
  }

  function strokeTransparency(stroke_opacity) {
    return (stroke_opacity / 255);
  }

  function drawAnchors(ctx, points, radius) {
    _.forEach(points, (point) => {
      drawVectorAnchor(ctx, point, radius);
    })
  }

  function highlightPoints(ctx, points, radius) {
    // let previousFill = context.fillStyle;
    // let previousAlpha = context.globalAlpha;
    ctx.setFillColor(HIGHLIGHTCOLOUR);
    ctx.setGlobalAlpha(HIGHLIGHTOPACITY)

    _.forEach(points, function(point) {
      ctx.fillCircle(point.x, point.y, radius);
    });
    // context.fillStyle = previousFill;
    // context.globalAlpha = previousAlpha;
  }
  function highlightVectorPoint(ctx, point, radius) {
    highlightPoint(ctx, point, HIGHLIGHTCOLOUR, radius);
  }

  function highlightPoint(ctx, point, color, radius) {
    // let previousFill = context.fillStyle;
    // let previousAlpha = context.globalAlpha;
    ctx.setFillColor(color);
    ctx.setGlobalAlpha(HIGHLIGHTOPACITY)

    ctx.fillCircle(point.x, point.y, radius);

    // context.fillStyle = previousFill;
    // context.globalAlpha = previousAlpha;
  }

  function drawVectorAnchor(ctx, point, radius) {
    drawAnchor(ctx, point, HIGHLIGHTCOLOUR, radius);
  }

  function drawAnchor(ctx, point, centerColor, radius) {
    // let previousLineWidth = context.lineWidth;
    // let previousStrokeStyle = context.strokeStyle;
    // let previousFill = context.fillStyle;
    // let previousAlpha = context.globalAlpha;

    let strokeWidth = radius/4;
    ctx.setStrokeWidth(strokeWidth);
    ctx.setGlobalAlpha(1)
    ctx.setFillColor("#000000");
    ctx.fillCircle(point.x, point.y, radius);
    ctx.setStrokeColor("#FFFFFF");
    ctx.strokeCircle(point.x, point.y, radius);
    ctx.setFillColor(centerColor);
    ctx.fillCircle(point.x, point.y, strokeWidth * 1.5);

    // context.lineWidth = previousLineWidth;
    // context.strokeStyle = previousStrokeStyle;
    // context.fillStyle = previousFill;
    // context.globalAlpha = previousAlpha;
  }

  function isOnHotSpot(x, y, point, radius) {
    let lineLength = Math.sqrt((point.x - x) * (point.x - x) + (point.y - y) * (point.y - y));
    return lineLength <= radius;
  }

  function hotSpotRadius(canvasHeight) {
    return parseInt(((0.1 * canvasHeight) - 10) / 100 + 10);
  }

  function isOnPoint(x, y, point, line_width) {
    let lineLength = Math.sqrt((point.x - x) * (point.x - x) + (point.y - y) * (point.y - y));
    return lineLength <= line_width / 2;
  }

  function isOnLine(x, y, origin, end, line_width) {
    if (isBetweenPoints(x, y, origin, end)) {
      let distance = distanceFromLine(x, y, origin, end);
      return distance < line_width / 2;
    }
    return false;

    function distanceFromLine(x, y, origin, end) {
      let x0 = x;
      let x1 = origin.x;
      let x2 = end.x;
      let y0 = y;
      let y1 = origin.y;
      let y2 = end.y;

      let doubleAreaOfTriangle = Math.abs((x1*(y2-y0)) + (x2*(y0-y1)) + (x0*(y1-y2)));
      let lineLength = Math.sqrt((x2 - x1) **2 + (y2 - y1) **2);

      // from equation h = 2A/b
      let distance =  doubleAreaOfTriangle / lineLength;
      return distance;
    };
  }

  function isBetweenPoints(x, y, origin, end) {
    let headOk = ((origin.x - end.x) * (x - end.x) + (origin.y - end.y)*(y - end.y)) > 0;
    let tipOk = ((end.x - origin.x) * (x - origin.x) + (end.y - origin.y)*(y - origin.y)) > 0;

    return headOk && tipOk;
  }

  function vectorIndiciesToPoints(pointIndicies, start, end) {
    let selectedPoints = [];
    if (_.includes(pointIndicies, 0)) {
      selectedPoints.push(start);
    }
    if (_.includes(pointIndicies, 1)) {
      selectedPoints.push(end);
    }
    return selectedPoints;
  }

  function vectorAnchorsFromSelectedIndicies(selectedPointIndicies, start, end) {
    if (selectedPointIndicies.length == 1) {
      if (_.includes(selectedPointIndicies, 0)) {
        return [end];
      }
      if (_.includes(selectedPointIndicies, 1)) {
        return [start];
      }
    }
    return [];
  }


  function move(x, y, selectedPoints, overallSize) {

    let x_ok = canMoveX(x, selectedPoints, overallSize);
    let y_ok = canMoveY(y, selectedPoints, overallSize);
    let last = selectedPoints.length;
    for (let index = 0; index < last; index++) {
      let point = selectedPoints[index];
      if (x_ok) {
        let new_x = point.x + x;
        point.data.x = (new_x / overallSize.width).toFixed(8);
      }
      if (y_ok) {
        let new_y = point.y + y;
        point.data.y = (new_y / overallSize.height).toFixed(8);
      }
    }
    return {x_ok: x_ok, y_ok: y_ok};
  }
  function canMoveX(x, selectedPoints, overallSize) {
    let last = selectedPoints.length;
    for (let index = 0; index < last; index++) {
      let point = selectedPoints[index];
      let new_x = point.x + x;
      if ((new_x < 0 || new_x > overallSize.width) && point.x >= 0 && point.x <= overallSize.width) {
        return false;
      }
    }
    return true;
  }
  function canMoveY(y, selectedPoints, overallSize) {
    let last = selectedPoints.length;
    for (let index = 0; index < last; index++) {
      let point = selectedPoints[index];
      let new_y = point.y + y;
      if ((new_y < 0 || new_y > overallSize.height) && point.y >= 0 && point.y <= overallSize.height) {
        return false;
      }
    }
    return true;
  }
}

export const Utils = (() => { return ShapeUtils(); })();
