import _ from 'lodash'
import { _BaseField as Base } from './_base.js'

const type = 'guidance';
const GuidanceFieldImpl = (specification) => {

  const spec = _.cloneDeep(specification);

  const style = {};
  spec.style?.replace(/\s/gi,'').split(',')
    .forEach(s => {
      let split = s.split(':');
      style[split[0]] = split[1]
    });

  let isRollupStart = spec.rollup === 'start';
  let isRollupStop = spec.rollup === 'stop';

  const thisField = Base.create(type, spec);
  thisField.startsRollup = isRollupStart;
  thisField.stopsRollup = isRollupStart || isRollupStop;
  thisField.isDefaultCollapsed = style.collapsed === 'true';
  thisField.backgroundColorStyle = style['background-color'];
  thisField.colorStyle = style['color'];
  thisField.fontSizeStyle = style['font-size'];
  return thisField;
}

export const GuidanceField = {
  type,
  create: (specification) => GuidanceFieldImpl(specification),
}