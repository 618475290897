<template>
  <fieldset class="guidance-field" v-show="!isRow">
    <div v-show="isVisible" class="guidance-heading" v-bind:style="{backgroundColor: guidanceBackground}">
      <legend class="guidance-legend" v-bind:style="{color: guidanceColor}">
        <span v-if="fieldModel.startsRollup" v-on:click="toggle" :class="['toggle material-icons', {down: !fieldState.isRolledUp}]" title="Click to toggle">chevron_right</span>
        {{ label }}
      </legend>
      <p class="help-block" v-bind:style="{color: guidanceColor}" v-if="hasHelpText">{{ helpText }}</p>
    </div>
  </fieldset>
</template>

<script>
  import fieldMixin from '../../mixins/field_mixin'
  import fieldLabelMixin from '../../mixins/field_label_mixin'

  export default {
    mixins: [fieldMixin, fieldLabelMixin],
    computed: {
      guidanceColor: function() {
        return this.fieldModel.colorStyle;
      },
      guidanceBackground: function() {
        return this.fieldModel.backgroundColorStyle;
      },
    },
    methods: {
      toggle: function (e) {
        e.preventDefault();
        this.fieldState.toggleRollup();
      },
    },
  }
</script>

<style scoped lang="scss">
.guidance-heading {
  margin-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
  width: 620px;
  padding-left: 0.5em;  
}
.guidance-legend {
  border-bottom: 0px;  
  margin-bottom: 5px;
  line-height: 35px;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.toggle {
  cursor: pointer;
  font-weight: bold;
  margin-right: 4px;
  line-height: inherit;
  user-select: none;
  transition: 150ms;
}
.down {
  transform: rotate(90deg);
}
</style>