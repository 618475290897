

const BaseFieldImpl = (type, specification) => {

  return {
    type: type,
    reference: specification.reference,
    isUsedDefault: !specification.hidden,
    isRequired: !!specification.required,
    isReadOnly: !!specification.read_only,

    fieldPrompt: specification.prompt ?? '',
    
    hasDefault: false,
    getDefault: () => null,
  }
}

export const _BaseField = {
  create: (type, specification) => BaseFieldImpl(type, specification),
}
