import _ from 'lodash'
import { _BaseField as Base } from './_base.js'

const type = 'photo';
const PhotoFieldImpl = (specification) => {

  const spec = _.cloneDeep(specification);

  const textOptions = Array.isArray(spec.text_options)
      // TODO: remove the filter and replace with a spread once all customer templates have been amended
    ? Object.freeze(spec.text_options.filter(o => !o.startsWith('SEQ:')))
    : undefined;

  const restrictToCategories = Array.isArray(spec.restrict_to_categories) && spec.restrict_to_categories.length > 0
    ? Object.freeze([...spec.restrict_to_categories])
    : undefined;

  const sourceOptions = Array.isArray(spec.source) && spec.source.length > 0
    ? Object.freeze([...spec.source])
    : undefined;

  const locator = spec.locator?.template_text != null // template_text is required
    ? Object.freeze({
      template_text: spec.locator.template_text,
      label: spec.locator.label ?? 'item',
      color_by: spec.locator.color_by,
      required: !!spec.locator.required
    })
    : undefined;



  const thisField = Base.create(type, spec);
  
  thisField.textOptions = textOptions;
  thisField.restrictToCategories = restrictToCategories;
  thisField.sourceOptions = sourceOptions;
  thisField.locator = locator;
  thisField.zoom_to_annotations = !!spec.zoom_to_annotations;
  thisField.validate = validate;
  thisField.recalculateAnnotations = recalculateAnnotations;
  thisField.getLocatorText = getLocatorText;

  return thisField;


  /* end of construction */


  function recalculateAnnotations(jsonValue, labels, getCategoryValueFunc) {
    let changed = false;
    let newValue = _.cloneDeep(jsonValue);
    _.forEach(newValue?.annotations ?? [], (a) => {
      if (a.annotation_type == 'balloon' && !!a.annotation_data.template_text) {
        let label = labels?.find(l => l.template === a.annotation_data.template_text);
        if (label && label.value != a.annotation_data.text) {
          a.annotation_data.text = label.value;
          a.annotation_data.full_text = label.value;
          changed = true;
        }
        if (a.annotation_data.locator && locator) {
          let categoryValue = getCategoryValueFunc(locator.color_by);
          if (categoryValue) {
            let fillColor = categoryValue.fill_color;
            if (fillColor) {
              changed = changed || a.annotation_data.fill_color !== fillColor;
              a.annotation_data.fill_color = fillColor;
            }
            let textColor = categoryValue.text_color;
            if (textColor) {
              changed = changed || a.annotation_data.text_color !== textColor;
              a.annotation_data.text_color = textColor;
            }
          }
        }
      }
    });
    return changed ? newValue : jsonValue;
  }

  function getLocatorText(jsonValue) {
    return locator 
      ? jsonValue?.annotations?.find(a => a.annotation_data.locator)?.annotation_data.text
      : undefined;
  }

  function validate(jsonValue) {
    let errors = [];
    let isValid = true;

    if (!thisField.isReadOnly) {
      let requiredCheck = checkRequired(jsonValue);

      errors = errors.concat(requiredCheck.messages);
      isValid = isValid && requiredCheck.isValid;

      if (isPresent(jsonValue)) {
        let locatorCheck = validateLocator(jsonValue);
        errors = errors.concat(locatorCheck.messages);
        isValid = isValid && locatorCheck.isValid;
      }
    }

    return {
      isValid: isValid,
      errors: errors
    }
  }

  function checkRequired (jsonValue) {
    let isValid = !thisField.isRequired || isPresent(jsonValue);
    return {
      isValid: isValid,
      messages: isValid ? [] : ['This field is mandatory']
    };
  }

  function isPresent (jsonValue) {
    return jsonValue?.asset_id != null && jsonValue.asset_id !== '';
  }

  function validateLocator(jsonValue) {
    let isValid = !spec.locator?.required || jsonValue?.annotations?.some(a => a.annotation_data.locator);
    return {
      isValid: isValid,
      messages: isValid ? [] : ['Location of the ' + spec.locator.label + ' is required to be marked with a smart pin']
    }
  }
}

export const PhotoField = {
  type,
  create: (specification) => PhotoFieldImpl(specification),
}
