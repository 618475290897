import _ from 'lodash'
import { _BaseField as Base } from './_base.js'

const type = 'selection';

const SelectionFieldImpl = (specification, sourceLists) => {

  const spec = _.cloneDeep(specification);

  const minSelections = Number.isFinite(spec.minimum_selections) ? spec.minimum_selections : undefined;
  const maxSelections = Number.isFinite(spec.maximum_selections) ? spec.maximum_selections : undefined;

  let options = !Array.isArray(spec.options) ? []
    : spec.options.map(o => ({ key: o[0], value: o[1] }));

  if (options.length === 0) {
    if (spec.source && sourceLists) {
      options = sourceLists[spec.source]?.items
          ?.filter(i => i.use)
          .map(i => ({ key: i.key, value: i.value }))
        ?? [];
    }
  }

  const thisField = Base.create(type, spec);

  thisField.isRequired = options.length > 0 && minSelections != null && minSelections > 0;
  thisField.isMultiple = !!spec.multiple;
  thisField.fieldControlSpec = spec['field-control'];
  thisField.getOptions = () => [...options];
  thisField.getOptionsForFieldInstance = getOptionsForField;
  thisField.hasDefault = Array.isArray(spec.default_selections) && spec.default_selections.length > 0;
  thisField.getDefault = getDefault;
  thisField.parse = parse;
  thisField.validate = validate;

  return thisField;


  /* end of construction */


  function getOptionsForField(originalSelectionsInField) {
    let fieldOptions = [...options];

    if (Array.isArray(originalSelectionsInField)) {
      let keys = fieldOptions.map(o => o.key);
      _.forEach(originalSelectionsInField, function(dataItem) { 
        if (!keys.includes(dataItem.key)) {
          fieldOptions.unshift(dataItem);
        }
      });
    }
    return fieldOptions;
  }

  function getDefault() {
    let selectedKeys = !Array.isArray(spec.default_selections) ? [] 
      : spec.multiple ? spec.default_selections : [spec.default_selections[0]];
    let selectedOptions = options.filter(o => selectedKeys.includes(o.key));
    return { 
      type: type,
      selections: selectedOptions
    };
  }

  function validate(jsonValue) {
    let errors = [];
    let isValid = true;

    if (!thisField.isReadOnly) {
      let requiredCheck = checkRequired(jsonValue);
      let criteriaCheck = checkCriteria(jsonValue);

      errors = errors.concat(requiredCheck.messages, criteriaCheck.messages);
      isValid = isValid && requiredCheck.isValid && criteriaCheck.isValid;
    }

    return {
      isValid: isValid,
      errors: errors
    }
  }

  function checkRequired (jsonValue) {
    let isValid = !thisField.isRequired || isPresent(jsonValue);
    return {
      isValid: isValid,
      messages: isValid ? [] : ['This field is mandatory']
    };
  }

  function isPresent (jsonValue) {
    return Array.isArray(jsonValue?.selections) && jsonValue.selections.length > 0;
  }

  function checkCriteria(jsonValue) {
    let messages = [];
    let isValid = true;

    let min = Math.min(options.length, minSelections);
    let max = maxSelections;
    let hasMin = min != null && min > 0;
    let hasMax = max != null && max > 0;

    let selections = Array.isArray(jsonValue?.selections) ? jsonValue.selections : []
    let minMet = !hasMin || (selections.length >= min);
    let maxMet = !hasMax || (selections.length <= max);
    
    isValid = minMet && (maxMet || !spec.multiple);

    if (!isValid) {
      let message = "You must select "
        + (!spec.multiple ? "an item"
          : hasMin && hasMax 
            ? (min == max ? items(min)
              : "between " + min + " and " + items(max))
          : hasMax ? "no more than " + items(max)
          : "at least " + items(min));
      messages.push(message);
    }

    return {
      isValid: isValid,
      messages: messages,
    };

    function items(val) {
      return val + (val === 1 ? " item" : " items");
    }
  }

  function parse(values, originalSelectionsInField) {
    values = values ? _.castArray(values).map(v => (typeof v === 'string' || v instanceof String) ? v.trim() : v) : [];
    let selections = [];

    if (values.length > 0) {
      let fieldOptions = getOptionsForField(originalSelectionsInField);

      // assume its keys
      selections = _.filter(fieldOptions, o => _.includes(values, o.key));

      if (selections.length == 0) {
        // might have been passed value strings instead
        selections = _.filter(fieldOptions, o => _.includes(values, o.value));
      }
      if (selections.length == 0) {
        // might have been passed key-value pair objects instead
        selections = _.filter(fieldOptions, o => _.find(values, v => (typeof v === 'object') && ('key' in v) && v.key.trim() == o.key));
      }
    }

    let output = null;
    let messages = [];
    if (!spec.multiple && selections.length > 1) {
      messages.push("Selections would overflow the field");
      selections = [selections[0]];
    }
    output = {
      type: type,
      selections: selections,
    };
    return {
      data: output,
      messages: messages,
    };
  }
}


export const SelectionField = {
  type,
  create: (specification, sourceLists) => SelectionFieldImpl(specification, sourceLists),
}