<template>
  <div class="inspection-editor" ref="inspectionEditor">
    <div v-if="!readOnly">
      <ul v-if="validationErrors" class="error-summary">
        <li v-for="(error, key) in validationErrors" :key="key">{{key}}: {{error}}</li>
      </ul>
      <select name="save_instructions" id="save_instructions" v-model="saveChoice" :multiple="false" class="edit-choices" >
        <option :value="option[1]" v-for="option in saveChoices" :key="option[1]">{{ option[0] }}</option>
      </select>&nbsp;
      <button class="btn btn-primary" @click.prevent="trySubmit">Submit</button>
      <br><br>
      <div classs="control-group">
        <label class="control-label">Assign to ...</label>
        <div class="controls" v-if="haveAssessorList">
          <option-selector
            class="assessor-chooser"
            :options="assessorOptions"
            :selectedKeys="(chosenAssessor ? [chosenAssessor] : [])"
            :multiple="false"
            :searchable="true"
            :clearable="true"
            placeholder="Select from Staff on Work Package"
            @update:selectedKeys="val => $emit('update:chosenAssessor', val?.[0])"
            />
        </div>
        <div v-else>
          <b>There are no staff assigned to this workpackage...</b>
        </div>
      </div>
    </div>
    <br><br>
    <div class="alert alert-error small-alert error-finder" v-if="displayErrors">There are errors on some of the fields - please check for warnings.
      <button class="btn btn-small error-button" @click.prevent="scrollToNext">Next Error</button>
    </div>
    <div class="alert alert-error small-alert" v-if="displayNeedAssessor">You need to assign a member of staff before this can be completed.</div>
    <inclusion-section 
      :inclusionsSchema="inclusionsSchema" 
      :inclusions="inclusions" 
      @update:inclusions="inclusions => $emit('update:inclusions', inclusions)" 
      :read-only="readOnly">
    </inclusion-section>
    <inspection-section 
      :fields="fields"
      :fieldListState="fieldListState" 
      :solution-type="solutionType"
      :data="data" 
      :fieldListModel="fieldList"
      @update:data="data => updateData(data)" 
      :read-only="readOnly" 
      :drawing-sets="drawingSets"
      >
    </inspection-section>
    <input type="hidden" :class="[{dirty: isDirty}]">
    <!-- 60 px clears the top bar -->
    <snackbar
      :offsetFromEdgePx="60"
      :isTop="true" 
      :isLongDuration="true" 
      :message="snackbarMessage" />
  </div>
</template>

<script>
  import InspectionSection from './inspection_section.vue'
  import InclusionSection from './inclusion_section.vue'
  import labelTemplateMixin from '../mixins/label_template_mixin'
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
  import optionSelector from './utils/option_selector.vue'
  import Snackbar from './utils/snackbar.vue';
  import { FieldList } from '../lib/fields/field_list.js'
  import { FieldListState } from '../lib/fields/field_list_state.js'

  const InspectionCache = () => {

    const storagePrefix = 'inspection:'

    function getItemIfAfter(id, dateString) {
      let item = getItem(id);
      if (item) {
        let inDate = new Date(dateString);
        let itemDate = new Date(item.inspection_date);
        if (inDate < itemDate && itemDate > nowOffset()) {
          return item.value;
        }
      }
      return null;
    }
    function getItem(id) {
      try {
        let value = localStorage.getItem(storageKey(id));
        if (value) {
          return JSON.parse(value);
        }
      } catch (error) { }
      return null;
    }
    function setItem(id, value) {
      let retry = true;
      while(retry) {
        try {
          localStorage.setItem(storageKey(id), JSON.stringify({inspection_date: new Date().toISOString(), value: value}));
          retry = false;
        } catch (err) {
          retry = isQuotaError(err) && deleteOldest();
        }
      }
    }
    function removeItem(id) {
      try {
        localStorage.removeItem(storageKey(id));
      } catch (error) { }
    }

    function nowOffset() {
      let nowOffset = 1000 * 60 * 30; // 30 minutes
      return new Date(new Date().getTime() - nowOffset);
    }
    function storageKey(id) {
        return storagePrefix + id;
    }
    function isQuotaError(err) {
      return err instanceof DOMException &&
      err.name === "QuotaExceededError";
    }

    function removeExpired() {
      try {
        let nowOffsetDate = nowOffset();
        for (let i = localStorage.length - 1; i >= 0; i--) {
          try {
            let key = localStorage.key(i);
            if (key.startsWith(storagePrefix)) {
              let item = JSON.parse(localStorage.getItem(key));
              if(new Date(item.inspection_date) < nowOffsetDate) {
                localStorage.removeItem(key);
              }
            }
          } catch (err) { }
        }
      } catch (err) { }
    }
    function deleteOldest() {
      try {
        let comparisonDate = new Date();
        let key = null;
        for (let i = localStorage.length - 1; i >= 0; i--) {
          try {
            let thisKey = localStorage.key(i);
            if (thisKey.startsWith(storagePrefix)) {
              let item = JSON.parse(localStorage.getItem(thisKey));
              let itemDate = new Date(item.inspection_date);
              if(itemDate < comparisonDate) {
                key = thisKey;
                comparisonDate = itemDate;
              }
            }
          } catch (err) { }
        }
        if (key) {
          localStorage.removeItem(key);
          return true;
        }
      } catch (err) { }
      return false;
    }

    // listeners

    const listeners = [];

    function addEventListener(listener) {
      listeners.push(listener);
      if (listeners.length === 1) {
        window.addEventListener('storage', storageListener);
      }
    }
    function removeEventListener(listener) {
      let index = listeners.indexOf(listener);
      if (index >= 0) {
        listeners.splice(index, 1);
      }
      if (listeners.length === 0) {
        window.removeEventListener('storage', storageListener);
      }
    }

    function storageListener(event) {
      if (listeners.length > 0 && event.key.startsWith(storagePrefix)) {
        let id = event.key.substring(storagePrefix.length);
        for (let i = listeners.length - 1; i >= 0; i--) {
          let oldValue = event.oldValue ? JSON.parse(event.oldValue).value : null;
          let newValue = event.newValue ? JSON.parse(event.newValue).value : null;
          let newEvent = new StorageEvent('storage', {key: id, url: event.url, oldValue: oldValue, newValue: newValue});
          let listener = listeners[i];
          listener(newEvent);
        }
      }
    }

    return {
      getItemIfAfter,
      setItem,
      removeItem,
      removeExpired,
      addEventListener,
      removeEventListener,
    }
  }


  const inspectionCache = InspectionCache();

  export default {
    components: {
      InspectionSection,
      InclusionSection,
      optionSelector,
      vSelect,
      Snackbar,
    },
    mixins: [labelTemplateMixin],
    props: {
      uuid: String, // must be null for new inspections
      templateUuid: String,
      workPackageUuid: String,
      schema: Object,
      classifiers: Array,
      categories: Object,
      listsToUse: Object,
      solutionType: String,
      data: Object,
      inclusions: Object,
      readOnly: Boolean,
      newForm: Boolean,
      drawingSets: Array,
      saveInstructions: Array,
      saveDefault: Number,
      availableAssessors: Array,
      chosenAssessor: Number,
      updatedAtUtc: String,
      validationErrors: Object,
    },
    provide: function () {
      return {
        sourceLists: this.listsToUse,
      }
    },
    data: function () {
      let fieldList = FieldList.create(this.schema.fields, this.listsToUse, this.classifiers, this.categories);

      return {
        initialData: this.data,
        fieldList: fieldList,
        fieldListState: FieldListState.create(fieldList, this.data),
        controlledFieldStates: {},
        saveChoice: this.saveDefault,
        saveChoices: this.saveInstructions,
        showValidation: false,
        errorPositions: [],
        snackbarMessage: null,
        starting: true,
      }
    },
    watch: {
      saveChoice: function (newChoice) {
        this.$emit('update:saveChoice', newChoice);
        document.getElementById('save_instructions').value = newChoice;
      }
    },
    computed: {
      assessorOptions: function() {
        return this.availableAssessors.map(a => ({ key: a[1], value: a[0] }));
      },
      hasAssessor: function () {
        return !_.isNil(this.chosenAssessor);
      },
      haveAssessorList: function () {
        return !_.isEmpty(this.assessorOptions);
      },
      displayNeedAssessor: function () {
        return this.showValidation && !this.hasAssessor;
      },
      displayErrors: function () {
        return this.showValidation && this.fieldListState.hasValidationErrors;
      },
      fields: function () {
        return this.schema.fields;
      },
      inclusionsSchema: function () {
        return this.schema.inclusions;
      },
      labelTemplateStr: function () {
        return this.schema.instance_label;
      },
      storageId: function() {
        return this.uuid ?? this.workPackageUuid + ":" + this.templateUuid;
      },
      isDirty: function () {
        return !(_.isEqual(this.initialData, this.data));
      },
    },
    mounted() {
      if (!this.readOnly) {
        inspectionCache.removeExpired();
        let updatedData = this.updateFromStorage();
        if (this.newForm && !updatedData) {
          updatedData = this.fieldList.defaultData();
        }
        if (updatedData) {
          this.emitUpdateData(updatedData);
        }
        // TODO: note this is constructed with data pushed up not data bounced back
        this.fieldListState = FieldListState.create(this.fieldList, updatedData ?? this.data);

        inspectionCache.addEventListener(this.onInspectionCacheUpdate);
        this.$nextTick(() => this.starting = false);
        let vm = this;
        $(document).bind('proceed.dirtyforms', function() { 
          vm.clearStorage();
        });
      }
    },
    unmounted() {
      inspectionCache.removeEventListener(this.onInspectionCacheUpdate);
    },
    methods: {
      scrollToNext: function () {
        let vm = this;
        let offset = 85;
        let currentPosition = document.documentElement.scrollTop + offset + 1;
        vm.warningSigns();
        if (!_.isEmpty(vm.errorPositions)) {
          let nextPosition = _.find(vm.errorPositions, function(o) { return o > currentPosition })
          document.documentElement.scrollTo(0,nextPosition - offset);
        }
      },
      warningSigns: function () {
        let vm = this;
        let errorNodes = vm.$refs.inspectionEditor.querySelectorAll('p.criteria-block');
        vm.errorPositions = _.map(errorNodes, function (o) { return o.getBoundingClientRect().top + document.documentElement.scrollTop })
      },
      trySubmit: function () {
        if (this.saveChoice == 4) {
          this.showValidation = true;
          this.validateData(this.data);
          if (!this.fieldListState.hasValidationErrors && this.hasAssessor) { 
            this.submitForm();
          } else {
            this.fieldListState.exposeErrors();
          }
        } else {
          this.submitForm();
        }
      },
      validateData: function(data) {
        this.fieldListState.validateAll(this.fieldList, data);
      },
      onInspectionCacheUpdate: function(event) {
        if (!this.readOnly && event.key === this.storageId) {
          // get the value from here because this checks the expiry of the data too
          let data = inspectionCache.getItemIfAfter(this.storageId, this.updatedAtUtc);
          if (data) {
            this.emitUpdateData(data);
          } else if (event.oldValue && event.newValue === null) {
            // if there's no new value but there was an old one - you've sumitted the form somewhere else
            if (this.uuid) {
              // reload for existing inspections
              window.location.reload();
            } else {
              // retain the local cache for new inspections
              inspectionCache.setItem(this.storageId, this.data);
            }
          }
        }
      },
      updateFromStorage: function() {
        if (!this.readOnly) {
          let data = inspectionCache.getItemIfAfter(this.storageId, this.updatedAtUtc);
          if (data) {
            if (this.uuid) {
              this.showMessage('Changes from your previous unsaved session have been applied.');
            } else {
              this.showMessage('Your previous session has been restored. PLEASE NOTE: you cannot create two new inspections of this type at the same time.');
            }
            return data;
          }
        }
        return null;
      },
      clearStorage: function() {
        // this action has side-effects in other windows/tabs - see onInspectionCacheUpdate function above
        inspectionCache.removeItem(this.storageId);
      },
      submitForm: function() {
        this.setFormClean();
        document.getElementById('edit_inspection').submit();
        this.clearStorage();
      },
      setFormClean: function () {
        let curr = this.$el;
        while (!(curr instanceof HTMLFormElement) && curr.parentElement) {
          curr = curr.parentElement;
        }
        if (curr instanceof HTMLFormElement) {
          $(curr).dirtyForms('setClean');
        }
      },
      updateData: function (data) {
        if (!this.starting) {
          inspectionCache.setItem(this.storageId, data);
        }
        this.emitUpdateData(data);
      },
      emitUpdateData: function(data) {
        this.$emit('update:data', data);
      },
      showMessage(message) {
        this.snackbarMessage = message;
        this.$nextTick(() => this.snackbarMessage = null);
      },
    }
  }
</script>

<style scoped lang="scss">
  .edit-choices {
    margin-bottom: 0px;
    width: 350px;
    display: inline-block;
  }
  .small-alert {
    width: 569px;
    margin-bottom: 5px;
  }
  .assessor-chooser {
    width: 350px;
    background: #ffffff;
  }
  .error-finder {
    position: sticky;
    top: 20px;
    z-index: 1;
  }
  .error-button {
    right: 2px;
    position: absolute;
    top: 4px;
  }
  .error-summary {
    font-weight: bold; 
    color: #FF0000;
  }

</style>