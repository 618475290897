<template>
  <div class="drawing-layout" ref="widgetContainer">
    <div class="layout-options">
      <div>
        <select v-model="selectedDrawing" id="drawingoptions" class="drawing-select" :disabled="!canSelectDrawing">
          <optgroup :label="drawingSet.drawing_set_name" v-for="drawingSet in drawingSets" :key="drawingSet.drawing_set_name">
            <option :value="option.id" v-for="option in drawingSet.drawings" :key="option.id">{{ option.drawing_name }}</option>
          </optgroup>
        </select>
      </div>

      <div v-show="showInspectionLink" class="link-out">
        <a v-bind:href="inspectionUrl" target="_blank" title="Click to open inspection in a new tab or window">
          {{ openInspectionLabel }}
          <span class="btn btn-primary btn-mini options-mini-button">
            <i class="icon-share icon-white"></i>
          </span>
        </a>
      </div>

      <div class="options-group">
        <button class="btn btn-success options-mini-button" v-show="!addingMode && !readOnly" @click="showBulk = true">Rearrange...</button>
        <div class="dropdown" title="Click for drawing save options">
          <button data-toggle="dropdown" class="btn btn-primary options-mini-button dropdown-toggle">
            Choose action &nbsp;&nbsp;<span class="caret"></span>
          </button>
          <ul class="dropdown-menu pull-right" role="menu">
            <li class="save-option" title="Download whole drawing as image" @click="startSave(false, false, true)">Download drawing</li>
            <li class="save-option" title="Download visible area as image" @click="startSave(true, false, true)">Download visible area</li>
            <li class="save-option" title="Copy whole drawing to the clipboard as image" @click="startSave(false, true, true)">Copy drawing to clipboard</li>
            <li class="save-option" title="Copy visible area to the clipboard as image" @click="startSave(true, true, true)">Copy visible area to clipboard</li>
            <li class="divider"></li>
            <li class="save-option" title="Download whole drawing as PDF" @click="startSave(false, false, false)">Download drawing as PDF</li>
          </ul>
        </div>
        <div class="btn-group">
          <button class="btn btn-primary btn-mini options-mini-button" @click.prevent="zoomIn" :disabled="maxZoomReached" title="Click to zoom in"><i class="icon-plus icon-white"></i></button>
          <button class="btn btn-primary btn-mini options-mini-button" @click.prevent="zoomOut" :disabled="minZoomReached" title="Click to zoom out"><i class="icon-minus icon-white"></i></button>
        </div>
      </div>
    </div>
    <drawing-view
      :background="assetImage"
      :annotations="viewAnnotations"
      :currentAnnotationId="selectedViewAnnotationId"
      :selectedAnnotationIds="selectedViewAnnotationIds"
      :relativeZoomPosition="relativeZoomPosition"
      :findOrigin="findOrigin"
      :drawSettings="drawSettings"
      :groupedColorSchemes="groupedColorSchemes"
      :saveFunctionOptions="saveFunctionOptions"
      :readOnly="!canMoveAnnotations"
      @update:annotations_data="val => updateAnnotationsData(val)"
      @update:currentViewAnnotationId="val => updateCurrentAnnotationId(val.annotation_id, val.selected_row_index)"
      @update:mergeFunction="val => { annotationMergeFunction = val }"
      @saveImageData="val => saveImageData(val, saveToClipboard)"
      @pdf_doc="val => savePdf(val)"
      @left_click_relative_location="val => onLeftClickLocation(val)"
      @zoom="val => changeZoom(val)"
    />
    <drawing-bulk-arrange v-if="showBulk && !readOnly"
      :background="assetImage"
      :annotations="bulkArrangeAnnotations"
      :readOnly="readOnly"
      :drawSettings="drawSettings"
      :groupedColorSchemes="groupedColorSchemes"
      @close="showBulk = false"
      @saveImageData="val => saveImageData(val.data, val.toClipboard)"
      @pdf_doc="val => savePdf(val)"
      @bulk_moved_annotations="val => this.$emit('bulk_moved_annotations', val)"
      @updateSettings="val => $emit('updateSettings', val)"
      @saveSettings="val => $emit('saveSettings', val)"
    />
    <snackbar :message="snackbarMessage" />
  </div>
</template>

<script>
  import _ from 'lodash'
  import { AnnotationTypes } from "../../lib/annotations/annotation"
  import DrawingView from './drawing_view.vue'
  import DrawingBulkArrange from './drawing_bulk_arrange.vue'
  import Snackbar from '../utils/snackbar.vue'

  const ZOOM_STEP_MAX = 7;
  const ZOOM_STEP_MIN = 1;

  const AnnotationType = AnnotationTypes();

  export default {
    components: {
      DrawingView,
      DrawingBulkArrange,
      Snackbar,
    },
    props: {
      drawingSets: Array,
      initialDrawing: Number,
      currentDrawing: Object,
      annotations: Array,
      currentSelectedAnnotation: String,
      addingMode: Boolean,
      newBalloonText: String,
      readOnly: Boolean,
      annotationId: String,
      findOrigin: Boolean,
      drawSettings: Object,
      groupedColorSchemes: Object,
    },
    data: function () {
      let initialSelected = _.find(this.annotations, (a) => {return a.uuid === this.currentSelectedAnnotation});
      return {
        selectedDrawing: this.initialDrawing,
        assetImage: null,
        selectedAnnotation: initialSelected,
        selectedViewAnnotation: null,

        newAnnotation: null,

        zoomLevel: ZOOM_STEP_MIN,

        annotationMergeFunction: function (annotations) {
          return annotations;
        },
        mergedAnnotations: [],

        saveToClipboard: false,
        snackbarMessage: null,

        showBulk: false,
        bulkArrangeAnnotations: [],

        saveFunctionOptions: null,
        inspectionUrl: '',
      };
    },
    watch: {
      addingMode: function(val) {
        if (!val) {
          this.newAnnotation = null;
        }
      },
      showBulk: function(val) {
        this.bulkArrangeAnnotations = val ? _.cloneDeep(this.annotations) : [];
      },
      selectedDrawing: function (newValue) {
        this.$emit('update:chooseDrawing', {selectedDrawing: newValue})
      },
      currentDrawing: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.loadImage();
        }
      },
      
      annotations: function () {
        if (this.showBulk && this.bulkArrangeAnnotations.length == 0) {
          this.bulkArrangeAnnotations = _.cloneDeep(this.annotations);
        }
        this.mergedAnnotations = this.annotationMergeFunction(this.annotations);
        this.selectedAnnotation = _.find(this.annotations, (a) => {return a.uuid === this.currentSelectedAnnotation});
      },
      annotationMergeFunction: function (val) {
        this.mergedAnnotations = val(this.annotations);
      },
      mergedAnnotations: function() {
        this.updateSelectedViewAnnotation();
      },
      currentSelectedAnnotation: function() {
        this.updateSelectedViewAnnotation();
        this.selectedAnnotation = _.find(this.annotations, (a) => {return a.uuid === this.currentSelectedAnnotation});
      },
      selectedAnnotation: function(val) {
        let vm = this;
        this.$emit('get:inspectionUrl', {annotation: val, callback: (url) => {
          vm.inspectionUrl = url;
        }});
      },

      newBalloonText: function (newValue) {
        if (!_.isEmpty(newValue) && this.newAnnotation) {
          let localAnnotation = _.cloneDeep(this.newAnnotation);
          localAnnotation.annotation_data.text = newValue;
          localAnnotation.annotation_data.full_text = newValue;
          this.newAnnotation = localAnnotation;
        };
        if (_.isEmpty(newValue)) {
          this.newAnnotation = null;
        }
      },
      newAnnotation: function (newValue){
        this.$emit('update:newAnnotation', newValue);
      },
    },
    computed: {
      maxZoomReached: function () {
        return (this.zoomLevel == ZOOM_STEP_MAX);
      },
      minZoomReached: function () {
        return (this.zoomLevel == ZOOM_STEP_MIN);
      },
      relativeZoomPosition: function () {
        return (this.zoomLevel - ZOOM_STEP_MIN) / (ZOOM_STEP_MAX - ZOOM_STEP_MIN);
      },
      canMoveAnnotations: function () {
        return !(this.readOnly || this.addingMode);
      },
      canSelectDrawing: function () {
        return this.assetImage;
      },
      selectedViewAnnotationIds: function () {
        return this.selectedViewAnnotationId ? [this.selectedViewAnnotationId] : [];
      },
      viewAnnotations: function() {
        return this.addingMode && this.newAnnotation ? _.concat(this.mergedAnnotations, this.newAnnotation) : this.mergedAnnotations;
      },
      showInspectionLink: function() {
        return !!this.inspectionUrl;
      },
      openInspectionLabel: function() {
        return "Open inspection for '" + (this.selectedAnnotation?.annotation_data?.full_text ?? '') + "'";
      },
      selectedViewAnnotationId: function() {
        return this.selectedViewAnnotation?.uuid;
      },
    },
    methods: {
      changeZoom: function (zoomin) {
        if (zoomin) this.zoomIn();
        else this.zoomOut();
      },
      zoomIn: function () {
        if (this.zoomLevel < ZOOM_STEP_MAX) { this.zoomLevel += 1 };
      },
      zoomOut: function () {
        if (this.zoomLevel > ZOOM_STEP_MIN) { this.zoomLevel -= 1 };
      },
      onLeftClickLocation(location) {
        if (this.addingMode && !_.isEmpty(this.newBalloonText)) {
          let offset = 0.07;
          let centre_x = location.x > 1 / 6 && location.x < 5 / 6
          let origin_x = location.x;
          let origin_y = location.y;
          let end_x = centre_x ? origin_x
            : origin_x + offset * (origin_x < 0.5 ? 1 : -1);
          let end_y = origin_y + offset * (origin_y < 0.5 ? 1 : -1);

          let localAnnotation = {
            annotation_type: AnnotationType.balloon,
            uuid: this.annotationId,
            linked_feature: "",
            annotation_data: {
              origin: {x: origin_x.toFixed(8), y: origin_y.toFixed(8)},
              end_point: {x:end_x.toFixed(8), y: end_y.toFixed(8)},
              text_color: '#000000',
              fill_color: '#FFFFFF',
              text: this.newBalloonText,
              full_text: this.newBalloonText
            }
          }
          this.newAnnotation = localAnnotation;
        }
      },

      updateSelectedViewAnnotation: function() {
        let annotations = this.mergedAnnotations;
        let id = this.currentSelectedAnnotation;
        let viewAnnotation = annotations.find(a => a.uuid == id)
          ?? annotations.find(a => a.annotation_type === AnnotationType.mergeBalloon && a.text_balloons.map(b => b.uuid).includes(id));
        this.selectedViewAnnotation = viewAnnotation;
      },
      updateAnnotationsData: function (data) {
        let emitData = [];
        _.forEach(data, d => {
          let local = _.find(this.mergedAnnotations, a => a.uuid == d.uuid);
          if (local) {
            let newData = d;
            if (this.isLocallyMergedBalloon(local)) {
              local.annotation_data = d.data;
              // move the head of the first balloon in the list of text_balloons
              let firstBalloon = local.text_balloons[0];
              let firstData = firstBalloon.annotation_data;
              firstData.end_point = { x: d.data.end_point.x, y: d.data.end_point.y };
              newData = { uuid: firstBalloon.uuid, data: firstData };
            }
            emitData.push(newData);
          }
        });
        this.$emit('update:annotations_data', emitData)
      },
      updateCurrentAnnotationId: function (viewId, selectedRowIndex) {
        let selectedId = null;
        let local = _.find(this.mergedAnnotations, a => a.uuid == viewId);
        if (local) {
          selectedId = local.uuid;
          if (this.isLocallyMergedBalloon(local) && selectedRowIndex >= 0) {
            selectedId = local.text_balloons[selectedRowIndex]?.uuid;
          }
        }
        this.$emit('update:currentAnnotationId', selectedId);
      },
      isLocallyMergedBalloon: function(annotation) {
        return annotation.annotation_type === AnnotationType.mergeBalloon && !this.annotations.find(a => a.uuid == annotation.uuid)
      },

      loadImage: function () {
        this.assetImage = null;
        let img = new Image();
        img.crossOrigin = "anonymous";
        let asset_id = this.currentDrawing.asset_id
        img.onload = () => this.onImageLoad(img); 
        this.$emit('get:assetUrl', {asset_id: asset_id, callback: (url) => {
          img.src = url;
        }});
      },
      onImageLoad: function (img) {
        this.assetImage = img;
        this.zoomLevel = ZOOM_STEP_MIN;
        this.$emit('backgroundLoaded', img);
      },

      startSave: function(onlyVisibleView, toClipboard, isImage) {
        this.saveToClipboard = toClipboard;
        this.saveFunctionOptions = { area: onlyVisibleView ? 'only_visible_view' : 'entire_drawing', format: isImage ? 'image' : 'pdf' };
        this.$nextTick(() => this.saveFunctionOptions = null);
      },
      saveImageData: function(blob, saveToClipboard) {
        if (blob) {
          if (saveToClipboard) {
            const data = [new ClipboardItem({ [blob.type]: blob })];
            const vm = this;
            navigator.clipboard.write(data).then(
              () => {
                vm.showMessage("Drawing copied to clipboard.");
              },
              (error) => {
                vm.showMessage("Unable to copy the drawing to the clipboard.");
              }
            );
          } else {
            let fileName = window.prompt("Enter a file name", "drawing");
            if (fileName) {
              downloadFile(blob, fileName);
            }
          }
        } else {
          vm.showMessage("Unable save the drawing.");
        }
      },
      savePdf: function(doc) {
        let fileName = window.prompt("Enter a file name", "drawing");
        if (fileName) {
          doc.save(fileName);
        }
      },

      showMessage(message) {
        this.snackbarMessage = message;
        this.$nextTick(() => this.snackbarMessage = null);
      },
    }
  }

  const downloadFile = (blob, fileName) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  };

</script>

<style scoped lang="scss">
.options-mini-button {
  border-top-width: 0px;
  padding-top: 0px;
  padding-bottom: 2px;
  border-bottom-width: 0px;
}
.layout-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.options-group {
  display: flex;
  column-gap: 24px;
  align-items: center;
}
.drawing-select {
  margin-bottom: 0;
}
.save-option {
  white-space: nowrap;
  padding: 6px;
}
.save-option:hover {
  color: white;
  background-color: black;
  cursor: pointer;
}
.button-icon {
  position: relative;
  top: 2px;
  font-size: 16px;
}

.inline-block {
  display: inline-block;
}
</style>
