import { 
  FeatureField,
  GuidanceField,
  PhotoField,
  SelectionField,
  TextField,
} from './_fields.js'

import { LabelTemplate } from '../label_template.js'


export const formViewId = "_form_view";

export const FieldListState = {
  create: (fieldList, jsonData) => FieldListStateImpl(fieldList, jsonData, false).buildListState(),
}


const labelTemplate = LabelTemplate();

const FeatureValue = (fieldModel, jsonData, isRows) => {
  const featureInstanceList = {};

  const expandedState = {}

  var defaultExpandedState = true;

  var currentViewId = formViewId;

  var isDataRows = !!isRows;

  jsonData.values.forEach(i => newInstance(i, fieldModel));

  return {
    getInstances: buildInstanceList,
    isDataRows: () => isDataRows,
    isDefaultExpanded: () => defaultExpandedState,
    getCurrentViewId: () => currentViewId,
    setCurrentViewId: setCurrentViewId,
    expandAll: expandAll,
    updateInstances: updateInstances,
    validateInstances:validateInstances,
    hasValidationErrors: hasValidationErrors,
    exposeErrors: exposeErrors,
  }

  /* end of construction */


  function buildInstanceList(rebuildFunc) {
    let instances = {};
    Object.keys(featureInstanceList).forEach(id => instances[id] = buildInstanceState(id, rebuildFunc));
    return instances;

    function buildInstanceState(id, rebuildFunc) {
      let instanceState = {
        isDataRow: isDataRows,
        isExpanded: expandedState[id],
        fieldListState: featureInstanceList[id].buildListState(),
        toggleExpanded: () => {
          expandedState[id] = !expandedState[id];
          rebuildFunc();
        },
      };
      return instanceState;
    }
  }

  function setCurrentViewId(viewId) {
    currentViewId = viewId;
    isDataRows = currentViewId != formViewId;
    Object.values(featureInstanceList).forEach(val => val.setIsDataRow(isDataRows));
  }

  function expandAll(expand) {
    defaultExpandedState = !!expand;
    Object.keys(expandedState).forEach(k => expandedState[k] = defaultExpandedState)
  }

  function newInstance(jsonInstance, fieldModel) {
    featureInstanceList[jsonInstance.id] = FieldListStateImpl(fieldModel.getFieldList(), jsonInstance.data, isDataRows);
    expandedState[jsonInstance.id] = defaultExpandedState;
  }

  function updateInstances(fieldModel, jsonData) {
    let newInstanceIds = jsonData?.values.map(i => i.id);
    let itemsToRemove = Object.keys(featureInstanceList).filter(k => !newInstanceIds.includes(k));
    itemsToRemove.forEach(i => delete featureInstanceList[i])

    let itemsToAdd = jsonData?.values.filter(i => !featureInstanceList[i.id]);
    itemsToAdd.forEach(i => newInstance(i, fieldModel));
  }

  function validateInstances(fieldModel, jsonData) {
    Object.entries(featureInstanceList).forEach(e => e[1].validateAll(fieldModel.getFieldList(), jsonData.values.filter(v => v.id == e[0])[0]?.data));
  }

  function hasValidationErrors() {
    return Object.values(featureInstanceList).findIndex(val => val.hasValidationErrors()) >= 0;
  }

  function exposeErrors() {
    Object.entries(featureInstanceList).forEach(e => {
      if (e[1].hasValidationErrors()) {
        e[1].exposeErrors();
        expandedState[e[0]] = true;
        setCurrentViewId(formViewId)
      }
    });
  }
}



const FieldListStateImpl = (fieldList, jsonData, isRow) => {

  const initialData = {};

  const fieldListRefs = fieldList.getFieldRefs();

  const featureValues = {};

  const rollupCoverage = {};

  const labelSpecs = [];


  const touchedFields = {};

  const rollupStates = {};

  const inUseFieldStates = {};

  const fieldLabels = {};

  var validationErrors = {};

  var classifications = {};

  var showValidation = false;

  var isDataRow = isRow;


  let lastRollup = null;
  fieldListRefs.forEach(ref => {
    let field = fieldList.getField(ref);
    if (field.type === FeatureField.type) {
      let fieldVal = jsonData?.[field.reference];
      if (fieldVal?.type === FeatureField.type && fieldVal.values && fieldVal.values.length > 0) {
        featureValues[field.reference] = FeatureValue(field, fieldVal, isDataRow);
      }
    } else if (field.type === GuidanceField.type) {
      if (field.startsRollup) {
        rollupStates[field.reference] = field.isDefaultCollapsed;
        lastRollup = field.reference;
      } else if (field.stopsRollup) {
        lastRollup = null;
      }
    } else if (field.type === PhotoField.type) {
      let templates = [];
      field.textOptions?.forEach(o => {
        if (!templates.find(t => t === o)) {
          templates.push(o);
        }
      });
      if (field.locator?.template_text && !templates.find(t => t === field.locator.template_text)) {
        templates.push(field.locator.template_text);
      }
      templates.forEach(t => {
        let spec = labelSpecs.find(s => s.template === t);
        if (!spec) {
          spec = {
            template: t,
            allowEmptyFields: true,
            fields: [],
          }
          labelSpecs.push(spec);
        }
        spec.fields.push(ref);
      })
    }
    if (lastRollup && lastRollup != field.reference) {
      rollupCoverage[field.reference] = lastRollup;
    }
    if (jsonData?.[field.reference]?.constructor == Object && field.type !== FeatureField.type) {
      initialData[field.reference] = {...jsonData[field.reference]};
    }
  });

  reclassify(jsonData, fieldList);
  rebuildLabelsForForm(jsonData);
  evaluateRulesForForm(jsonData, fieldList);

  // this is what vue will see
  const fieldListStateObj = {
    hasValidationErrors: false,
    setFieldData: setFieldData,
    validateAll: validateAllFields,
    exposeErrors: exposeErrors,
    fieldStates: {},
  };

  return { 
    hasValidationErrors: hasValidationErrors,
    buildListState: () => {
      buildListState();
      return fieldListStateObj;
    },
    setIsDataRow: isRow => {
      isDataRow = !!isRow;
      buildListState();
    },
    validateAll: validateAllFields,
    exposeErrors: exposeErrors,
  };


  /* end of construction */


  // rebuilds the field states for vue
  function buildListState() {
    let states = {};
    fieldListRefs.forEach(ref => states[ref] = buildFieldState(ref));
    fieldListStateObj.fieldStates = states; // need to replace with a completed object, else vue isn't happy

    function buildFieldState(fieldRef) {
      let fieldState = {
        isRow: isDataRow,
        isVisible: isVisible(fieldRef, isDataRow),
        hadInteraction: !!touchedFields[fieldRef],
        validationErrors: validationErrors[fieldRef],
        showValidation: showValidation,
        
        initialValue: initialData[fieldRef],
        touchField: () => {
          touchedFields[fieldRef] = true;
          buildListState();
        },
        getClassification: classifierReference => classifications[classifierReference],
      }
      let labels = fieldLabels[fieldRef];
      if (labels) {
        fieldState.labels = labels;
      }

      let featureState = featureValues[fieldRef];
      if (featureState) {
        fieldState.currentViewId = featureState.getCurrentViewId();
        fieldState.isFormView = !featureState.isDataRows();
        fieldState.isDefaultExpanded = featureState.isDefaultExpanded();
        fieldState.setCurrentViewId = viewId => {
          featureState.setCurrentViewId(viewId);
          buildListState();
        };
        fieldState.expandAll = expand => {
          featureState.expandAll(expand);
          buildListState();
        }
        fieldState.hasInstanceValidationErrors = featureState.hasValidationErrors();
        fieldState.instanceStates = featureState.getInstances(() => buildListState());
      }

      let rollupState = rollupStates[fieldRef];
      if (rollupState != null) {
        fieldState.isRolledUp = rollupState;
        fieldState.toggleRollup = () => {
          rollupStates[fieldRef] = !rollupStates[fieldRef];
          buildListState();
        }
      }

      return fieldState;
    }
  }

  function setFieldData(fieldRef, newValue, fieldList, listJsonData) {
    let jsonData = _.cloneDeep(listJsonData);
    if (newValue) {
      jsonData[fieldRef] = newValue;
    } else {
      delete jsonData[fieldRef];
    }

    let field = fieldList.getField(fieldRef);
    if (field.type === FeatureField.type) {
      if (newValue?.values && newValue.values.length > 0) {
        let featureState = featureValues[fieldRef];
        if (featureState) {
          featureState.updateInstances(field, newValue, isDataRow);
        } else {
          featureValues[fieldRef] = FeatureValue(field, newValue, isDataRow);
        }
      } else {
        delete featureValues[fieldRef];
      }
    }

    reclassify(jsonData, fieldList);
    rebuildLabelsForForm(jsonData);
    recalculateFieldValues(fieldList, jsonData);

    if (fieldList.isControlField(fieldRef)) {
      evaluateRulesForForm(jsonData, fieldList);
      validateAll(fieldList, jsonData);
    } else {
      let fieldError = validateField(fieldRef, newValue, fieldList);
      if (!fieldError.isValid) {
        validationErrors[fieldRef] = fieldError.errors;
      } else {
        delete validationErrors[fieldRef];
      }
      fieldListStateObj.hasValidationErrors = hasValidationErrors();
    }
    buildListState();

    return jsonData;
  }

  function hasValidationErrors() {
    return validationErrors && Object.keys(validationErrors).length > 0
      || Object.values(featureValues).findIndex(val => val.hasValidationErrors()) >= 0;
  }

  function isVisible(fieldRef, isRow) {
    return isFieldInUse(fieldRef)
      && (isRow || isVisibleUnderRollup(fieldRef));
  }

  function isFieldInUse(fieldRef) {
    let rollup = rollupCoverage[fieldRef];
    if (rollup) {
      let state = inUseFieldStates[rollup];
      if (state === false) {
        return false;
      }
    }
    return inUseFieldStates[fieldRef];
  }

  function isVisibleUnderRollup(fieldRef) {
    let rollup = rollupCoverage[fieldRef];
    if (rollup != null) {
      let rollupState = rollupStates[rollup];
      if (rollupState != null) {
        return !rollupState;
      }
    }
    return true;
  }

  function validateField(fieldRef, jsonData, fieldList) {
    let fieldModel = fieldList.getField(fieldRef);
    let result = { isValid: true, errors: [] };
    if (isFieldInUse(fieldRef) && fieldModel?.validate) {
      result = fieldModel.validate(jsonData);
    }
    return result;
  }

  function validateAllFields (fieldList, jsonData) {
    validateAll(fieldList, jsonData);
    buildListState();
  }

  function validateAll(fieldList, jsonData) {
    let errors = {};
    fieldListRefs.forEach(ref => {
      let field = fieldList.getField(ref);
      if (isFieldInUse(field.reference)) {
        if (field.validate) {
          let data = jsonData?.[field.reference];
          let result = field.validate(data);
          if (!result.isValid) {
            errors[field.reference] = result.errors;
          }
        }
      }
    })
    validationErrors = errors;
    fieldListStateObj.hasValidationErrors = hasValidationErrors();
    
    Object.entries(featureValues).forEach(e => e[1].validateInstances(fieldList.getField(e[0]), jsonData[e[0]]));
  }

  function exposeErrors() {
    showValidation = true;
    Object.keys(validationErrors).forEach(ref => {
      let rollup = rollupCoverage[ref];
      if (rollup != null) {
        rollupStates[rollup] = false;
      }
    });
    Object.entries(featureValues).forEach(e => {
      if (e[1].hasValidationErrors()) {
        let rollup = rollupCoverage[e[0]];
        if (rollup != null) {
          rollupStates[rollup] = false;
        }
      }
    });
    Object.values(featureValues).forEach(val => val.exposeErrors());
    buildListState();
  }

  function recalculateFieldValues(fieldList, jsonData) {
    fieldListRefs.forEach(ref => {
      let field = fieldList.getField(ref);
      if (field.type === PhotoField.type) {
        jsonData[ref] = field.recalculateAnnotations(jsonData[ref], fieldLabels[ref], classifierReference => classifications[classifierReference]);
        let locatorText = field.getLocatorText(jsonData[ref]);
        let locatorRef = field.reference + '$locator_text';
        if (locatorText) {
          jsonData[locatorRef] = TextField.newFieldValue(locatorText);
        } else {
          delete jsonData[locatorRef];
        }
      }
    });
  }

  function rebuildLabelsForForm(data) {
    labelSpecs.forEach(s => {
      let value = labelTemplate.interpolatedText(s.template, data, s.allowEmptyFields);
      s.fields.forEach(f => {
        let labels = fieldLabels[f] ?? [];
        let label = labels.find(l => l.template === s.template);
        if (!label) {
          label = {
            template: s.template,
          }
          labels.push(label);
        }
        label.value = value;
        fieldLabels[f] = labels;
      });
    })
  }

  function reclassify(jsonData, fieldList) {
    classifications = fieldList.classify(jsonData) ?? {};
  }



  function evaluateRulesForForm(data, fieldList) {
    let fieldControlResults = {};
    fieldListRefs.forEach(ref => {
      let field = fieldList.getField(ref);
      inUseFieldStates[field.reference] = field.isUsedDefault;
      if (field.type === SelectionField.type && Array.isArray(field.fieldControlSpec)) {
        let selectedKeys = data[field.reference]?.['selections']?.map(e => e.key) ?? [];

        field.fieldControlSpec.forEach(thisRule => {
          let inUse = thisRule.behaviour.toLowerCase() === 'show';
          let isMatched = evaluateRule(thisRule, selectedKeys);
          let controlResult = { matched: isMatched, inUse: !isMatched != !!inUse }
  
          thisRule['field-references'].forEach(ref => {
            let fieldResultList = fieldControlResults[ref] ?? [];
            fieldResultList.push(controlResult);
            fieldControlResults[ref] = fieldResultList;
          });
        });
      }
    });

    Object.entries(fieldControlResults).forEach(e => {
      let matchedList = e[1].filter(r => r.matched);
      let control = matchedList.length > 0 ? matchedList[0] : e[1].pop();
      inUseFieldStates[e[0]] = control.inUse;
    });

    function evaluateRule (thisRule, selectedKeys) {
      switch (thisRule.test.toLowerCase()) {
        case 'and':
          return thisRule.keys.findIndex(k => !selectedKeys.includes(k)) < 0;
        case 'or':
          return selectedKeys.length === 0 && (thisRule.keys.length === 0 || thisRule.keys.includes(''))
            || thisRule.keys.findIndex(k => selectedKeys.includes(k)) >= 0;
      }
      return false;
    }
  }
}