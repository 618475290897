<template>
  <div>
    <div id="annotations-control-content">
      <annotation-view
        :background="annotationsBackground"
        :annotations="annotations"
        :currentAnnotation="currentAnnotation"
        :annotationDrawingOptions="annotationDrawingOptions"
        :currentClipWindow="window"
        :zoom="zoomLevel"
        :zoomExtension="10"
        :readOnly="readOnly"
        @annotationSelected="val => onAnnotationSelected(val)"
        @relativeViewportChanged="val => { relativeViewport = val; }"
        @zoom="val => changeZoom(val)"
        id="annotation-view"
      />
      <annotation-attributes
        :annotation="currentAnnotation"
        :balloonTextScale.sync="balloonTextScale"
        :zoom.sync="zoomLevel"
        :readOnly="readOnly"
        :locatorConfig="locatorConfig"
        :notifyBalloonChange="notifyBalloonChange"
        @newAnnotation="val => newAnnotation(val)"
        @destroyCurrent="destroyCurrentAnnotation"
        @locatorSelected="onLocatorSelected"
        @getBalloonValue="val => $emit('getBalloonValue', val)"
        @cropToView="val => cropToView(val)"
        id="annotation-attributes"
      />
    </div>
  </div>
</template>

<script>

import generateUUID from 'uuid/v4'
import annotation_view from './annotations_view'
import annotation_attributes, { ZoomSettings } from './annotation_attributes'
import { DrawingOptions } from "../../lib/annotations/annotation"
import { AnnotationDefaults } from "../../lib/annotations/annotation"
import { AnnotationTypes } from "../../lib/annotations/annotation"

var annotationType = AnnotationTypes();
var annotationDefault = AnnotationDefaults();

const zoomSettings = ZoomSettings;

export default {
  components: {
    annotationView: annotation_view,
    annotationAttributes: annotation_attributes,
  },
  props: {
    fieldModel: {
      type: Object,
      required: true,
    },
    assetId: {
      type: String,
      required: true,
    },
    annotations: Array,
    textScale: Number,
    window: Object,
    textLabels: Array,
    readOnly: Boolean,
    locatorClassification: Object,
    notifyBalloonChange: Object,
  },
  data: function() {
    let options = new DrawingOptions();
    return {
      annotationsBackground: null,
      currentAnnotation: null,
      balloonTextScale: this.textScale < 0 ? options.balloonOptions.textScale : this.textScale,
      annotationDrawingOptions: options,
      zoomLevel: zoomSettings.min,
      relativeViewport: null,
    }
  },
  watch: {
    assetId: {
      handler: function(new_id) {
        if (new_id) {
          this.loadBackgroundImage(new_id);
        }
      }, immediate: true,
    },
    balloonTextScale: function(val) {
      if (val) {
        this.annotationDrawingOptions.balloonOptions.textScale = val;
        if (!this.readOnly) {
          this.$emit('update:textScale', val);
        }
      }
    },
  },
  computed: {
    locator: function() {
      return this?.annotations?.find(a => a.annotation_data.locator);
    },
    locatorConfig: function() {
      return {
        allowed: !!this.fieldModel.locator,
        required: !!this.fieldModel.locator?.required,
        exists: !!this.locator,
      }
    },
  },
  methods: {
    loadBackgroundImage: function(assetId) {
      let vm = this;
      let newImage = new Image();
      newImage.onload = function() {
        vm.annotationsBackground = newImage
        vm.$nextTick(() => {
          vm.annotationsBackground = null;
        });
      },
      newImage.src = this.assetUrl(assetId);
    },
    onAnnotationSelected: function(annotation) {
      this.currentAnnotation = annotation;
    },
    assetUrl: function (assetId) {
      return '/o/' + this.organisationId() + '/assets/' + (assetId)
    },
    organisationId: function () {
      const organisationIdRegex = /^\/o\/(\d+)\//g;
      let matches = organisationIdRegex.exec(window.location.pathname)

      if (matches) {
        return matches[1]
      }
    },
    changeZoom: function (zoomin) {
      if (zoomin) this.zoomIn();
      else this.zoomOut();
    },
    zoomIn: function () {
      if (this.zoomLevel < zoomSettings.max) { this.zoomLevel += 1 };
    },
    zoomOut: function () {
      if (this.zoomLevel > zoomSettings.min) { this.zoomLevel -= 1 };
    },

    cropToView: function(crop) {
      if (this.readOnly) {
        return;
      }
      let window = crop && this.relativeViewport
        ? {
          tl_x: this.relativeViewport.left,
          tl_y: this.relativeViewport.top,
          scale_side: this.relativeViewport.right - this.relativeViewport.left,
        }
        : {
          tl_x: 0,
          tl_y: 0,
          scale_side: 1,
        };
        this.$emit("update:window", window);
    },
    onLocatorSelected: function() {
      if (this.locatorConfig.allowed) {
        if (!this.locatorConfig.exists) {
          this.newAnnotation('locator');
        } else {
          this.currentAnnotation = this.locator;
        }
      }
    },
    newAnnotation: function(type) {
      if (this.readOnly) {
        return;
      }
      let centerX = 0.5;
      let centerY = 0.5;
      let diff = 0.1;
      if (this.relativeViewport) {
        centerX = (this.relativeViewport.right - this.relativeViewport.left) / 2 + this.relativeViewport.left;
        centerY = (this.relativeViewport.bottom - this.relativeViewport.top) / 2 + this.relativeViewport.top;
        diff = (this.relativeViewport.bottom - this.relativeViewport.top) * 0.1;
      }
      let yOffset = diff;
      let origin = {x: centerX.toFixed(8), y: (centerY - yOffset).toFixed(8)};
      let end = {x:centerX.toFixed(8), y: (centerY + yOffset).toFixed(8)};

      let annotation = null;
      if (type != annotationType.balloon && type != 'locator') {
        let strokeColor = annotationDefault.shapeStrokeColor;
        let strokeOpacity = annotationDefault.shapeStrokeOpacity;
        let scale = annotationDefault.shapeStrokeScale;
        let fillColor = annotationDefault.shapeStrokeColor;
        let fillOpacity = annotationDefault.shapeFillOpacity;
        if (this.currentAnnotation && this.currentAnnotation.annotation_data) {
          let data = this.currentAnnotation.annotation_data;
          strokeColor = data.stroke_color ? data.stroke_color : strokeColor;
          strokeOpacity = Math.round(data.stroke_opacity ? data.stroke_opacity : strokeOpacity);
          scale = data.stroke_scale ? data.stroke_scale : scale;
          fillColor = data.fill_color ? data.fill_color : fillColor;
          fillOpacity = Math.round(data.fill_opacity ? data.fill_opacity : fillOpacity);
        }
        if (type == annotationType.rectangle) {
          let aspectRatio = 1;
          if (this.relativeViewport) {
            aspectRatio = 1 / this.relativeViewport.aspectRatio;
          }
          let xOffset = yOffset;
          origin.x = (origin.x - xOffset).toFixed(8);
          end.x = (end.x - xOffset).toFixed(8);
          
          annotation = {
            annotation_type: type,
            uuid: generateUUID(),
            annotation_data: {
              origin: origin,
              end_point: end,
              aspect_ratio: aspectRatio,
              stroke_color: strokeColor,
              stroke_opacity: strokeOpacity,
              stroke_scale: scale,
              fill_color: fillColor,
              fill_opacity: fillOpacity,
            }
          }
        } else if (type != annotationType.freeStyle) {
          annotation = {
            annotation_type: type,
            uuid: generateUUID(),
            annotation_data: {
              origin: origin,
              end_point: end,
              stroke_color: strokeColor,
              stroke_opacity: strokeOpacity,
              stroke_scale: scale,
              fill_color: fillColor,
              fill_opacity: fillOpacity,
            }
          }
        } else {
          // sort freestyle later
        }
      } else {
        if (type === 'locator' && this.fieldModel.locator) {
          let templateText = this.fieldModel.locator.template_text;
          let balloonText = this.textLabels.find(l => l.template === templateText)?.value ?? '';
          let textColor = this.locatorClassification?.text_color ?? annotationDefault.balloonTextColor;
          let fillColor = this.locatorClassification?.fill_color ?? annotationDefault.balloonFillColor;
          annotation = {
            annotation_type: annotationType.balloon,
            uuid: generateUUID(),
            linked_feature: '',  // the app relies on this being present... so we'll make sure it is
            annotation_data: {
              origin: end, // swapped over so the balloon points down
              end_point: origin,
              text_color: textColor,
              fill_color: fillColor,
              text: balloonText,
              full_text: balloonText,
              template_text: templateText,
              locator: true
            }
          }
        }
        if (!annotation) {
          let balloonText = window.prompt('Enter the text you want shown on the balloon.');
          if (balloonText) {
            let textColor = annotationDefault.balloonTextColor;
            let fillColor = annotationDefault.balloonFillColor;
            if (this.currentAnnotation?.annotation_data) {
              let data = this.currentAnnotation.annotation_data;
              textColor = data.text_color ? data.text_color : textColor;
              // TODO: remove this check if copying from other shapes permissable
              if (this.currentAnnotation.annotation_type == annotationType.balloon) {
                fillColor = data.fill_color ? data.fill_color : fillColor;
              }
            }
            annotation = {
              annotation_type: annotationType.balloon,
              uuid: generateUUID(),
              linked_feature: '',  // the app relies on this being present... so we'll make sure it is
              annotation_data: {
                origin: end, // swapped over so the balloon points down
                end_point: origin,
                text_color: textColor,
                fill_color: fillColor,
                text: balloonText,
                full_text: balloonText
              }
            }
          }
        }
      }

      if (annotation) {
        // TODO: find correct insertion point and add it to the collection - for now we'll just add it to the end
        let newAnnotations = [...this.annotations ?? [], annotation];
        this.currentAnnotation = annotation;
        this.$emit('update:annotations', newAnnotations);
        if (this.textScale < 0 && annotation.annotation_type === annotationType.balloon) {
          this.$emit('update:textScale', this.balloonTextScale);
        }
      }
    },
    destroyCurrentAnnotation: function() {
      let newAnnotations = [...this.annotations ?? []];
      let index = newAnnotations.findIndex(a => a === this.currentAnnotation);
      if (index >= 0) {
        this.currentAnnotation = null;
        newAnnotations.splice(index, 1);
        this.$emit('update:annotations', newAnnotations);
        if (newAnnotations.length === 0) {
          this.balloonTextScale = undefined;
        }
      }
    },
  }
}
</script>

<style scoped>
  #annotations-control-content {
    display: flex;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
  }
  #annotation-view {
    display: flex;
    max-width: 100%;
    max-height: 100%;
  }
  #annotation-attributes {
    width: 240px;
    flex: 0 0 auto;
    padding: 20px;
  }
</style>
