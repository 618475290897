<template>
  <div>
    <div :class="['collapsible', {'transitioning': transitioning}, {'indented': useIndent}, expanded ? 'expanded' : 'collapsed']" @transitionend="transitionend">
      <div v-for="option in options" :key="option.reference" class="classifications-selector" v-show="!hideEmptyItems || option.options?.length > 0">
        {{option.label}}
        <v-select 
          :options="classificationSelectorOptions(option)"
          :value="classificationSelectorValues(option)"
          :getOptionKey="val => val.reference"
          :multiple="true"
          :searchable="true"
          :closeOnSelect="true"
          @input="val => classificationSelectionsChanged(option.reference, val)">
          <span slot="no-options"></span>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
  import vSelect from 'vue-select'
  import _ from 'lodash'

  export default {
    components: {
      vSelect,
    },
    props: {
      options: Array,
      selections: Object,
      expanded: Boolean,
      hideEmptyItems: Boolean,
      useIndent: Boolean,
    },
    data: function () {
      return {
        transitioning: false,
      };
    },
    watch: {
      expanded: function() {
        this.setExpanded();
      },
    },
    methods: {
      classificationSelectorOptions: function(option) {
        let selectedKeys = this.selectedKeys(option.reference);
        return option.options.filter(o => !selectedKeys.includes(o.reference));
      },
      classificationSelectorValues: function(option) {
        let selectedKeys = this.selectedKeys(option.reference);
        return option.options.filter(o => selectedKeys.includes(o.reference));
      },
      selectedKeys: function (reference) {
        let selected = this.selections;
        if (selected?.hasOwnProperty(reference)) {
          return selected[reference];
        }
        return [];
      },
      classificationSelectionsChanged: function(classifier, values) {
        let selections = _.cloneDeep(this.selections) ?? {};
        if (values.length != 0) {
          selections[classifier] = values.map(v => v.reference);
        } else {
          delete selections[classifier];
        }
        this.$emit('update:selections', selections);
      },
      setExpanded: function() {
        this.transitioning = true;
        let target = this.$el.getElementsByClassName('collapsible')[0];
        target.style.height=target.scrollHeight+'px';
        if (!this.expanded) {
          setTimeout(()=> {target.style.height='';}, 1); // timeout needed to start the collapse transition
        }
      },
      transitionend: function(evt) {
        // compare targets to avoid events which may have bubbled up
        if (evt.currentTarget === evt.target) {
          this.transitioning = false;
          // remove the height setting to allow browser to dynamically update it for resize etc
          evt.target.style.height='';
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .collapsible {
    height: 0;
    transition: all 0.5s ease-in-out;
  }
  .expanded {
    height: auto;
  }
  .collapsed, .transitioning {
    overflow-y: hidden;
  }
  .classifications-selector {
    margin-bottom: 10px;
  }
  .collapsible.indented {
    padding-left: 10px;
  }
  .collapsible.indented.expanded {
    border-left: 1px solid #00719cbb;
  }
  .collapsible.indented.collapsed, .collapsible.indented.transitioning {
    border-left: 1px solid transparent;
  }
</style>
<style>
/* vue-select unscoped */
  .classifications-selector .vs__dropdown-toggle {
    padding: 0;
    min-height: 32px;
  }
  .classifications-selector .vs__selected {
    margin: 3px 2px;
  }
  .classifications-selector .vs__selected-options input {
    margin: 0;
    padding: 0;
    height: auto;
    line-height: auto;
    border-width: 0;
    background-color: inherit;
  }
  .classifications-selector .vs__selected-options input:focus {
    box-shadow: none; /* hides the focus shadow */
  }
</style>