<template>
  <div class="inspection-section">
    <component
      :is="componentFor(field)"
      :specification="field"
      :data="data[field.reference]"
      :fieldModel="fieldModelFor(field)"
      :fieldState="fieldStateFor(field)"
      :notifyFieldData="notifyFieldData[field.reference]"
      :solution-type="solutionType"
      @registerLinks="val => registerLinks(val)"
      @update:data="val => fieldUpdate(field, val)"
      @clear:data="() => fieldRemoval(field)"
      :read-only="readOnly"
      :drawing-sets="drawingSets"
      :prefill-data="prefillData[field.reference]"
      @notifyFieldEvent="val => onNotifyFieldEvent(val)"
      v-for="field in fields" :key="field.reference">
    </component>
  </div>
</template>

<script>
  import _ from 'lodash'
  import labelTemplateMixin from '../mixins/label_template_mixin'
  import DateField from './fields/date.vue'
  import DecimalField from './fields/decimal.vue'
  import FeatureField from './fields/feature.vue'
  import GuidanceField from './fields/guidance.vue'
  import IntegerField from './fields/integer.vue'
  import LocationField from './fields/location.vue'
  import SelectionField from './fields/selection.vue'
  import TextField from './fields/text.vue'
  import TimeField from './fields/time.vue'
  import PhotoField from './fields/photo.vue'

  export default {
    components: {
      DateField,
      DecimalField,
      FeatureField,
      GuidanceField,
      IntegerField,
      LocationField,
      SelectionField,
      TextField,
      TimeField,
      PhotoField
    },
    mixins: [labelTemplateMixin],
    props: {
      fields: Array,
      fieldListState: Object,
      solutionType: String,
      data: Object,
      fieldListModel: Object,
      readOnly: Boolean,
      drawingSets: Array,
      notifyFieldEvent: Object,
    },
    data: function () {
      let initialPrefillDataList = this.prefillDataList(this.data);
      let initialFieldsToWatch = this.getFieldsToWatch();
      let initialNotify = _.reduce(this.fields, (o, f) => {
        o[f.reference] = {};
        return o;
        }, {});

      return {
        fieldsToWatch: initialFieldsToWatch,
        prefillData: initialPrefillDataList,
        registeredLinks: [],
        notifyFieldData: initialNotify,
      }
    },
    watch: {
      notifyFieldEvent: function(val) {
        this.onNotifyFieldEvent(val);
      },
    },
    methods: {
      registerLinks: function(reg) {
        let newReg = _.map(reg.links, (l) => { return {ownerReference: reg.owner, targetReference: l}; })
        this.registeredLinks = this.registeredLinks.concat(newReg);
      },
      onNotifyFieldEvent: function(val) {
        if (!val.destination) {
          _.forEach(this.registeredLinks, (l) => {
            if (l.targetReference == val.source) {
              val.destination = l.ownerReference;
              this.notifyFieldData[l.ownerReference] = val;
            }
          });
        }
        if (!!val.destination) {
          // we work with relative paths only
          // we're making the assumption that there are no deep paths
          // TODO: adapt to support nested features
          // TODO: adapt to support parent paths
          // TODO: adapt to support absolute paths (eg if path starts with a '/')
          let parts = val.destination.split(':');
          if (parts.length == 2) {
            val.destination = parts[1];
            this.notifyFieldData[parts[0]] = val;
          } else {
            this.notifyFieldData[val.destination] = val;
          }
        }
      },
      fieldsByReference: function () {
        return _.keyBy(this.fields, 'reference');
      },
      getFieldsToWatch: function() {
        let vm = this;
        // check all fields and return references of those that need prefills
        let result = [];
        _.forEach(vm.fields, function (thisField) {
          if (!_.isEmpty(thisField['prefill'])) { result.push(thisField['prefill'][0]['form']); }
        })
        return result;
      },
      listOfPrefills: function () {
        let vm = this;
        // check all fields and return references of those that need prefills
        let result = {};
        let prefill = {};
        _.forEach(vm.fields, function (thisField) {
          if (!_.isEmpty(thisField['prefill'])) {
            // There is a prefill request
            prefill = thisField['prefill'][0];
            result[thisField.reference] = { "source": prefill.form, "fields": prefill.fields };
          }
        })
        return result;
      },
      featuresListLabel: function(fieldReference) {
        return this.fieldsByReference()[fieldReference]['list_label']
      },
      prefillDataList: function(dataSource) {
        // Go through the preFillRequests and generate the lists for them
        // Each list is added to the prefillData object
        let result = {};
        let vm = this; 
        let returnedList = [];
        _.forEach(vm.listOfPrefills(), function (thisPrefillRequest, thisTargetField) {
          returnedList = vm.featureDataList(dataSource, thisPrefillRequest.source, thisPrefillRequest.fields);
          if (!_.isEmpty(returnedList)) {
            result[thisTargetField] = returnedList;
          }
        });
        return result
      },
      featureDataList: function(dataSource, fieldReference, fieldsList) {
        // For a single request, returns the list with the data
        let result = {};
        let vm = this; 
        if (vm.canUseFieldSource(dataSource, fieldReference)) {
          let level1 = vm.featuresListLabel(fieldReference)[0];
          let level1Processed = '';
          let level2Processed = '';

          let testSubst = new RegExp(/%{([\s\S]+?)}/,'g');
          if (!testSubst.test(level1)) {
            // is a static string
            result[level1] = [];
          } else {
            // is a subst string - add each level
            _.forEach(dataSource[fieldReference]['values'], function(instance){
              level1Processed = vm.interpolatedText(level1, instance.data);
              if (!_.isEmpty(_.trim(level1Processed))) {
                result[level1Processed] = [];
              }
            })
          }
          let level2 = vm.featuresListLabel(fieldReference)[1];
          _.forEach(dataSource[fieldReference]['values'], function(instance){
            level1Processed = vm.interpolatedText(level1, instance.data);
            level2Processed = vm.interpolatedText(level2, instance.data);
            if (!_.isEmpty(_.trim(level1Processed)) && !_.isEmpty(_.trim(level2Processed))) {
              result[level1Processed].push(
                { 
                  "value": level2Processed,
                  "data": vm.pullFieldsFromData(instance.data, fieldsList)
                }
              );
            }
          })
        }
        return result
      },
      canUseFieldSource: function (dataSource, fieldReference) {
        let vm = this;
        return !_.isEmpty(vm.featuresListLabel(fieldReference)) 
            && !_.isEmpty(dataSource)
            && !_.isEmpty(dataSource[fieldReference]) 
            && !_.isEmpty(dataSource[fieldReference]['values']) 
            && !_.isEmpty(dataSource[fieldReference]['values'][0]['data']);
      },
      pullFieldsFromData: function (fieldData, fieldsList) {
        // for the data given, only pull out those items from the FieldsList
        let vm = this; 
        let result = {}; 
        _.forEach(fieldsList, function (fieldName) {
          if (!_.isUndefined(fieldData[fieldName])) {
            let source = fieldData[fieldName];
            switch (fieldData[fieldName].type) {
              case "selection":
                result[fieldName] = {};
                result[fieldName]['type'] = 'text';
                result[fieldName]['value'] = source.selections.map(_.property('value')).join(', ');
                break;
              case "integer":
              case "decimal":
                result[fieldName] = {};
                result[fieldName]['type'] = 'text';
                result[fieldName]['value'] = _.toString(source['value'])
                break;
              case "date":
                result[fieldName] = {};
                result[fieldName]['type'] = 'text';
                result[fieldName]['value'] = _.isNil(source['year']) ? "" : _.join([_.padStart(source.day, 2, '0'), _.padStart(source.month, 2, '0'), source.year],"-");
                break;
              default: 
                result[fieldName] = _.cloneDeep(source);
                break;
            }
          }
        })
        return result;
      },
      componentFor: function (field) {
        return field.type + "-field";
      },
      fieldModelFor: function (field) {
        return this.fieldListModel.getField(field.reference);
      },
      fieldStateFor: function (field) {
        return this.fieldListState.fieldStates[field.reference];
      },
      fieldUpdate: function (field, value) {
        let data = this.fieldListState.setFieldData(field.reference, value, this.fieldListModel, this.data);

        if (_.includes(this.fieldsToWatch, field.reference)) {
          this.prefillData = this.prefillDataList(data);
        }
        this.$emit('update:data', data)
      },
      fieldRemoval: function (field) {
        let data = this.fieldListState.setFieldData(field.reference, null, this.fieldListModel, this.data);
        this.$emit('update:data', data)
      },
    }
  }
</script>

<style scoped lang="scss">
</style>