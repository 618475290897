<template>
  <div>
    <div v-for="group in classificationSelectorGroups" :key="group.reference">
      <classifications-selector-group-header
        v-show="useHeaders && (!hideEmptyItems || Object.keys(group.classifiers).length > 0)"
        :label="group.label"
        :selected="selectedGroups.includes(group.reference)"
        :isSelectable="selectableGroups"
        :useCollapsible="useCollapsibles && Object.keys(group.classifiers).length > 0 && (!selectableGroups || selectedGroups.indexOf(group.reference) >= 0)"
        :collapsibleOnLeft="!selectableGroups"
        :expanded="expandedGroups.includes(group.reference) && Object.keys(group.classifiers).length > 0"
        @update:selected="val => updateGroupInUse(group.reference, val)"
        @update:expanded="val => updateGroupExpanded(group.reference, val)"
      />
      <classifications-selector-group
        :options="group.classifiers"
        :selections="selectedOptions[group.reference] ?? {}"
        :expanded="expandedGroups.includes(group.reference)"
        :useHeader="useHeaders"
        :hideEmptyItems="hideEmptyItems"
        :useIndent="true"
        @update:selections="val => updateSelectedClassifications(group.reference, val)"
      />
    </div>
  </div>
</template>

<script>
  import classificationsSelectorGroupHeader from './classifications_selector_group_header.vue'
  import classificationsSelectorGroup from './classifications_selector_group.vue'
  import _ from 'lodash'

  export const FALLBACK_ID = "__fallback"

  export default {
    components: {
      classificationsSelectorGroupHeader,
      classificationsSelectorGroup,
    },
    props: {
      // these four are used only to calculate classificationSelectorGroups
      classificationGroups: Object,
      categories: Object,
      classifiersBySolution: Object,
      __fallbackId: String,

      selectedGroups: {
        type: Array, 
        default: v => v ?? []},
      selectedOptions: Object,
      expandedGroups: {
        type: Array, 
        default: v => v ?? []},
      useHeaders: Boolean,
      selectableGroups: Boolean,
      useCollapsibles: Boolean,
      openOnUse: Boolean,
      hideEmptyItems: Boolean,
    },
    computed: {
      classificationSelectorGroups: function () {
        if (this.classificationGroups && this.classifiersBySolution && this.categories) {
          return makeSelectorGroups(this.classificationGroups, this.classifiersBySolution, this.categories, this.__fallbackId);
        }
        return [];
      },
    },
    methods: {
      updateGroupInUse: function(groupReference, inUse) {
        let selectedGroups = _.cloneDeep(this.selectedGroups);
        let index = selectedGroups.indexOf(groupReference);
        if (inUse && index < 0) {
          selectedGroups.push(groupReference);
        } else if (!inUse && index >= 0) {
          selectedGroups.splice(index, 1);
        }
        this.$emit('update:selectedGroups', selectedGroups)
        if (this.useCollapsibles) {
          this.$nextTick(() => {
            this.updateGroupExpanded(groupReference, inUse && this.openOnUse);
          });
        }
      },
      updateGroupExpanded: function(groupReference, expanded) {
        let expandedGroups = [...this.expandedGroups];
        let index = expandedGroups.indexOf(groupReference);
        if (expanded && index < 0) {
          expandedGroups.push(groupReference);
        } else if (!expanded && index >= 0) {
          expandedGroups.splice(index, 1);
        }
        this.$emit('update:expandedGroups', expandedGroups)
      },
      updateSelectedClassifications: function(groupReference, selections) {
        let selectedOptions = _.cloneDeep(this.selectedOptions);
        if (Object.keys(selections).length != 0) {
          selectedOptions[groupReference] = selections;
        } else {
          delete selectedOptions[groupReference];
        }
        this.$emit('update:selectedOptions', selectedOptions);
      },
    }
  }


  function makeSelectorGroups(classificationGroups, classifiersBySolution, categories, __fallbackId) {
    let fallbackId = __fallbackId ?? FALLBACK_ID;
    let result = Object.entries(classificationGroups).map(e => ({reference: e[0], label: e[1], classifiers: makeClassifications(e[0])}));
    return result;

    function makeClassifications(groupReference) {
      let classifiers = classifiersBySolution[groupReference];
      if (!classifiers) return [];
      let result = classifiers.map(classifier => {
        let category = categories[classifier.category];
        if (!category?.values || classifier.hasOwnProperty('filterable') && !classifier.filterable || classifier.reference === 'availability') {
          return;
        }
        let sortBy = !category.hasOwnProperty('sort_by_label') || category.sort_by_label ? 'label' : 'order';
        let sortedOptions = _.sortBy(Object.entries(category.values), e => e[1][sortBy])
            .map(e => ({reference: e[0], label: e[1].label}));

        if (!classifier.exhaustive) {
          sortedOptions.push({reference: fallbackId, label: category.fallback?.label ?? 'Unknown'});
        }
        return {reference: classifier.reference, label: classifier.label, options: sortedOptions};
      });
      return result.filter(c => c);
    }
  }
</script>