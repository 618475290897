<template>
  <canvas v-show="!offScreen" ref="annotationCanvas"/>
</template>

<script>
import { DrawingOptions } from "../../lib/annotations/annotation"
import { LayoutParams } from "../../lib/annotations/annotation"
import { AnnotationTypes } from "../../lib/annotations/annotation"

const annotationType = AnnotationTypes();

export default {
  props: {
    shapes: Array,
    selectedAnnotationIds: Array,
    annotationDrawingOptions: DrawingOptions,
    balloonColorScheme: Object,
    width: Number,
    height: Number,
    offset: Object,
    overallSize: Object,
    background: Image,
    readOnly: Boolean,
    getImageData: Boolean,
    offScreen: Boolean,
    redraw: Boolean,
  },
  data: function () {
    return {
      canvas: null,
      context: null,
      layoutParams: new LayoutParams(),
    }
  },
  watch: {
    width: function() {
      this.resize();
    },
    height: function() {
      this.resize();
    },
    offset: function(val) {
      this.layoutParams.setViewOffset(val.x, val.y);
      this.redrawCanvas();
    },
    background: function(val) {
      this.redrawCanvas();
    },
    overallSize: function(val) {
      this.layoutParams.setOverallSize(val.width, val.height);
      this.redrawCanvas();
    },
    readOnly: function() {
      this.redrawCanvas();
    },
    redraw: function(val) {
      if (val) {
        this.redrawCanvas();
      }
    },
    getImageData: function(val) {
      if (val) {
        const vm = this;
        vm.redrawCanvas();
        vm.canvas.toBlob((blob) => {
          vm.$emit("saved_image_blob", blob);
        });
      }
    },

    shapes: function() {
      this.redrawCanvas();
    },
    selectedAnnotationIds: function(val, oldVal) {
      if (!_.isEqual(val, oldVal)) {
        this.redrawCanvas();
      }
    },
    annotationDrawingOptions: {
      handler() {
        this.redrawCanvas();
      },
      deep: true
    },
    balloonColorScheme: function() {
      this.redrawCanvas();
    },
  },
  computed: {
    localSelectedAnnotationIds: function () {
      return this.selectedAnnotationIds ?? [];
    },
  },
  mounted() {
    this.canvas = this.$refs.annotationCanvas;
    this.context = this.canvas.getContext("2d");
  },
  methods: {
    resize: function() {
      let width = this.width;
      let height = this.height;
      if (this.canvas.width != width || this.canvas.height != height) {
        this.canvas.width = width;
        this.canvas.height = height;
        this.redrawCanvas();
      }
    },
    redrawCanvas: function() {
      if (!this.width || !this.height || !this.overallSize.width || !this.overallSize.height) {
        return;
      }
      this.context.clearRect(0,0, this.canvas.width, this.canvas.height);
      this.context.globalAlpha = 1;
      if (this.background) {
        this.context.imageSmoothingEnabled = true;
        this.context.imageSmoothingQuality = "high";
        let scale = this.background.naturalWidth / this.overallSize.width;
        let sWidth = this.background.naturalWidth * (this.width / this.overallSize.width);
        let sHeight =  this.background.naturalHeight * (this.height / this.overallSize.height);
        this.context.drawImage(this.background, this.offset.x * scale, this.offset.y * scale, sWidth, sHeight, 0, 0, this.width, this.height);
      }

      let unselectedShapes = [];
      let selectedShapes = [];
      let last = this.shapes.length;
      for (let index = 0; index < last; index++) {
        if (this.localSelectedAnnotationIds.indexOf(this.shapes[index].uuid) < 0) {
          unselectedShapes.push(this.shapes[index]);
        } else {
          selectedShapes.push(this.shapes[index]);
        }
      }
      redrawShapes(unselectedShapes, false, this.context, this.layoutParams, this.annotationDrawingOptions, this.balloonColorScheme);
      redrawShapes(selectedShapes, true, this.context, this.layoutParams, this.annotationDrawingOptions, this.balloonColorScheme);
      this.$emit('finished_drawing');

      function redrawShapes(shapes, isSelected, context, layoutParams, drawingOptions, balloonColorScheme) {
        let last = shapes.length;
        for (let index = 0; index < last; index++) {
          let shape = shapes[index];
          if (balloonColorScheme?.scheme && (shape.type === annotationType.balloon || shape.type === annotationType.mergeBalloon)) {
            let colorsId = shape.classifications?.[balloonColorScheme.id];
            let scheme = balloonColorScheme.scheme.values?.[colorsId] ?? balloonColorScheme.scheme.fallback;
            let recoloredDrawingOptions = _.cloneDeep(drawingOptions);
            recoloredDrawingOptions.balloonOptions.fillColorOverride = scheme?.fill_color;
            recoloredDrawingOptions.balloonOptions.textColorOverride = scheme?.text_color;
            recoloredDrawingOptions.balloonOptions.drawBorder = !scheme || !!balloonColorScheme.scheme?.values; // no border for default colors
            shape.draw(context, layoutParams, recoloredDrawingOptions, isSelected);
          } else {
            shape.draw(context, layoutParams, drawingOptions, isSelected);
          }
        }
      }
    },
  }
}
</script>
