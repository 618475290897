<template>
  <div class="selection-field control-group" v-if="isRow">
    <div class="controls" :title="label" v-show="isVisible">
      <p v-if="isReadOnly || noSelectableValue"  class="inspection-table-field">&nbsp;{{ stringValue }} </p>
      <option-selector v-else
        :class="['option-selector', 'option-selector-cell', 'inspection-table-field', {'field-error': shouldShowValidation}]"
        :options="options"
        :selectedKeys.sync="selections"
        :multiple="fieldModel.isMultiple"
        :searchable="true"
        :clearable="true"
        :placeholder="fieldModel.fieldPrompt"
        />
    </div>
    <div class="controls" :title="label" v-show="!isVisible">
      &nbsp;
    </div>
  </div>
  <div class="selection-field control-group" v-show="isVisible && !noSelectableValue" v-else>
    <label v-bind:for="formId" class="control-label">{{ label }}<span v-if="fieldModel.isRequired">*</span></label>
    <div class="controls">
      <p class="help-block" v-if="hasHelpText">{{ helpText }}</p>
      <p class="criteria-block" v-if="hasParseErrors || shouldShowValidation">
        <span v-if="hasParseErrors">{{ parseErrorText }}</span>
        <span v-if="shouldShowValidation">{{ validationErrorText }}</span>
      </p>
      <p v-if="isReadOnly">&nbsp;{{ stringValue }} </p>
      <option-selector v-else
        class="option-selector option-selector-field"
        :options="options"
        :selectedKeys.sync="selections"
        :multiple="fieldModel.isMultiple"
        :searchable="true"
        :clearable="true"
        :placeholder="fieldModel.fieldPrompt"
        />
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import optionSelector from '../utils/option_selector.vue'
  import fieldMixin from '../../mixins/field_mixin'
  import fieldLabelMixin from '../../mixins/field_label_mixin'
  import fieldValidationMixin from '../../mixins/field_validation_mixin'

  export default {
    components: {
      optionSelector,
    },
    mixins: [fieldMixin, fieldLabelMixin, fieldValidationMixin],
    data: function () {
      return {
        initialDataSelections: this.fieldState.initialValue?.selections ?? [],
        selections: this.selectionsFromData(this.data),
        parseErrors: [],
      }
    },
    watch: {
      data: function (data) {
        let newSelections = this.selectionsFromData(data);
        if (!_.isEqual(this.selections, newSelections) && !this.hasParseErrors) {
          this.selections = newSelections;
        }
      },
      selections: function (newSelections) {
        this.setSelection(newSelections);
      },
    },
    computed: {
      noSelectableValue: function () {
        return this.options.length === 0;
      },
      options: function () {
        let options = this.fieldModel.getOptionsForFieldInstance(this.initialDataSelections);
        return options;
      },
      stringValue: function () {
        let vm = this;
        if (_.isNil(vm.data)) {
          return "";
        } else {
          return _.chain(_.castArray(vm.data.selections)).map(_.property('value')).join(', ')
        }
      },
      hasParseErrors: function() {
        return this.parseErrors.length > 0;
      },
      parseErrorText: function() {
        return this.hasParseErrors ? this.parseErrors[0] + '.' : '';
      },
    },
    methods: {
      selectionsFromData: function(data) {
        if (data) {
          let newSelections = data.selections.map(o => o.key);
          return this.fieldModel.isMultiple ? newSelections 
            : newSelections.length > 0 ? [newSelections[0]] 
            : [];
        }
        return [];
      },
      setSelection: function (newSelections) {
        let result = this.fieldModel.parse(newSelections, this.initialDataSelections);
        this.parseErrors = result.messages;
        this.$emit('update:data', result.data);
        if (!_.isEqual(this.data, result.data)) {
          this.fieldState.touchField();
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .inspection-table-field {
    width: 95%;
    padding-right: 0px;
    margin-bottom: 0px;
  }
  .control-group {
    margin-bottom: 0px;  
  }
  .option-selector {
    --os-field-color-bg: white; /* sets in option selector */
  }
  .option-selector-field {
    margin-bottom: 10px;
    width: 460px;
  }
  .option-selector-cell {
    margin: 0 1px;
  }
  .inspection-table-field.field-error {
    --os-field-border-color: red; /* sets in option selector */
  }

</style>