<template>
  <div class="decimal-field control-group" v-if="isRow">
    <div class="controls" :title="label" v-show="isVisible">
      <input v-bind:name="formId" type="number" step="any" v-model="value" :disabled="isReadOnly" v-bind:class="[!shouldShowValidation && !hasParseErrors ? '' : 'field-error', 'inspection-table-field']" v-on:keydown.enter.prevent="">
    </div>
    <div class="controls" :title="label" v-show="!isVisible">
      &nbsp;
    </div>      
  </div>
  <div class="decimal-field control-group" v-show="isVisible" v-else>
    <label v-bind:for="formId" class="control-label">{{ label }}<span v-if="fieldModel.isRequired">*</span></label>
    <div class="controls">
      <p class="help-block" v-if="hasHelpText">{{ helpText }}</p>
      <p class="criteria-block" v-if="hasParseErrors || shouldShowValidation">
        <span v-if="hasParseErrors">{{ parseErrorText }}</span>
        <span v-if="shouldShowValidation">{{ validationErrorText }}</span>
      </p>
      <input v-bind:name="formId" type="number" step="any" v-model="value" :disabled="isReadOnly" v-on:keydown.enter.prevent="">
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import fieldMixin from '../../mixins/field_mixin'
  import fieldLabelMixin from '../../mixins/field_label_mixin'
  import fieldValidationMixin from '../../mixins/field_validation_mixin'

  export default {
    mixins: [fieldMixin, fieldLabelMixin, fieldValidationMixin],
    data: function () {
      return {
        value: this.valueFromData(this.data),
        parseErrors: [],
      }
    },
    watch: {
      data: function (data) {
        let newValue = this.valueFromData(data);
        if (!_.isEqual(this.value, newValue) && !this.hasParseErrors) {
          this.value = newValue;
        }
      },
      value: _.debounce(function (newValue) {
          this.setNewValue(newValue)
        },150)
    },
    computed: {
      hasParseErrors: function() {
        return this.parseErrors.length > 0;
      },
      parseErrorText: function() {
        return this.hasParseErrors ? this.parseErrors[0] + '.' : '';
      },
    },
    methods: {
      checkBothEmpty: function (initial, current) {
        return ((_.isNull(initial) || initial === '' || _.isNaN(initial)) && (_.isNull(current) || current === '' || _.isNaN(current)))
      },
      valueFromData: function(data) {
        return data?.value ?? null;
      },
      setNewValue: function (newValue) {
        let result = this.fieldModel.parse(newValue);
        this.parseErrors = result.messages;
        this.$emit('update:data', result.data);
        if (!_.isEqual(this.data, result.data)) {
          this.fieldState.touchField();
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .inspection-table-field {
    width: 95%;
    padding-right: 0px;
    margin-bottom: 0px;
  }
  .control-group {
    margin-bottom: 0px;  
  }
</style>