<template>
  <div>
    <div ref="annotationViewContainer">
      <div id="annotation-view-content" ref="annotationViewContent">
        <img class="display-layer" ref="image" v-bind:width="imageWidth" v-bind:height="imageHeight"/>
        <annotation-canvas
          :width="imageWidth"
          :height="imageHeight"
          :annotations="annotations"
          :currentAnnotation="currentAnnotation"
          :annotationDrawingOptions="annotationDrawingOptions"
          :selectPointsWhenCurrentAnnotationChanges="true"
          :currentClipWindow="currentClipWindow"
          :readOnly="readOnly"
          @annotationSelected="val => $emit('annotationSelected', val)"
          class="display-layer"
        />
      </div>
    </div>
    <div ref="scalerContainer" class="scaler">
      <img ref="scaler"/>
    </div>
  </div>
</template>

<script>
import annotation_canvas from "./annotation_canvas.vue"
import { DrawingOptions } from "../../lib/annotations/annotation"
import { ZoomSettings } from './annotation_attributes'

const zoomSettings = ZoomSettings;
const SCROLL_BAR_WIDTH = 17; // TODO: make this robust against styling changes and browser zoom

export default {
  components: {
    annotationCanvas: annotation_canvas,
  },
  props: {
    annotations: Array,
    currentAnnotation: Object,
    annotationDrawingOptions: DrawingOptions,
    currentClipWindow: Object,
    background: Image,
    readOnly: Boolean,
    zoom: Number,
    zoomExtension: Number,
  },
  data: function () {
    return {
      annotationViewContainer: null,
      annotationViewContent: null,
      scalerContainer: null,
      zoomFactor: 1,
      relativeScroll_x: 0.5,
      relativeScroll_y: 0.5,
      viewZoomFocusX: -1,
      viewZoomFocusY: -1,
      aspectRatio: 0,
      imageWidth: 600,
    }
  },
  watch: {
    background: function(image) {
      if (image != null) {
        this.$refs.scaler.src = image.src;
        this.$refs.image.src = image.src;

        this.aspectRatio = image ? image.naturalWidth / image.naturalHeight : 0;

        // set max canvas height for text size calculation
        this.annotationDrawingOptions.backgroundNaturalHeight = image.naturalHeight;

        this.resize();
      }
    },
    zoom: function() {
      this.changeZoom();
    },
    zoomExtension: function() {
      this.changeZoom();
    },
  },
  computed: {
    imageHeight: function () {
      let dpr = window.devicePixelRatio;
      return Math.trunc(this.imageWidth/this.aspectRatio);
    },
    externalWidth: function() {
      return this.imageWidth + SCROLL_BAR_WIDTH;
    },
    externalHeight: function() {
      return this.imageHeight + SCROLL_BAR_WIDTH;
    },
  },
  mounted() {
    this.annotationViewContainer = this.$refs.annotationViewContainer;
    this.annotationViewContent = this.$refs.annotationViewContent;
    this.scalerContainer = this.$refs.scalerContainer;
    window.addEventListener('resize', this.resize);
    this.annotationViewContent.addEventListener('scroll', this.onScroll);
    this.annotationViewContent.addEventListener('wheel', this.handleWheel);
  },
  beforeUnmount: function() {
    window.removeEventListener('resize', this.resize);
    this.annotationViewContent.removeEventListener('scroll', this.onScroll);
    this.annotationViewContent.removeEventListener('wheel', this.handleWheel);
  },
  methods: {
    changeZoom: function() {

      let rect = this.annotationViewContent.getBoundingClientRect();
      let portX = this.viewZoomFocusX >= 0 ? this.viewZoomFocusX : rect.width / 2;
      let portY = this.viewZoomFocusY >= 0 ? this.viewZoomFocusY : rect.height / 2;
      this.relativeScroll_x = (this.annotationViewContent.scrollLeft + portX) / this.imageWidth;
      this.relativeScroll_y = (this.annotationViewContent.scrollTop + portY) / this.imageHeight;

      let defaultZoom = 1;
      let zoomRange = this.zoomExtension - defaultZoom;
      let stopCount = zoomSettings.max - zoomSettings.min;
      let result = zoomRange * (this.zoom - zoomSettings.min) / stopCount + defaultZoom;
      this.zoomFactor = result;

      let imageRect = this.annotationViewContainer.getBoundingClientRect();
      this.imageWidth = Math.trunc(imageRect.width * this.zoomFactor - SCROLL_BAR_WIDTH);

      this.$nextTick(() => { 
        let x = this.imageWidth * this.relativeScroll_x - portX;
        let y = this.imageHeight * this.relativeScroll_y - portY;
        this.annotationViewContent.scrollTo({
          top: y,
          left: x,
          behavior: 'auto'
        });
        // reset these so we go back to zooming on the view centre with the buttons
        this.viewZoomFocusX = -1;
        this.viewZoomFocusY = -1;
      });
    },
    resize: function() {

      let scalerContainer = this.scalerContainer;
      let view = this.annotationViewContainer;
      scalerContainer.classList.remove('display-none');
      view.classList.add('display-none');
      let rect = scalerContainer.getBoundingClientRect();
      view.classList.remove('display-none')
      scalerContainer.classList.add('display-none')
      if (rect.width > 0 && rect.height > 0) {
        view.style['width'] = rect.width + 'px';
        view.style['height'] = rect.height + 8 + 'px'; 
        
        // TODO: sort out scrollbar dimensions

        let width = Math.trunc(rect.width * this.zoomFactor - SCROLL_BAR_WIDTH);
        if (this.imageWidth != width) {
          this.imageWidth = width;
        }
        this.$nextTick(() => this.reCentreFocalPoint());
      }
    },
    onScroll: function () {
      let rect = this.annotationViewContent.getBoundingClientRect();
      this.relativeScroll_x = (this.annotationViewContent.scrollLeft + rect.width / 2) / this.imageWidth;
      this.relativeScroll_y = (this.annotationViewContent.scrollTop + rect.height / 2) / this.imageHeight;
      this.viewportChanged();
    },
    handleWheel: function (evt) {
      if (evt.ctrlKey) {
        evt.preventDefault();
        this.viewZoomFocusX = evt.offsetX - this.annotationViewContent.scrollLeft;
        this.viewZoomFocusY = evt.offsetY - this.annotationViewContent.scrollTop;
        this.$emit('zoom', evt.deltaY < 0);
      }
      evt.stopPropagation();
    },
    reCentreFocalPoint: function (panTo) {
      let rect = this.annotationViewContent.getBoundingClientRect();
      let top = this.imageHeight * this.relativeScroll_y - rect.height / 2;
      let left = this.imageWidth * this.relativeScroll_x - rect.width / 2;
      this.annotationViewContent.scrollTo({
        top: top,
        left: left,
        behavior: (panTo ? 'smooth' : 'auto')
      });
      this.viewportChanged();
    },
    viewportChanged: function() {
      let rect = this.annotationViewContent.getBoundingClientRect();
      let top = (this.annotationViewContent.scrollTop);
      let left = (this.annotationViewContent.scrollLeft);
      let bottom = (this.annotationViewContent.scrollTop + rect.height);
      let right = (this.annotationViewContent.scrollLeft + rect.width);
      let relativeViewport = {
        top: Math.max(0, top / this.externalHeight),
        left: Math.max(0, left / this.externalWidth),
        bottom: Math.min(1, bottom / this.externalHeight),
        right: Math.min(1, right / this.externalWidth),
        aspectRatio: (right - left) / (bottom - top)
      };
      this.$emit('relativeViewportChanged', relativeViewport);
    },
  }
}
</script>

<style scoped>
  #annotation-view-content {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: scroll;
  }
  .display-layer {
    grid-area: 1 / 1 / 1 / 1;
    max-width: none;
  }
  .scaler {
    max-width: 100%;
    max-height: 100%;
    visibility: hidden;
    overflow: hidden;
  }
  .scaler img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
  }

  .display-none {
    display: none;
  }

</style>