<template>
  <fieldset v-if="!instanceState.isDataRow" class="feature-instance" v-bind:class="{ emphasizenew: newForm }" v-bind:ref="instance.id" v-bind:id="instance.id">
    <legend v-bind:class="{ emphasizenew: newForm }" >
      <a v-on:click="toggle" ><i :class="['icon', instanceState.isExpanded ? 'icon-chevron-down' : 'icon-chevron-right']" title="Click to toggle"></i></a>
      {{ interpolatedLabel }}
      <button v-show="!readOnly" type="button" class="btn btn-danger btn-small pull-right" v-on:click="remove" :disabled="disableRemove">Remove</button>
    </legend>
    <inspection-section
      :fields="fields"
      :data="instance.data"
      :fieldListModel="fieldListModel"
      :fieldListState="getFieldListState()"
      @update:data="data => dataUpdated(data)"
      :read-only="readOnly"
      :drawing-sets="drawingSets"
      :notifyFieldEvent="notifyFieldEvent"
      v-show="instanceState.isExpanded"
      />
  </fieldset>
  <inspection-section-row
    v-else
    :fields="fields"
    :columns="columns"
    :data="instance.data"
    :fieldListModel="fieldListModel"
    :fieldListState="getFieldListState()"
    @update:data="data => dataUpdated(data)"
    :read-only="readOnly"
    :notifyFieldEvent="notifyFieldEvent"
    />
</template>

<script>
  import _ from 'lodash'
  import labelTemplateMixin from '../../mixins/label_template_mixin'
  import InspectionSection from '../inspection_section.vue'
  import InspectionSectionRow from '../inspection_section_row.vue'
  
  export default {
    beforeCreate: function () {
      // To avoid a recursive loop in component registration register this requirement at runtime
      this.$options.components.InspectionSection = InspectionSection ;
      this.$options.components.InspectionSectionRow = InspectionSectionRow ;
    },
    mounted: function () {
      if (this.newForm && ! this.instanceState.isDataRow) {
        this.$refs[this.instance.id].scrollIntoView({ behavior: 'smooth' });
      }
    },
    mixins: [labelTemplateMixin],
    props: {
      specification: {
        type: Object,
        required: true
      },
      fields: Array,
      instance: Object,
      fieldListModel: {
        type: Object,
        required: true
      },
      instanceState: {
        type: Object,
        required: true
      },
      readOnly: {
        type: Boolean,
        default: function () { return false; }
      },
      newForm: Boolean,
      columns: Array,
      drawingSets: Array,
      notifyInstanceData: Object,
    },
    data: function () {
      let initialLinkValue = this.getLinkValue(this.instance.data);
      return {
        linkValue: initialLinkValue,
        notifyFieldEvent: null,
      }
    },
    watch: {
      interpolatedPrefix: function() {
        this.calculateBalloonText();
      },
      interpolatedSuffix: function() {
        this.calculateBalloonText();
      },
      linkValue: function() {
        this.calculateBalloonText();
      },
      notifyInstanceData: function(val) {
        if (val.type == 'getBalloonValue') {
          this.calculateBalloonText();
        }
      },
    },
    computed: {
      data: function () { //used in the labeltemplatemixin
        return this.instance.data;
      },
      disableRemove: function () {
        // Use comparision to false so undefined and nil don't disallow remove
        return this.isReadOnly || this.specification.allow_remove === false
      },
      labelTemplateStr: function () {
        return this.specification.instance_label
      },
    },
    methods: {
      getFieldListState: function() {
        return this.instanceState.fieldListState;
      },
      calculateBalloonText: function() {
        let fullValue = this.interpolatedPrefix + this.linkValue + this.interpolatedSuffix;
        this.$emit('notifyInstanceEvent', {
          source: this.instance.id, 
          payload: {newValue: fullValue},
          type: 'updatedBalloonText'
          });
      },
      toggle: function (e) {
        e.preventDefault();
        this.instanceState.toggleExpanded();
      },
      remove: function (e) {
        e.preventDefault();
        if (!this.disableRemove && window.confirm("Are you sure you want to remove " + this.interpolatedLabel + "?")) {
          this.$emit('destroy')
        }
      },
      dataUpdated: function (newData) {
        let instance = _.omit(this.instance, ['data'])
        instance.data = newData
        this.linkValue = this.getLinkValue(newData);
        this.$emit('update:instance', instance)
      },
      getLinkValue: function (instanceData) {
        let linkFieldReference = this.specification.link_field;
        // use the label generator to extract the link value from the field
        let newVal = this.interpolatedText('%{'+linkFieldReference+'}', instanceData);
        return newVal;
      },
    }
  }
</script>

<style scoped lang="scss">
.emphasizenew {
  animation: fade 3s forwards;
  background-color: rgba(0,113,156,0.5);
}
@keyframes fade {
    to {background-color:transparent;}
}
</style>
