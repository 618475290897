import _ from 'lodash'
import { FieldList } from './field_list.js'
import { NewInstance as NewFeatureInstance } from './feature_instance.js'
import { _BaseField as Base } from './_base.js'

const type = 'feature';
const FeatureFieldImpl = (specification, sourceLists) => {

  const spec = _.cloneDeep(specification);
  
  const fieldList = FieldList.create(spec.fields, sourceLists);

  const minInstances = Number.isFinite(spec.minimum) ? spec.minimum : undefined;
  const maxInstances = Number.isFinite(spec.maximum) ? spec.maximum : undefined;

  const thisField = Base.create(type, spec);
  
  thisField.isRequired = !!minInstances && minInstances > 0;
  thisField.getFieldList = () => fieldList; // TODO: should this be cloned?
  thisField.newValue = newValue;
  thisField.validate = validate;
  thisField.newInstance = newInstance;

  return thisField;


  /* end of construction */


  function newInstance(data) {
    data = data ?? fieldList.defaultData();
    return NewFeatureInstance(data);
  }

  function validate(jsonValue) {
    let errors = [];
    let isValid = true;

    if (!thisField.isReadOnly) {
      let criteriaCheck = checkCriteria(jsonValue);

      errors = errors.concat(criteriaCheck.messages);
      isValid = isValid && criteriaCheck.isValid;
    }

    return {
      isValid: isValid,
      errors: errors
    }
  }

  // function isPresent (jsonValue) {
  //   return jsonValue?.values != null && jsonValue.values.length > 0;
  // }

  function checkCriteria (jsonValue) {
    let messages = [];
    let isValid = true;

    let hasMin = minInstances != null && minInstances > 0;
    let hasMax = maxInstances != null && maxInstances > 0;

    let instances = Array.isArray(jsonValue?.values) ? jsonValue.values : [];
    let minMet = !hasMin || (instances.length >= minInstances);
    let maxMet = !hasMax || (instances.length <= maxInstances);
    
    isValid = minMet && maxMet;

    if (!isValid) {
      let message = hasMin && hasMax
        ? (minInstances == maxInstances) ? "You must have " + items(maxInstances)
          : "You must have between " + minInstances + " and " + items(maxInstances)
        : hasMax ? "You can have upto " + items(maxInstances)
        : "You must have at least " + items(minInstances);
      messages.push(message);
    }

    return {
      isValid: isValid,
      messages: messages,
    };

    function items(val) {
      return _.toString(val) + (val === 1 ? " sub-form" : " sub-forms");
    }
  }

  function newValue (instances) {
    return {
      type: type,
      values: instances
    }
  }
}

export const FeatureField = {
  type,
  create: (specification, sourceLists) => FeatureFieldImpl(specification, sourceLists),
}
