import _ from 'lodash'
import { _BaseField as Base } from './_base.js'

const type = 'location';
const LocationFieldImpl = (specification) => {

  const spec = _.cloneDeep(specification);

  const thisField = Base.create(type, spec);
  
  thisField.parse = parse;
  thisField.validate = validate;

  return thisField;


  /* end of construction */


  function validate(jsonValue) {
    let errors = [];
    let isValid = true;

    if (!thisField.isReadOnly) {
      let requiredCheck = checkRequired(jsonValue);
      errors = errors.concat(requiredCheck.messages);
      isValid = isValid && requiredCheck.isValid;
    }

    return {
      isValid: isValid,
      errors: errors
    }
  }

  function checkRequired (jsonValue) {
    let isValid = !thisField.isRequired || isPresent(jsonValue);
    return {
      isValid: isValid,
      messages: isValid ? [] : ['This field is mandatory']
    };
  }

  function isPresent (jsonValue) {
    return jsonValue != null && (jsonValue.longitude != null || jsonValue.latitude != null);
  }
  
  function parse(stringValue) {
    let output = null;
    let messages = [];
    if (!_.isEmpty(stringValue?.trim())) {
      let matches = stringValue?.match(/^\s*(-?\d+(?:\.\d+)?)\s*,\s*(-?\d+(?:\.\d+)?)\s*$/);
      if (_.isArray(matches)) {
        // assumes lat,long order
        let lat = parseFloat(matches[1]);
        let long = parseFloat(matches[2]);
        let latMessage = !_.isNumber(lat) ? "Latitude must be a decimal"
            : -90 > lat ? "Latitude must be larger than or equal to -90"
            : 90 < lat ? "Latitude must be smaller than or equal to 90"
            : null;
        let longMessage = !_.isNumber(long) ? "Longitude must be a decimal"
            : -180 > long ? "Longitude must be larger than or equal to -180"
            : 180 < long ? "Longitude must be smaller than or equal to 180"
            : null;
        if (latMessage) {
          messages.push(latMessage);
        }
        if (longMessage) {
          messages.push(longMessage);
        }
        if (_.isEmpty(messages)) {
          output = {
            type: type,
            latitude: lat,
            longitude: long,
          };
        }
      } else {
        messages.push("Value must be two comma separated decimals: Lat, Long");
      }
    }
    return {
      data: output,
      messages: messages,
    };
  };
}

export const LocationField = {
  type,
  create: (specification) => LocationFieldImpl(specification),
}