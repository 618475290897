<template>
  <div class="inspection-photo" v-show="isVisible">
    <label v-bind:for="formId" class="control-label">{{ label }}<span v-if="fieldModel.isRequired">*</span></label>
    <p class="help-block" v-if="hasHelpText">{{ helpText }}</p>
    <p class="criteria-block" v-if="shouldShowValidation">{{ validationErrorText }}</p>
    <button v-show="hasPhoto" @click.prevent="openEditor" class="btn btn-primary open-button">{{editorLabel}}</button>
    <div class="photo-container">
      <dropzone class="file-drop-zone" 
        v-if="!isReadOnly && sourceFile"
        v-show="!hasPhoto" 
        label="Drop your image file here (jpg, jpeg format)" 
        @files_selected="val => onFilesDragged(val)"/>
      <img v-if="hasPhoto" :src="photoUrl">
      <!-- purely for measuring the dimensions for the overlay generator -->
      <img v-if="hasAnnotations && !isReadOnly" :src="photoUrlDims" ref="photoField" v-on:load="onPhotoLoad" v-show="false">
      <p v-if="isReadOnly && data?.drawing_id" class="drawing-label">{{drawingLabel}}</p>
    </div>
    <div v-if="!isReadOnly" class="image-control-container">
      <div class="alert alert-error small-alert" v-if="errorText">{{ errorText }}</div>
      <div class="alert alert-error small-alert" v-if="annotationError">
        {{ annotationError }}
        <button class="pull-right" @click.prevent="regenerateOverlay(data)">Retry</button>
      </div>
      <div class="alert alert-success small-alert" v-if="fileLoadProgress">{{ fileLoadProgress }}</div>
      <input style="display: none" type="file" accept="image/jpeg" @change="onFileChosen" ref="fileInput" >
      <button v-if="sourceFile" @click.prevent="onFileInput" class="btn btn-small">Load Image File...</button>
      <button v-show="hasPhoto" class="btn btn-danger btn-small pull-right" @click.prevent="removeImage">Remove Image</button>
      <select v-if="showDrawingSelector" v-model="selectedDrawing" class="span4 drawing-select" ref="seldrawing">
        <option value="TOP" disabled selected>Choose from Drawing Sets ...</option>
        <optgroup :label="drawingSet.drawing_set_name" v-for="drawingSet in filteredDrawingSets" :key="drawingSet.drawing_set_name">
          <option :value="drawing.drawing_id" v-for="drawing in drawingSet.drawings" :key="drawing.drawing_id">{{ drawing.drawing_name }}</option>
        </optgroup>
      </select>
    </div>
    <annotation-editor
      :fieldModel="fieldModel"
      :photoFieldValue="data"
      :textLabels="fieldState?.labels"
      :readOnly="isReadOnly"
      :photoLabel="drawingLabel"
      :locatorClassification="fieldState?.getClassification(fieldModel.locator?.color_by)"
      :notifyBalloonChange="notifyFieldData"
      v-if="showEditor"
      @getBalloonValue="val => notifyFieldEvent(val)"
      @close="val => closeEditor(val)"
    />
  </div>
</template>

<script>
  import _ from 'lodash'
  import fieldMixin from '../../mixins/field_mixin'
  import fieldLabelMixin from '../../mixins/field_label_mixin'
  import fieldValidationMixin from '../../mixins/field_validation_mixin'
  import generateUUID from 'uuid/v4'
  import { AssetUploader } from "../../lib/asset_uploader"
  import dropzone from '../utils/dropzone.vue'

  import annotation_editor from '../annotation_editor/annotation_editor.vue'
  import { OverlayGenerator } from '../../lib/annotations/annotation_overlay_generator.js'

  const OVERLAY_ERROR_TEXT = 'Failed to gnerate an annotation overlay.';
  const FILE_TYPE_ERROR_TEXT = 'Select a valid file (jpg or jpeg)... come on ...';
  const FILE_SIZE_ERROR_TEXT = 'Please check your file, it needs to be less than 20MB...';
  const IMAGE_AREA_ERROR_TEXT = 'Please check your file, it needs to be less than 40 Megapixels...';

  const MAX_FILE_SIZE = 20000000;
  const MAX_IMAGE_AREA = 40000000;

  const ASSET_CREATED_TEXT = 'Asset created successfully!';


  // the width we are using to style the field
  const MAX_IMAGE_WIDTH = 620;
  const MAX_IMAGE_HEIGHT = 620;

  export default {
    components: {
      dropzone,
      annotationEditor: annotation_editor,
    },
    mixins: [fieldMixin, fieldLabelMixin, fieldValidationMixin],
    data: function () {
      let restrictToCategories = this.fieldModel.restrictToCategories;
      let initialDrawingSets = !this.drawingSets ? []
        : !restrictToCategories ? [...this.drawingSets]
        : this.drawingSets.filter(ds => restrictToCategories.includes(ds.drawing_set_category));

      let initialSelection = this.data?.drawing_id ?? 'TOP';

      return {
        selectedDrawing: initialSelection,
        fileLoadProgress: '',
        errorText: '',
        showEditor: false,
        filteredDrawingSets: initialDrawingSets,
        photoUrl: "",
        photoUrlDims: "", // this is only for the overlay generator
        annotationError: null, // this is only for the overlay generator
        photoLoaded: false, // only for overlay generator
        newAnnotationAssetUUID: generateUUID(), // overlay generator only
        overlayFieldValue: this.data, // overlay generator only
      }
    },
    watch: {
      selectedDrawing: function (newSelection) {
        if (newSelection != null && newSelection != 'TOP') {
          this.onDrawingChosen(newSelection);
        }
      },
      notifyFieldData: function(val) {
        let changed = false;
        if (!this.showEditor) {
          if (val.type == 'updatedBalloonText') {
            _.forEach(this.data.annotations, (a) => {
              let sections = a.linked_feature.split('/');
              let last = sections[sections.length - 1];
              if (last == val.source
                  && a.annotation_type == 'balloon') {
                a.annotation_data.text = val.payload.newValue;
                a.annotation_data.full_text = val.payload.newValue;
                changed = true;
              }
            })
          }
        }
        if (changed) {
          this.$nextTick(() => {
            this.setPhotoUrl(this.data);
            this.regenerateOverlay(this.data);
          });
        }
      },
      data: {
        handler(dataValue, previous) {
          if (dataValue && !_.isEqual(dataValue, previous)) {
            this.setPhotoUrl(dataValue);
            if (!_.isEqual(dataValue, this.overlayFieldValue)) {
              this.overlayFieldValue = dataValue;
              this.regenerateOverlay(dataValue);
            }
          } else if (!dataValue) {
            this.selectedDrawing = 'TOP';
          }
        },
        immediate: true
      },
    },
    mounted() {
      this.scanForLinks();
    },
    computed: {
      allowedSources: function() {
        return this.fieldModel.sourceOptions ?? ['folder', 'camera', 'drawing'];
      },
      sourceFile: function () {
        return (this.allowedSources.includes('folder') || this.allowedSources.includes('camera'));
      },
      showDrawingSelector: function() {
        return Array.isArray(this.filteredDrawingSets) && this.filteredDrawingSets.length > 0
          && this.allowedSources.includes('drawing');
      },
      hasPhoto: function () {
        return !!this.data?.asset_id;
      },
      editorLabel: function () {
        return this.isReadOnly ? "View" : "Edit";
      },
      drawingLabel: function() {
        return this.findDrawing(this.data?.drawing_id)?.drawing_name;
      },
      hasAnnotations: function () {
        return this.data?.annotations?.length > 0;
      },
    },
    methods: {
      openEditor: function() {
        this.showEditor = true;
        this.annotationError = null;
      },
      closeEditor: function(newField) {
        this.showEditor = false;
        if (newField && !this.isReadOnly) {
          this.emitUpdateData(newField);
        }
      },
      scanForLinks: function() {
        let links = [];
        if (this.data && this.data.annotations) {
          _.forEach(this.data.annotations, (a) => {
            if (!!a.linked_feature) {
              // since the inspection_section works in relative paths - we need to give it just the last section to avoid a misunderstanding
              let sections = a.linked_feature.split('/');
              let last = sections[sections.length - 1];
              links.push(last);
            }
          })
        }
        let reg = {
          owner: this.fieldModel.reference,
          links: links
        };
        this.$emit('registerLinks', reg);
      },
      notifyFieldEvent: function(val) {
        val.source = this.fieldModel.reference;
        this.$emit('notifyFieldEvent', val);
      },
      findDrawing: function(drawingId) {
        return this.filteredDrawingSets
              .flatMap(ds => ds.drawings)
              .find(d => d.drawing_id === drawingId);
      },
      setPhotoUrl: async function(photoFieldValue) {
        if (!photoFieldValue?.asset_id) {
          return;
        }
        let dpr = Math.max(1, window.devicePixelRatio);
        let fetchUrl = '/o/' + this.organisationId() + '/assets/' + photoFieldValue.asset_id + '/overlay'
        let data = {
          photo: _.cloneDeep(photoFieldValue),
          max_width: MAX_IMAGE_WIDTH * dpr,
          max_height: MAX_IMAGE_HEIGHT * dpr,
        };
        const response = await this.$http.post(fetchUrl, data);
        let url = response.body.url;
        this.photoUrl = url;
      },
      organisationId: function () {
        const organisationIdRegex = /^\/o\/(\d+)\//g;
        let matches = organisationIdRegex.exec(window.location.pathname)

        if (matches) {
          return matches[1]
        }
      },
      onDrawingChosen: function(drawingId) {
        this.resetMessages();
        if (drawingId != this.data?.drawing_id) {
          let assetId = this.findDrawing(drawingId)?.asset_id;
          if (assetId) {
            this.changeAsset(assetId, drawingId)
          }
        }
      },
      onFileInput: function () {
        this.resetMessages();
        this.$refs.fileInput.click();          
      },
      onFileChosen: function (event) {
        this.onFileSelected(event.target.files[0]);
      },
      onFilesDragged: function (files) {
        this.onFileSelected(files[0]);
      },
      onFileSelected: function (file) {
        let vm = this;
        vm.fileLoadProgress = '';
        if (!file?.type == 'image/jpeg') {
          vm.errorText = FILE_TYPE_ERROR_TEXT;
        } else if (file.size >= MAX_FILE_SIZE) {
          vm.errorText = FILE_SIZE_ERROR_TEXT;
        } else {
          var _URL = window.URL || window.webkitURL;
          var img = new Image();

          img.onload = function() {
            imageLoaded(this);
          };
          img.src = _URL.createObjectURL(file);
        }

        function imageLoaded(img) {
          if (img.width * img.height >= MAX_IMAGE_AREA) {
            vm.errorText = IMAGE_AREA_ERROR_TEXT;
          } else {
            let uploader = AssetUploader(success, fail, message => vm.fileLoadProgress = message);
            uploader.uploadJpeg(file)
          }
        }
        function success(assetId) {
          vm.fileLoadProgress = ASSET_CREATED_TEXT;
          vm.selectedDrawing = 'TOP';
          setTimeout(() => {
            vm.changeAsset(assetId, null);
          },2000);
          setTimeout(() => {
            vm.fileLoadProgress = '';
            vm.$refs.fileInput.value = '';
          },3500);
        };
        function fail(message) {
          vm.errorText = message;
          vm.fileLoadProgress = '';
          vm.$refs.fileInput.value = '';
        }
      },
      removeImage: function () {
        if (this.hasPhoto && window.confirm("Are you sure you want to remove image: " + this.label + " and any annotations?")) {
          this.selectedDrawing = 'TOP';
          this.annotationError = null;
          this.photoUrl = '';
          this.resetMessages();
          this.$emit('clear:data');
        }
      },
      resetMessages: function() {
        this.errorText = '';
        this.fileLoadProgress = '';
      },
      changeAsset: function(assetId, drawingId) {
        this.annotationError = null;
        let newPhotoField = {
          type: this.fieldModel.type,
          asset_id: assetId,
          window: { "tl_x": 0,"tl_y": 0, "scale_side": 1 },
        };
        if (drawingId) {
          newPhotoField.drawing_id = drawingId;
        }
        if (this.data && this.data.annotations && this.data.annotations.length > 0
            && window.confirm("Would you like to keep the existing annotations?")) {
          newPhotoField.annotations = _.cloneDeep(this.data.annotations);
          if (this.data && this.data.text_scale) {
            newPhotoField.text_scale = this.data.text_scale;
          }
          this.regenerateOverlay(_.cloneDeep(newPhotoField));
        }

        let hasAnnotations = !!newPhotoField.annotations && newPhotoField.annotations.length > 0;
        if (this.data && hasAnnotations && this.data.annotation_asset_id) {
          newPhotoField.annotation_asset_id = this.data.annotation_asset_id;
        }
        this.emitUpdateData(newPhotoField);
      },
      emitUpdateData: function (dataToSend) {
        this.$emit('update:data', dataToSend);
        if (!_.isEqual(this.data, dataToSend)) {
          this.fieldState.touchField();
        }
      },



      regenerateOverlay: function (fieldValue) {
        if (fieldValue?.annotations && fieldValue.annotations.length > 0 && fieldValue.asset_id && !this.isReadOnly) {
          let assetChanged = this.data.asset_id !== fieldValue.asset_id;
          if (assetChanged || !this.photoUrlDims) {
            this.photoLoaded = false;
            this.photoUrlDims = '/o/' + this.organisationId() + '/assets/' + fieldValue.asset_id;
          } else if (this.photoLoaded) {
            let overlayGenerator = OverlayGenerator(
              (annotationAssetId) => {
                fieldValue.annotation_asset_id = annotationAssetId;
                this.emitUpdateData(fieldValue);
                this.annotationError = null;
              },
              (message) => this.annotationError = OVERLAY_ERROR_TEXT);

            overlayGenerator.start(fieldValue, this.$refs.photoField, this.newAnnotationAssetUUID);
          }
        }
      },
      onPhotoLoad: function (event) {
        this.photoLoaded = true;
        this.regenerateOverlay(this.data);
      },
    },
  }
</script>

<style scoped>
  .inspection-photo {
    margin-bottom: 20px;
    width: 620px;
  }
  .photo-container {
    margin-bottom: 10px;
  }
  .photo-container img {
    width: 620px;
    max-height: 620px;
    object-fit: contain;
  }
  .file-drop-zone {
    height: 80px;
  }
  .image-control-container {
    position: relative;
    width: 620px;
  }
  .small-alert {
    width: 569px;
    margin-bottom: 5px;
  }
  .drawing-select {
    margin-bottom: 0px;
  }
  .open-button {
    float: right;
    margin-bottom: 10px;
  }
  .drawing-label {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
  }
</style>
