import AssetMap from './components/asset_map.vue'
import InspectionEditor from './components/inspection_editor.vue'
import DrawingEditor from './components/drawing_editor.vue'
import DrawingImporter from './components/drawing_importer.vue'
import DrawingVersions from './components/drawing_versions.vue'
import PhotoFrame from './components/photo_frame.vue'
import WorkpackageEditor from './components/workpackage_editor.vue'
import DrawingDisplay from './components/drawing_display.vue'
import SingleListEditor from './components/single_list_editor.vue'
import SingleDrawingEditor from './components/single_drawing_editor.vue'
import MembershipsEditor from './components/memberships.vue'
import ClassificationsSelector from './components/classifications_selector/classifications_selector.vue'

export default {
  components: {
    AssetMap,
    InspectionEditor,
    DrawingEditor,
    DrawingImporter,
    DrawingVersions,
    PhotoFrame,
    WorkpackageEditor,
    DrawingDisplay,
    SingleListEditor,
    SingleDrawingEditor,
    MembershipsEditor,
    ClassificationsSelector
  },
  http: {
    root: '/',
    headers: {}
  }
}