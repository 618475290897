import _ from 'lodash'
import { _BaseField as Base } from './_base.js'

const type = 'text';
const TextFieldImpl = (specification, sourceLists) => {

  const spec = _.cloneDeep(specification);

  const maxLength = Number.isFinite(spec.maximum_length) ? spec.maximum_length : undefined;

  const listSource = Array.isArray(spec.source)
  ? spec.source
    .filter(s => s.startsWith('list:') || s.startsWith('list+K:'))
    .map(s => {
      let parts = s.split(':');
      return Object.freeze({
        isListOnly: parts[0] === 'list',
        list: parts[1]
      });
    })[0] // first matching source
  : undefined;

  const options = sourceLists != null && listSource
    ? sourceLists[listSource.list]?.items
        ?.filter(i => i.use)
        ?.map(i => ({ key: i.key, value: i.value }))
      ?? []
    : [];

  const thisField = Base.create(type, spec);
  
  thisField.isMultiLine = !!(spec.multiline ?? true);
  thisField.listSource = listSource;
  thisField.getListOptionsForFieldInstance = getOptionsForField;
  thisField.parse = parse;
  thisField.validate = validate;

  return thisField;


  /* end of construction */


  function getOptionsForField(originalTextInField) {
    let fieldOptions = [...options];

    if (listSource?.isListOnly && (typeof originalTextInField === 'string' || originalTextInField instanceof String) && originalTextInField.trim().length > 0) {
      if (fieldOptions.findIndex(o => o.value == originalTextInField) < 0) {
        fieldOptions.unshift({ key: originalTextInField, value: originalTextInField });
      }
    }
    return fieldOptions;
  }

  function validate(jsonValue) {
    let errors = [];
    let isValid = true;

    if (!thisField.isReadOnly) {
      let requiredCheck = checkRequired(jsonValue);
      errors = errors.concat(requiredCheck.messages);
      isValid = isValid && requiredCheck.isValid;

      if (isPresent(jsonValue)) {
        let criteriaCheck = checkCriteria(jsonValue);
        errors = errors.concat(criteriaCheck.messages);
        isValid = isValid && criteriaCheck.isValid;
      }
    }

    return {
      isValid: isValid,
      errors: errors
    }
  }

  function checkRequired (jsonValue) {
    let isValid = !thisField.isRequired || isPresent(jsonValue);
    return {
      isValid: isValid,
      messages: isValid ? [] : ['This field is mandatory']
    };
  }

  function isPresent (jsonValue) {
    return jsonValue?.value != null && jsonValue.value.length > 0;
  }
  
  function checkCriteria (jsonValue) {
    let val = jsonValue?.value;
    let hasMax = maxLength != null && maxLength > 0;
    let isValid = !hasMax || (val != null && val.length <= maxLength);

    let messages = [];
    if (!isValid) {
      let message = "Text must be no longer than " + maxLength + " characters.";
      messages.push(message);
    }

    return {
      isValid: isValid,
      messages: messages,
    };
  }
  
  function parse(value) {
    return {
      data: newFieldValue(value),
      messages: [],
    };
  }
}

function newFieldValue(stringValue) {
  return {
    type: type,
    value: stringValue,
  };
}

export const TextField = {
  type,
  create: (specification, sourceLists) => TextFieldImpl(specification, sourceLists),
  newFieldValue: newFieldValue,
}
