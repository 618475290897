import { TextField, IntegerField, DecimalField, DateField, TimeField, LocationField, SelectionField } from '../lib/fields/_fields.js'
import { NewInstance as NewFeatureInstance } from '../lib/fields/feature_instance.js'
import Papa from 'papaparse';


function importFile (fileToLoad, specification, featureFieldModel, existingInstances) {
  return new Promise((resolve, reject) => {
    if (specification.allow_add === false) {
      reject("This field does not permit additions");
    }
    if (fileToLoad.size === 0) {
      reject("The file is empty");
    }
    loadFile(fileToLoad).then(results => {
      if (results.errors.length === 0) {
        if (results.meta.renamedHeaders && results.meta.renamedHeaders.length > 0) {
          reject("There are duplicate field names in the file");
        } else if (_.some(results.meta.fields, (val, index, array) => array.indexOf(val) !== index)) {
          reject("There are duplicate fields in the file");
        } else if (_.some(results.meta.fields, (val) => _.isEmpty(val))) {
          reject("There are blank field names in the file");
        } else if (results.data.length === 0) {
          reject("There was no data in the file");
        } else {
          let parseResults = parseData(results.data, featureFieldModel.getFieldList(), existingInstances);
          if (specification.maximum === 0 || existingInstances.length + parseResults.length <= specification.maximum) {
            let message = findError(parseResults);
            if (message) {
              reject(message);
            } else {
              let allInstances = existingInstances.concat(_.map(parseResults, r => r.feature));
              resolve(allInstances);
            }
          } else {
            reject("The field will not accept this many additions");
          }
        }
      } else {
        reject("There was an error reading the file: " + results.errors[0].message);
      }
    });
  });
}

function findError(result) {
  let duplicate = _.find(result, r => r.meta.is_duplicate);
  if (duplicate) {
    return "Line " + String(duplicate.meta.line) + " has already been loaded";
  } else {
    let messaged = _.find(result, r => Object.keys(r.meta.field_messages).length > 0);
    if (messaged) {
      let messages = messaged.meta.field_messages;
      let reference = Object.keys(messages)[0];
      return "Error importing line " + String(messaged.meta.line) + ": " + "Field '" + reference + "': " + messages[reference];
    }
  }
  return null;
}

function loadFile(fileToLoad) {
  return new Promise((resolve, reject) => {
    Papa.parse(fileToLoad, { 
      skipEmptyLines: true,
      delimiter: ",",
      header: true,
      transformHeader: (h, i) => h.trim(),
      complete: (results, file) => resolve(results),
    });
  });
}

function parseData(loadedData, fieldListModel, existingInstances) {
  // shallow copy compare instances with an empty id
  let existingForCompare = existingInstances.map(val => { return {...val, id:''}; });

  let results = [];

  let last = loadedData.length;
  for (let i = 0; i < last; i++) {
    let row = parseRow(fieldListModel, loadedData[i]);
      let compare = {...row.feature, id:''};
      let isDuplicate = !!_.find(existingForCompare, e => _.isEqual(e, compare));
      results.push({
      feature: row.feature,
      meta: {
        line: i + 1,
        is_duplicate: isDuplicate,
        field_messages: row.messages,
      },
    });
  }
  return results;
}

function parseRow (fieldListModel, dataStrings) {
  let messages = {};
  let output = {};

  _.each(dataStrings, (value, key) => {
    let field = fieldListModel.getField(key);
    if (_.isNil(field)) {
      messages[key] = "Field not in template"
    } else {
      let type = field.type;
      let parseResult;
      switch (type) {
        case TextField.type: 
        case IntegerField.type: 
        case DecimalField.type: 
        case DateField.type:
        case TimeField.type:
        case LocationField.type:
          parseResult = field.parse(value);
          break;
        case SelectionField.type:
          let values = value.split(',');
          parseResult = field.parse(values);
          if (parseResult.messages.length === 0) {
            let newValue = parseResult.data;
            if (newValue && values.length > newValue.selections.length) {
              parseResult.messages.push("Some selections did not match");
            }
          }
          break;
        default:
          parseResult = {
            data: null,
            messages: ["Field type not supported"],
          }
          break;
      }
      let newFieldValue = parseResult.data;
      let message = parseResult.messages.length > 0 ? parseResult.messages[0] 
          : !newFieldValue && !_.isEmpty(value) ? "Error loading value"
          : null;
      if (message) {
        messages[key] = message;
      }
      output[key] = newFieldValue;
    }
  });
  let feature = NewFeatureInstance(output);

  return {
    messages: messages,
    feature: feature,
  }
}



export { importFile as ImportFile }