<template>
  <div class="text-field control-group" v-if="isRow">
    <div class="controls inspection-table-cell" :title="label" v-show="isVisible">
      <div v-show="!isListOnly || isReadOnly">
        <textarea v-if="fieldModel.isMultiline" v-bind:name="formId" v-model="value" :disabled="isReadOnly" :class="[{'field-error': shouldShowValidation}, 'inspection-table-field']" ref="text_multi"></textarea>
        <input v-else v-bind:name="formId" type="text" v-model="value" :disabled="isReadOnly" :class="[{'field-error': shouldShowValidation}, 'inspection-table-field']" v-on:keydown.enter.prevent="" ref="text_single" />
      </div>
      <option-selector v-if="showListSource && !isReadOnly"
        :class="[isListOnly ? 'list-only-field-option-selector' : 'list-text-field-option-selector', {'field-error': shouldShowValidation}]"
        title="Add text from the list at cursor position / selection."
        :options="listOptions"
        :selectedKeys.sync="selections"
        :multiple="false"
        :searchable="true"
        :clearable="isListOnly"
        :placeholder="'Insert text at cursor / selection...'"
        :noFieldSelectOnly="isListAndText"
      />
    </div>
    <div class="controls" :title="label" v-show="!isVisible">
      &nbsp;
    </div>
  </div>
  <div class="text-field control-group" v-show="isVisible" v-else>
    <label v-bind:for="formId" class="control-label">{{ label }}<span v-if="fieldModel.isRequired">*</span>
    </label>
    <div class="controls">
      <p class="help-block" v-if="hasHelpText">{{ helpText }}</p>
      <p class="criteria-block" v-if="shouldShowValidation">
        <span v-if="shouldShowValidation">{{ validationErrorText }}</span>
      </p>
      <div class="value-controls">
        <div v-show="!isListOnly || isReadOnly">
          <textarea v-if="fieldModel.isMultiLine" v-bind:name="formId" v-model="value" :disabled="isReadOnly" :class="['span8']" ref="text_multi"></textarea>
          <input v-else v-bind:name="formId" type="text" v-model="value" :disabled="isReadOnly" :class="['span8']" v-on:keydown.enter.prevent="" ref="text_single" />        
        </div>
        <option-selector v-if="showListSource && !isReadOnly"
          :class="[isListOnly ? 'list-only-field-option-selector' : 'list-text-field-option-selector']"
          title="Add text from the list at cursor position / selection."
          :options="listOptions"
          :selectedKeys.sync="selections"
          :multiple="false"
          :searchable="true"
          :clearable="isListOnly"
          :placeholder="'Insert text at cursor / selection...'"
          :noFieldSelectOnly="isListAndText"
        />
      </div>      
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import optionSelector from '../utils/option_selector.vue'
  import fieldMixin from '../../mixins/field_mixin'
  import fieldLabelMixin from '../../mixins/field_label_mixin'
  import fieldValidationMixin from '../../mixins/field_validation_mixin'

  export default {
    components: {
      optionSelector,
    },
    mixins: [fieldMixin, fieldLabelMixin, fieldValidationMixin],
    data: function () {
      return {
        value: this.valueFromData(this.data),
        selections: this.getSelectionKeys(this.fieldModel, [], ''),
      }
    },
    watch: {
      data: function (data) {
        let newValue = this.valueFromData(data);
        if (!_.isEqual(this.value, newValue)) {
          this.value = newValue;
        }
      },
      notifyFieldData: function(val) {
        this.value = val.payload.value;
      },
      selections: function(newValue) {
        let selectedItemValue = Array.isArray(newValue) && newValue.length > 0 
            ? this.listOptions.filter(o => o.key == newValue[0])[0]?.value
            : null;
        this.setText(selectedItemValue);
        if (this.isListAndText) {
          this.activeTextField?.focus();
        }
        let selectionsToSet = this.isListAndText ? [] : this.selections;
        if (!_.isEqual(selectionsToSet, this.selections)) {
          this.selections = selectionsToSet;
        }
      },      
      value: _.debounce(function (newValue) {
          this.setNewValue(newValue)
        },150)
    },
    computed: {
      activeTextField: function () {
        return this.fieldModel.isMultiLine
          ? this.$refs.text_multi
          : this.$refs.text_single;
      },
      initialValue: function() {
        return this.valueFromData(this.fieldState.initialValue);
      },
      listOptions: function() {
        return this.fieldModel.getListOptionsForFieldInstance(this.initialValue) ?? [];
      },
      dropDownHelpText: function () {
        return "Add text from the list at cursor position / selection."
      },
      dropDownPrompt: function () {
       return " - Insert text at cursor / selection...";
      },
      isPresent: function () {
        return !_.isEmpty(this.value);
      },
      showListSource: function () {
        return this.listOptions.length > 0;
      },
      isListOnly: function () {
        let listSource = this.fieldModel.listSource;
        return listSource && !!listSource.isListOnly
      },
      isListAndText: function () {
        let listSource = this.fieldModel.listSource;
        return listSource && !listSource.isListOnly
      },
    },
    methods: {
      valueFromData: function(data) {
        return data?.value ?? null;
      },
      getSelectionKeys: function (fieldModel, listOptions, value) {
        if (fieldModel.listSource?.isListOnly) {
          let index = listOptions.findIndex(o => o.value == value);
          if (index >= 0) {
            return [listOptions[index].key];
          }
        }
        return [];
      },
      setText: function (textToInsert) {
        if (this.isListOnly) {
          this.value = textToInsert ?? '';
        } else if (this.isListAndText && textToInsert) {
          let thisField = this.activeTextField;
          if (thisField.selectionStart) {
            let startPos = thisField.selectionStart;
            let endPos = thisField.selectionEnd;
            let prefix = thisField.value.substring(0, startPos);
            let suffix = thisField.value.substring(endPos, thisField.value.length);
            this.value = prefix + textToInsert + suffix;
            let newCursorPosition = prefix.length + textToInsert.length;
            this.$nextTick(() => thisField.setSelectionRange(newCursorPosition, newCursorPosition));
          } else {
            this.value = thisField.value + textToInsert;
          }
        }
      },
      setNewValue: function (newValue) {
        let vm = this;
        let dataToSend = {};
        dataToSend = _.isNil(vm.data) ? {} : _.clone(vm.data);
        dataToSend['value'] = newValue;
        dataToSend['type'] = this.fieldModel.type;
        vm.$emit('update:data', dataToSend);
        if (!_.isEqual(this.data, dataToSend)) {
          this.fieldState.touchField();
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .inspection-table-cell {
    width: 95%;
    display:flex;
  }
  .inspection-table-cell > div {
    flex: 1 1 auto;
  }
  .inspection-table-field {
    padding-left: 6px;
    padding-right: 0px;
    margin-bottom: 0px;
    width: 100%;
  }
  .control-group {
    margin-bottom: 0px;  
  }
  .value-controls {
    display: flex;
    margin-bottom: 10px;
  }
  .value-controls:has(.list-text-field-option-selector) {
    input, textarea {
      margin-bottom: 0;
    }
    input {
      height: 22px;
    }
  }
  .list-only-field-option-selector {
    --os-field-color-bg: white; /* sets in option selector */
    width: 460px;
  }
  .list-only-field-option-selector.field-error {
    --os-field-border-color: red; /* sets in option selector */
  }
  .inspection-table-cell .list-only-field-option-selector {
    width: 200px;
  }

</style>