<template>
  <div :class="['os-option-selector']">
    <div :class="['os-field-container', {'os-no-field': noFieldSelectOnly}, {'os-field-open': isOpen}, {'os-disabled': disabled}]" 
        :tabindex="disabled ? -1 : 0" 
        ref="focusDefault" 
        :disabled="disabled"
        v-on:mousedown="onMousedown"
        v-on:mouseup="onMouseup"
        v-on:click.prevent="onToggle"
        v-on:blur="onBlur"
        v-on:keydown="onKeyDown">
      <div v-if="!noFieldSelectOnly" class="os-selected-options-container">
        <p v-show="showPlaceholder" class="os-placeholder">{{placeholder}}</p>
        <span v-show="!showPlaceholder" v-for="option in selectedOptions" :key="option.key" :class="['os-selected-option', {'os-multiple-option': multiple}]">
          <span class="os-selected-option-text">{{option.value}}</span>
          <button v-show="multiple || !isOpen"
              :disabled="disabled"
              :class="['os-button', 'os-deselect-button', {'os-deselect-gone': !multiple && !clearable}]"
              title="Click to remove value"
              v-on:mousedown="onMousedown"
              v-on:mouseup="onMouseup"
              v-on:click="onRemove($event, option.key)"
              v-on:keydown="onRemoveKeyDown($event, option.key)">
            <span class="os-icon material-icons">clear</span>
          </button>
        </span>
      </div>
      <button class="os-button os-toggle-button" tabindex="-1" :disabled="disabled">
        <span :class="['os-icon', 'material-icons', {'os-open': isOpen}]">expand_more</span>
      </button>
    </div>
    <div v-if="!disabled" :class="['os-dropdown-container', {'os-dropdown-open': isOpen}]" v-on:transitionend="onTransitionEnd">
      <div v-show="showDropdown" class="os-option-selector-dropdown-menu">
        <input type="text"
            v-model="criteria"
            ref="filter"
            :class="['os-option-filter', {'os-hide-filter': !searchable}]"
            v-on:blur="onBlur"
            v-on:keydown="onKeyDown"/>
        <div v-for="option in filteredOptions" 
            :key="option.key"
            v-on:mouseover="onMouseover(option)"
            v-on:mousedown="onMousedown"
            v-on:mouseup="onMouseup"
            v-on:click.prevent="onSelect($event, option)"
            :class="['os-dropdown-option', {'os-dropdown-current-option': option.key === highlightKey}, {'os-option-disabled': option.disabled}]"
            :title="option.value">
          {{option.value}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: Array, // expect [{key: <key>, value: <value> [, disabled: <true|false>] ...} ...]
    selectedKeys: Array,
    multiple: Boolean,
    searchable: Boolean,
    clearable: Boolean,
    disabled: Boolean,
    placeholder: String,
    noFieldSelectOnly: Boolean,
  },
  data: function() {
    return {
      isOpen: false,
      isMouseClick: false,
      needsFocusing: false,
      isTransitioning: false,
      currentKey: null,
      highlightKey: null,
      criteria: '',
    }
  },
  watch: {
    isOpen: function(isOpen) {
      this.isTransitioning = true;
      if (!isOpen) {
        this.criteria = '';
        if (this.multiple) {
          this.setKeys(null);
        } else if (!this.noFieldSelectOnly) {
          this.selectOption(this.currentKey);
        }
      }
    },
    selectedKeys: {
      handler: function(keys) {
        if (!this.multiple) {
          this.setKeys(keys?.[0]);
        }
      }, immediate: true
    },
  },
  computed: {
    filteredOptions: function() {
      let availableOptions = !this.multiple ? this.options ?? []
        : this.options?.filter(o => !this.selectedKeys?.includes(o.key)) ?? [];
      let lowerCriteria = this.criteria?.toLowerCase();
      return this.criteria ? availableOptions.filter(o => o.value.toLowerCase().includes(lowerCriteria))
        : [...availableOptions];
    },
    selectedOptions: function() {
      let predicate = this.multiple
        ? o => this.selectedKeys?.includes(o.key)
        : o => o.key === this.currentKey;
      return this.options?.filter(predicate) ?? [];
    },
    showDropdown: function() {
      return this.isOpen || this.isTransitioning;
    },
    showPlaceholder: function() {
      return (!Array.isArray(this.selectedKeys) || this.selectedKeys.length === 0) && !this.currentKey;
    },
  },
  methods: {
    onToggle: function() {
      if (!this.disabled) {
        this.toggleOpen();
      }
    },
    onBlur: function() {
      if (!this.isMouseClick && !this.isTransitioning) {
        this.isOpen = false;
      }
    },
    onMousedown: function(evt) {
      this.isMouseClick = true;
    },
    onMouseup: function() {
      this.isMouseClick = false;
    },
    onMouseover: function(option) {
      if (this.isOpen && !option.disabled) {
        this.highlightKey = option.key;
      }
    },
    onSelect: function(event, option) {
      if (!option.disabled) {
        this.actionSelect(option, event.ctrlKey);
      } else {
        this.focusFilter();
      }
    },
    onRemove: function(event, optionKey) {
      event.preventDefault();
      event.stopPropagation();
      this.removeOption(optionKey);
    },
    onTransitionEnd: function(evt) {
      if (evt.currentTarget === evt.target) {
        if (this.needsFocusing) {
          this.focusFilter();
          this.needsFocusing = false;
        }
        this.isTransitioning = false;
      }
    },
    onKeyDown: function(event) {
      if (this.disabled) {
        return;
      }
      switch(event.keyCode) {
        case 9: // tab
          if (this.isOpen) {
            event.preventDefault();
            this.toggleOpen();
          }
          break;
        case 13: // enter
          event.preventDefault();
          event.stopPropagation();
          if (this.isOpen) {
            this.actionSelect(this.filteredOptions.find(o => o.key === this.highlightKey), event.ctrlKey);
          } else {
            this.toggleOpen();
          }
          break;
        case 27: // esc
          event.preventDefault();
          if (this.isOpen) {
            this.toggleOpen();
          }
          break;
        case 32: // space
          event.preventDefault();
          event.stopPropagation();
          this.toggleOpen();
          break;
        case 38: // up arrow
          event.preventDefault();
          this.nextCurrent(this.findPrevKey(this.filteredOptions, this.highlightKey));
          break;
        case 40: // down arrow
          event.preventDefault();
          this.nextCurrent(this.findNextKey(this.filteredOptions, this.highlightKey));
          break;
      }
    },
    onRemoveKeyDown: function(event, optionKey) {
      if (this.disabled) {
        return;
      }
      switch(event.keyCode) {
        case 13: // enter
        case 32: // space
          this.removeOption(optionKey);
        case 38: // up arrow
        case 40: // down arrow
          event.preventDefault();
          event.stopPropagation();
      }
    },
    actionSelect: function(option, withCtrl) {
      if (!option?.disabled) {
        let nextKey = this.findNearbyKey(this.filteredOptions, this.highlightKey);
        this.selectOption(option.key);
        if (this.multiple && withCtrl) {
          this.setKeys(nextKey);
        } else {
          this.setKeys(option.key);
          this.toggleOpen();
        }
      }
    },
    nextCurrent: function (nextKey) {
      if (nextKey) {
        if (!this.isOpen && !this.multiple) {
          this.selectOption(nextKey);
        }
        this.setKeys(nextKey);
      }
    },
    findNearbyKey: function(options, thisKey) {
      return this.findNextKey(options, thisKey)
      ?? this.findPrevKey(options, thisKey);
    },
    findNextKey: function(options, thisKey) {
      const lastIndex = options.length - 1;
      let index = options.findIndex(o => o.key === thisKey);
      while (index < lastIndex && options[++index].disabled);
      let option = options[index];
      return !option.disabled && thisKey != option.key
        ? option.key
        : null;
    },
    findPrevKey: function(options, thisKey) {
      let index = options.findIndex(o => o.key === thisKey);
      while (index > 0 && options[--index].disabled);
      let option = options[index];
      return !option.disabled && thisKey != option.key
        ? option.key
        : null;
    },
    toggleOpen: function() {
      this.isOpen = !this.isOpen;
      if (this.isOpen || !this.noFieldSelectOnly) {
        this.needsFocusing = true;
      }
    },
    selectOption: function(optionKey) {
      let keys = [...this.selectedKeys ?? []];
      if (!keys.includes(optionKey)) {
        if (this.multiple) {
          keys.push(optionKey);
        } else {
          keys = [optionKey];
        }
        this.$emit('update:selectedKeys', keys);
      }
    },
    removeOption: function(optionKey) {
      let keys = this.selectedKeys ?? [];
      if (keys.includes(optionKey)) {
        this.$emit('update:selectedKeys', keys.filter(k => k != optionKey));
      }
      this.focusFilter();
    },
    focusFilter: function() {
      if (this.isOpen) {
        this.$refs.filter.focus();
      } else {
        this.$refs.focusDefault.focus();
      }
    },
    setKeys: function(key) {
      this.currentKey = key;
      this.highlightKey = key;
    }
  }
}
</script>
<style scoped>
  .os-option-selector {
    display: block;
    /* Move any variables into here that need to be available outside of this control */
    --os-field-color-bg: transparent;
    --os-field-border-color: #ccc;
    --os-field-border-radius: 4px;
    --os-field-border-width: 1px;
  }
  .os-field-container {

    /* Selected */
    --os-state-selected-color: #333;
    --os-state-selected-color-bg: #f0f0f0;
    --os-state-selected-border-color: rgba(60, 60, 60, 0.26);
    --os-state-selected-border-width: 1px;
    --os-state-selected-border-radius: 4px;

    /* Buttons */
    --os-button-color: rgba(60, 60, 60, 0.5);
    --os-button-color-bg: transparent;
    --os-button-border-color: rgba(60, 60, 60, 0.26);
    --os-button-border-width: 0;

    /* Deselect Button */
    --os-deselect-button-color: var(--os-button-color);
    --os-deselect-button-color-bg: var(--os-button-color-bg);
    --os-deselect-button-border-color: var(--os-button-border-color);
    --os-deselect-button-border-width: var(--os-button-border-width);

    /* Dropdown Button */
    --os-toggle-button-color: var(--os-button-color);
    --os-toggle-button-color-bg: var(--os-button-color-bg);
    --os-toggle-button-border-color: var(--os-button-border-color);
    --os-toggle-button-border-width: var(--os-button-border-width);

  }
  .os-dropdown-container {
    /* Dropdown */
    --os-dropdown-color-bg: white;
    --os-dropdown-border-color: #ccc;
    --os-dropdown-border-width: 1px;
    --os-dropdown-border-radius: 4px;
    --os-dropdown-color-shadow: rgba(0, 0, 0, 0.26);

    /* Disabled */
    --os-state-disabled-color: #ccc;
    /* --os-state-disabled-color-bg: #333; */

    /* Current */
    --os-state-current-color: white;
    --os-state-current-color-bg: #00719c;
  }



  .os-field-container {
    display: flex;
    justify-content: space-between;
    background-color: var(--os-field-color-bg);
    border: var(--os-field-border-width) solid var(--os-field-border-color);
    border-radius: var(--os-field-border-radius);
  }
  .os-no-field {
    width: 22px;
    height: 28px;
    --os-field-color-bg: #00719c;
    --os-toggle-button-color: white;
  }
  .os-field-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-color: transparent;
  }
  .os-placeholder {
    display: flex;
    align-items: center;
    padding: 0 8px;
    margin-bottom: 0;
    height: 28px;
    color: #999;
  }

  /* Focus Ring */
  .os-field-container:not(.os-disabled):focus, .os-deselect-button:focus, .os-toggle-button:focus {
    /* match other input field styles from /app/assets/stylesheets/unicorn/bootstrap.css.scss L1143 */
    border: 1px solid rgba(82, 168, 236, 0.8);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  }

/* Selected Options */
  .os-selected-options-container {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding-left: 2px;
    min-height: 28px;
  }
  .os-selected-option {
    display: flex;
    justify-content: space-between;
    color: var(--os-state-selected-color);
    min-height: 24px;
    white-space: nowrap;
    width: 100%;
    gap: 2px;
    margin-left: 8px;
  }
  .os-multiple-option {
    background-color: var(--os-state-selected-color-bg);
    border: var(--os-state-selected-border-width) solid var(--os-state-selected-border-color);
    border-radius: var(--os-state-selected-border-radius);
    width: auto;
    padding: 0 4px;
    margin: 3px 2px;
  }
  .os-selected-option-text {
    margin-top: auto;
    margin-bottom: auto;
  }
  .os-disabled {
    background-color: transparent;
    cursor: not-allowed;
  }
  .os-disabled .os-button {
    cursor: not-allowed;
  }

  /* Buttons */
  .os-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 22px;
    user-select: none;
  }
  .os-deselect-button {
    color: var(--os-deselect-button-color);
    background-color: var(--os-deselect-button-color-bg);
    border: var(--os-deselect-button-border-width) solid var(--os-deselect-button-border-color);
  }
  .os-deselect-button .os-icon {
    font-size: 16px;
    font-weight: bold;
  }
  .os-deselect-gone {
    display: none;
  }
  .os-toggle-button {
    color: var(--os-toggle-button-color);
    background-color: var(--os-toggle-button-color-bg);
    border: var(--os-toggle-button-border-width) solid var(--os-toggle-button-border-color);
  }
  .os-toggle-button .os-icon {
    transition: 300ms;
  }
  .os-open {
    transform: rotate(180deg);
  }

  /* Dropdown */
  .os-dropdown-container {
    position: relative;
    min-width: 200px;
    transition: opacity 100ms 
        cubic-bezier(1, -0.115, 0.975, 0.855);
    opacity: 0;
  }
  .os-dropdown-open {
    opacity: 1;
  }
  .os-option-selector-dropdown-menu {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: absolute;
    z-index: 1000;
    border: var(--os-dropdown-border-width) solid var(--os-dropdown-border-color);
    border-bottom-right-radius: var(--os-dropdown-border-radius);
    border-bottom-right-radius: var(--os-dropdown-border-radius);
    background-color: var(--os-dropdown-color-bg);
    border-top: 0;
    width: 100%;
    padding-top: 4px;
    box-shadow: var(--os-dropdown-color-shadow) 0px 3px 6px 0px;
  }
  .os-option-filter {
    padding-left: 4px;
    width: auto;
    margin: 4px;
  }
  .os-hide-filter {
    max-height: 0;
    margin: 0;
    padding: 0;
    border: none;
  }
  .os-hide-filter:focus {
    box-shadow: none;
  }
  .os-dropdown-option {
    cursor: pointer;
    padding: 4px;
    padding-left: 12px;
  }
  .os-dropdown-current-option {
    background-color: var(--os-state-current-color-bg);
    color: var(--os-state-current-color);
  }
  .os-option-disabled {
    color: var(--os-state-disabled-color);
    cursor: default;
  }
</style>