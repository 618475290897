import _ from 'lodash'
import { _BaseField as Base } from './_base.js'

const type = 'time';
const TimeFieldImpl = (specification) => {

  const spec = _.cloneDeep(specification);

  const thisField = Base.create(type, spec);
  
  thisField.hasDefault = !!(spec.set_on_create ?? true);
  thisField.getDefault = getDefault;
  thisField.parse = parse;
  thisField.validate = validate;

  return thisField;


  /* end of construction */


  function getDefault() {
    let now = new Date();
    return {
      type: type,
      hour: now.getHours(),
      minute: now.getMinutes(),
      second: 0
    };
  }
  
  function validate(jsonValue) {
    let errors = [];
    let isValid = true;

    if (!thisField.isReadOnly) {
      let requiredCheck = checkRequired(jsonValue);
      errors = errors.concat(requiredCheck.messages);
      isValid = isValid && requiredCheck.isValid;
    }

    return {
      isValid: isValid,
      errors: errors
    }
  }

  function checkRequired (jsonValue) {
    let isValid = !thisField.isRequired || isPresent(jsonValue);
    return {
      isValid: isValid,
      messages: isValid ? [] : ['This field is mandatory']
    };
  }

  function isPresent (jsonValue) {
    return jsonValue != null && (jsonValue.hour != null || jsonValue.minute != null || jsonValue.second != null);
  }
  
  function parse(stringValue) {
    let output = null;
    let messages = [];
  
    if (!_.isEmpty(stringValue?.trim())) {
      // H[H]:MM[:SS[.s[s[s]]]][[ ]AM|PM|am|pm]
      var TIME_REGEX = /^\s*(\d{1,2}):(\d{2})(?::(\d{2})(?:\.(\d{1,3}))?)?(?: ?(am|pm|AM|PM))?\s*$/;
      var match = stringValue.match(TIME_REGEX);

      if (_.isArray(match)) {
        let hours = _.toSafeInteger(match[1]);
        let minutes = _.toSafeInteger(match[2]);
        let seconds = _.toSafeInteger(match[3]);
        let xm = match[5];

        if (minutes < 60 && seconds < 60
            && (hours < 12 || hours < 24 && !xm)) {
          if (xm && (xm == 'PM' || xm == 'pm')) {
            hours += 12;
          }
          output = {
            type: 'time',
            hour: _.toSafeInteger(match[1]),
            minute: _.toSafeInteger(match[2]),
            second: _.toSafeInteger(match[3])
          }
        } else {
          messages.push("Value must be a valid time");
        }
      } else {
        messages.push("Value must be a time");
      }
    }

    return {
      data: output,
      messages: messages,
    };
  };
}

export const TimeField = {
  type,
  create: (specification) => TimeFieldImpl(specification),
}
