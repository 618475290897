import { LabelTemplate } from '../lib/label_template.js'

const labelTemplate = LabelTemplate();

export default {
  computed: {
    interpolatedLabel: function() {
      return this.interpolatedText(this.labelTemplateStr, this.data);
    },
    interpolatedPrefix: function() {
      return this.interpolatedText(this.specification.prefix, this.data, true);
    },
    interpolatedSuffix: function() {
      return this.interpolatedText(this.specification.suffix, this.data, true);
    },
  },
  methods: {
    interpolatedText: function(textTemplate, thisData, noEmptyFields) {
      return labelTemplate.interpolatedText(textTemplate, thisData, !noEmptyFields);
    },
    labelFields: function(textTemplate) {
      return labelTemplate.labelFields(textTemplate);
    },
  }
}