<template>
  <div>
    <div id="editor-overlay" ref="editorOverlay">
      <div id="annotations-editor">
        <div id="top-bar">
          <h5>{{ title }}</h5>
          <h5 v-if="photoLabel">{{ photoLabel }}</h5>
          <div>
            <button v-if="readOnly" type="button" class="btn btn-success" v-on:click="onCancel">Close</button>
            <button v-if="!readOnly" type="button" class="btn btn-danger" v-on:click="onCancel">Cancel</button>
            <button v-if="!readOnly" type="button" class="btn btn-primary" v-on:click="onClose">Save</button>
          </div>
        </div>
        <annotations-control
          id="annotations-control"
          :fieldModel="fieldModel"
          :assetId="photoFieldValue.asset_id"
          :annotations.sync="annotations"
          :textScale.sync="textScale"
          :window.sync="window"
          :textLabels="textLabels"
          :readOnly="readOnly"
          :locatorClassification="locatorClassification"
          :notifyBalloonChange="notifyBalloonChange"
          @getBalloonValue="val => $emit('getBalloonValue', val)"
          @update:window="val => onUpdateWindow(val)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import annotations_control from './annotations_control'

export default {

  components: {
    annotationsControl: annotations_control,
  },
  props: {
    fieldModel: {
      type: Object,
      required: true,
    },
    photoFieldValue: Object,
    textLabels: Array,
    readOnly: Boolean,
    photoLabel: String,
    locatorClassification: Object,
    notifyBalloonChange: Object,
  },
  data: function() {
    return {
      annotations: _.cloneDeep(this.photoFieldValue.annotations) ?? [],
      textScale: this.photoFieldValue?.text_scale ?? -1,
      window: {...this.photoFieldValue?.window ?? {}},
    }
  },
  computed: {
    title: function() {
      return this.readOnly ? "Annotations" : "Edit Annotations";
    }
  },
  mounted() {
    this.$refs.editorOverlay.onselectstart = () => {return false};
    this.$refs.editorOverlay.addEventListener('wheel', this.handleWheel);
  },
  beforeUnmount: function() {
    this.$refs.editorOverlay.removeEventListener('wheel', this.handleWheel);
  },
  methods: {
    onCancel: function() {
      if (this.readOnly || !this.isDirty() || window.confirm("Discard annotation edits?")) {
        this.$emit("close", null);
      }
    },
    onClose: function () {
      let result = this.isDirty() ? {...this.photoFieldValue} : null;
      if (result) {
        result.window = this.window;
        if (this.annotations.length > 0) {
          result.annotations = this.annotations;
          result.text_scale = this.textScale;
        } else {
          delete result.text_scale;
          delete result.annotation_asset_id;
          delete result.annotations;
        }
      }
      this.$emit("close", result);
    },
    isDirty() {
      return !_.isEqual(this.annotations, this.photoFieldValue.annotations)
        || !_.isEqual(this.textScale, this.photoFieldValue.text_scale)
        || !_.isEqual(this.window, this.photoFieldValue.window)
    },
    handleWheel: function (evt) {
      evt.preventDefault();
    },
  }
}
</script>

<style scoped>
  #editor-overlay {
    --border-radius: 6px;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #annotations-editor {
    cursor: auto;
    --content-padding: 8px;
    padding: var(--content-padding);
    --top-bar-height: 30px;
    max-width: calc(100vw - 40px);
    max-height: calc(100vh - 40px);
    height: 100%;
    background-color: rgba(255,255,255,1);
    border-radius: var(--border-radius);
  }
  #top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--top-bar-height);
    padding-left: 10px;
  }
  #annotations-control {
    height: 100%;
    max-height: calc(100% - var(--top-bar-height) - var(--content-padding));
  }
</style>