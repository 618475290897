<template>
  <div>
    <div :class="['classification-group-header', {'toggle': useCollapsible}, {'rtl': collapsibleOnLeft}]" v-on:click="toggleCollapsed">
      <div :class="['classification-group-title', {'toggle': isSelectable}]" v-on:click="toggleSelected">
        <input type="checkbox" :checked="selected" v-show="isSelectable">
        <span>{{label}}</span>
      </div>
      <i :class="['icon', expanded ? 'icon-chevron-down' : isSelectable ? 'icon-chevron-up' : 'icon-chevron-right']" title="Click to toggle" v-show="useCollapsible"></i>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      label: String,
      selected: Boolean,
      expanded: Boolean,
      isSelectable: Boolean,
      useCollapsible: Boolean,
      collapsibleOnLeft: Boolean,
    },
    methods: {
      toggleSelected: function(evt) {
        if (this.isSelectable) {
          this.$emit("update:selected", !this.selected);
          evt.stopPropagation();
        }
      },
      toggleCollapsed: function() {
        if (this.useCollapsible) {
        this.$emit("update:expanded", !this.expanded);
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .classification-group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .toggle {
    cursor: pointer;
  }
  .classification-group-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 11.9px;
    line-height: 20px;
  }
  .classification-group-title input {
    margin-top: 0px;
    margin-bottom: 2px;
    margin-right: 4px;
  }
  .classification-group-title span {
    margin-left: 4px;
  }
  .classification-group-header .icon {
    flex: 0 0 auto;
  }
  .rtl {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
</style>