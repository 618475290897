<template>
  <canvas ref="annotationCanvas"  @click="click" @mousedown="mousedown" @mouseup="mouseup" @mousemove="mousemove"/>
</template>

<script>
import { ShapeFactory } from "../../lib/annotations/annotation"
import { DrawingOptions } from "../../lib/annotations/annotation"
import { LayoutParams } from "../../lib/annotations/annotation"

var shapeFactory = ShapeFactory();

export default {
  props: {
    annotations: Array,
    currentAnnotation: Object,
    annotationDrawingOptions: DrawingOptions,
    selectPointsWhenCurrentAnnotationChanges: Boolean,
    currentClipWindow: Object,
    width: Number,
    height: Number,
    readOnly: Boolean,
  },
  data: function () {
    return {
      canvas: null,
      context: null,
      layoutParams: new LayoutParams(),
      forceRedraw: false,
      shapes: [],
      currentShape: null,
      dragStart: null,
      isMoving: false,
    }
  },
  watch: {
    width: function(val) {
      this.layoutParams.setOverallSize(val, this.height);
      this.resize();
    },
    height: function(val) {
      this.layoutParams.setOverallSize(this.width, val);
      this.resize();
    },
    annotations: {
      handler: function(val) {
        this.shapes = shapeFactory.makeShapes(val);
        this.redrawShapes();
      }, immediate: true,
    },
    currentAnnotation: {
      handler(val) {
        if (!val) {
          if (this.currentShape) {
            this.currentShape.unselect();
          }
          this.currentShape = null;
        } else if (!this.currentShape || this.currentShape.uuid != val.uuid) {
          for (let index = this.shapes.length - 1; index >= 0; index--) {
            let shape = this.shapes[index];
            if (shape.uuid == val.uuid) {
              this.currentShape = shape;
              if (this.selectPointsWhenCurrentAnnotationChanges) {
                shape.selectAll();
              }
              break;
            }
          }
        }
        this.redrawShapes();
      },
      deep: true
    },
    currentClipWindow: function() {
      this.redrawShapes();
    },
    forceRedraw: function(force) {
      if (force) {
        this.redrawShapes();
        this.$nextTick(() => this.forceRedraw = false);
      }
    },
    annotationDrawingOptions: {
      handler(val) {
        this.redrawShapes();
      },
      deep: true
    },
  },
  mounted() {
    this.canvas = this.$refs.annotationCanvas;
    this.context = this.canvas.getContext("2d");
  },
  methods: {
    resize: function() {
      let width = this.width;
      let height = this.height;
      if (this.canvas.width != width || this.canvas.height != height) {
        this.canvas.width = width;
        this.canvas.height = height;
        this.redrawShapes();
      }
    },
    redrawShapes: function() {
      if (this.context) {
        this.context.clearRect(0,0, this.canvas.width, this.canvas.height);
        let last = this.shapes.length;
        for (let index = 0; index < last; index++) {
          let shape = this.shapes[index];
          shape.draw(this.context, this.layoutParams, this.annotationDrawingOptions, shape == this.currentShape);
        }
        this.drawClipWindow();
      }
    },
    click: function(evt) {
      if (!this.isMoving) {
        this.selectSingleShape(this.findHere(evt.offsetX, evt.offsetY), evt.offsetX, evt.offsetY);
      }
      this.isMoving = false;
    },
    mousedown: function (evt) {
      if (!this.readOnly) {
        let foundShape = this.findHere(evt.offsetX, evt.offsetY);
        if (foundShape) {
          this.selectSingleShape(foundShape, evt.offsetX, evt.offsetY);
        }
        this.dragStart = {x: evt.offsetX, y: evt.offsetY};
      }
    },
    mouseup: function () {
      this.dragStart = null;
    },
    mousemove: function (evt) {
      if (this.dragStart && this.currentShape && !this.readOnly) {
        this.isMoving = true;
        let offsetX = evt.offsetX - this.dragStart.x;
        let offsetY = evt.offsetY - this.dragStart.y;

        let result = this.currentShape.move(offsetX, offsetY, this.layoutParams);
        
        // update the start for the dimensions that got updated
        if (result.x_ok) this.dragStart.x = evt.offsetX;
        if (result.y_ok) this.dragStart.y = evt.offsetY;
      }
    },
    findHere: function (x, y) {
      let last = this.shapes.length;
      for (let index = last - 1; index >= 0; index--) {
        let shape = this.shapes[index];
        if (shape.isHere(x, y)) {
          return shape;
        }
      }
      return null;
    },
    selectSingleShape: function (shapeToSelect, x, y) {
      let foundAnnotation = null;
      if (shapeToSelect) {
        shapeToSelect.selectHere(x, y);
        foundAnnotation = this.annotations.find(a => a.uuid == shapeToSelect.uuid);
      }
      this.currentShape = shapeToSelect;
      let last = this.shapes.length;
      for (let index = last - 1; index >= 0; index--) {
        let shape = this.shapes[index];
        if (shape != shapeToSelect) {
          shape.unselect();
        }
      }
      this.$emit('annotationSelected', foundAnnotation);
      this.forceRedraw = foundAnnotation && foundAnnotation == this.currentAnnotation;
    },
    drawClipWindow: function() {
      if (this.currentClipWindow) {
        let left = this.currentClipWindow.tl_x * this.width;
        let top = this.currentClipWindow.tl_y * this.height;
        let right = left + this.currentClipWindow.scale_side * this.width;
        let bottom = top + this.currentClipWindow.scale_side * this.height;

        let ctx = this.context;
        ctx.beginPath();

        // define mask boundary
        ctx.moveTo(0, 0);
        ctx.lineTo(this.width, 0);
        ctx.lineTo(this.width, this.height);
        ctx.lineTo(0, this.height);
        ctx.closePath();

        // define hole boundary
        ctx.moveTo(left, top);
        ctx.lineTo(left, bottom);
        ctx.lineTo(right, bottom);
        ctx.lineTo(right, top);
        ctx.closePath();

        ctx.fillStyle = "#000000";
        ctx.globalAlpha = 0.2;
        ctx.fill('evenodd');
      }
    }
  }
}
</script>
