import _ from 'lodash'

export default {
  props: {
    specification: {
      type: Object,
      required: true
    },
    fieldModel: {
      type: Object,
      required: true
    },
    fieldState: {
      type: Object,
      required: true
    },
    solutionType: String,
    data: {
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: function () { return true; }
    },
    drawingSets: Array,
    prefillData: {
      type: Object,
      default: function () { return {}; }
    },
    notifyFieldData: Object,
  },
  created: function () {
    this.formId = _.uniqueId('field_')
  },
  computed: {
    isVisible: function() {
      return this.fieldState.isVisible;
    },
    isRow: function() {
      return this.fieldState.isRow;
    },
    isReadOnly: function () {
      return this.readOnly || this.fieldModel.isReadOnly;
    }
  }
}