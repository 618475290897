import _ from 'lodash'

export const LabelTemplate = () => {

  function fieldToString (fieldValue, keyforSelection) {
    if (!fieldValue) {
      return '';
    }
  
    switch (fieldValue.type) {
      case "text": return fieldValue.value;
      case "integer": return fieldValue.value;
      case "decimal": return fieldValue.value;
        return _.has(fieldValue, 'value') ? fieldValue.value : '';
      case "selection":
        var keyOrValue = keyforSelection ? 'key' : 'value';
        return _.chain(fieldValue.selections).map(_.property(keyOrValue)).join(', ').value(); 
      case "date": 
         return _.has(fieldValue, 'day') ? makeDate(fieldValue) : '';
      case "time": 
         return _.has(fieldValue, 'hour') ? makeTime(fieldValue) : '';
      default:
        return '';
    }
  }
  function makeDate (fieldValue) {
    // NB zero based array - add dummy zeroth entry
    var monthNames = ["",
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];
    return fieldValue.day + '-' + monthNames[fieldValue.month] + '-' + fieldValue.year;
  }
  
  function padNumber(n) {
    return (n < 10) ? ("0" + n) : n;
  }
  
  function makeTime(fieldValue) {
    return padNumber(fieldValue.hour) + ':' + padNumber(fieldValue.minute);
  }

  function interpolatedText(textTemplate, thisData, allowEmptyFields) {
    let missing = false;
    let result = _.replace(textTemplate, /%{([\s\S]+?)}/g, function (match, expr) {
      var keyField = false;
      var valueExpr = expr;
      if (_.endsWith(expr,':key')) {
        keyField = true;
        valueExpr = _.replace(expr,':key','');
      }
      let val = fieldToString(thisData[valueExpr.trim()], keyField);
      if (!allowEmptyFields && !val) {
        missing = true;
      }
      return val;
    });
    return missing ? "" : result;
  }

  function labelFields(textTemplate) {
    const regex = RegExp('%{([^}]*)}', 'g');
    let result = [];
    let match;
    while ((match = regex.exec(textTemplate)) !== null) {
      let group = match[1];
      if (group) {
        result.push(group);
      }
    }
    return result;
  }

  return {
    interpolatedText: interpolatedText,
    labelFields: labelFields,
  }
}